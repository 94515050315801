import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useRef,
  useMemo,
  useCallback
} from "react";

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(() => {
    const language = localStorage.getItem("language");
    if (language) return language;
    if (navigator.language)
      switch (navigator.language.toLowerCase()) {
        case "zh-cn":
          return "cn";
        case "zh-tw":
        case "zh-hk":
          return "tw";
        default:
          return "en";
      }
    return "en";
  });
  const prevLanguage = useRef(language);

  const [value, setValue] = useState(() => ({
    language,
    changeLanguage(language) {
      localStorage.setItem("language", language);
      setLanguage(language);
    }
  }));

  useEffect(() => {
    if (prevLanguage.current !== language) {
      prevLanguage.current = language;
      setValue((value) => ({ ...value, language }));
    }
  }, [language]);

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);

export const useScriptConverter = () => {
  const { language } = useLanguage();
  return useCallback(
    (script) =>
      typeof script === "string"
        ? script
        : script[language] ||
          (language === "cn" && script.tw
            ? script.tw
            : language === "tw" && script.cn
            ? script.cn
            : script.en) ||
          "",
    [language]
  );
};

export const makeScripts =
  (rawScripts = {}) =>
  () => {
    const converter = useScriptConverter();
    return useMemo(() => {
      const scripts = {};
      for (const key in rawScripts) scripts[key] = converter(rawScripts[key]);
      return scripts;
    }, [converter]);
  };
