import axios from "axios";

import { FETCH_ACCOUNTS } from "./type";

export const fetchAccount =
  ({ userId }, callback) =>
  async (dispatch, getStore) => {
    const {
      data: { accountMap }
    } = await axios.get(`account/${userId}`);

    dispatch({ type: FETCH_ACCOUNTS, payload: { accountMap } }); // get teacher access

    if (callback) callback();
  };
