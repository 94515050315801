import axios from "axios";

import { FETCH_TRAININGS, UPDATE_TRAINING, LOADING_TRAININGS } from "./type";

// fetch all the training video lists
export const fetchTrainings =
  (options, callback) => async (dispatch, getStore) => {
    dispatch({
      type: LOADING_TRAININGS,
      payload: {}
    });

    let query = ``;

    const route = `training${query ? `?${query.slice(1)}` : ``}`;

    const {
      data: { trainingMap }
    } = await axios.get(route);

    dispatch({ type: FETCH_TRAININGS, payload: { trainingMap } });

    if (callback) callback();
  };

// add training video list
export const addTraining =
  ({ title }, callback) =>
  async (dispatch, getStore) => {
    const {
      data: { training }
    } = await axios.post(`training`, {
      title
    });

    dispatch({ type: UPDATE_TRAINING, payload: { training } });
    if (callback) callback();
  };

// edit training video list title
export const editTraining =
  ({ title, trainingId }, callback) =>
  async (dispatch, getStore) => {
    const {
      data: { training }
    } = await axios.patch(`training/${trainingId}`, {
      patch: {
        action: "CHANGE_TITLE",
        data: {
          title
        }
      }
    });

    dispatch({ type: UPDATE_TRAINING, payload: { training } });
    if (callback) callback();
  };

// delete training video list
export const deleteTraining =
  (trainingId, callback) => async (dispatch, getStore) => {
    // delete training
    await axios.delete(`training/${trainingId}`);

    //update trainings
    const {
      data: { trainingMap }
    } = await axios.get(`training`);

    dispatch({ type: FETCH_TRAININGS, payload: { trainingMap } });

    if (callback) callback();
  };
