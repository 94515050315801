import styled from "styled-components/macro";

import {
  teamviewer,
  teamviewer_tw,
  teamviewer_cn,
  teamviewerHover,
  teamviewer_tw_hover,
  teamviewer_cn_hover,
  chrome,
  chrome_tw,
  chrome_cn,
  chromeHover,
  chrome_tw_hover,
  chrome_cn_hover,
  anydesk,
  anydesk_tw,
  anydesk_cn,
  anydeskHover,
  anydesk_tw_hover,
  anydesk_cn_hover,
  login,
  loginHover,
  logout,
  logoutHover,
  taManager,
  taManagerHover
} from "../img/HomePage";

const chooseButton = (linkname, lang, hover = false) => {
  switch (linkname) {
    case "teamviewer":
      if (lang === `en`) return hover ? teamviewerHover : teamviewer;
      if (lang === `tw`) return hover ? teamviewer_tw_hover : teamviewer_tw;
      if (lang === `cn`) return hover ? teamviewer_cn_hover : teamviewer_cn;
      break;
    case "chrome":
      if (lang === `en`) return hover ? chromeHover : chrome;
      if (lang === `tw`) return hover ? chrome_tw_hover : chrome_tw;
      if (lang === `cn`) return hover ? chrome_cn_hover : chrome_cn;
      break;
    case "anydesk":
      if (lang === `en`) return hover ? anydeskHover : anydesk;
      if (lang === `tw`) return hover ? anydesk_tw_hover : anydesk_tw;
      if (lang === `cn`) return hover ? anydesk_cn_hover : anydesk_cn;
    case "login":
      return hover ? loginHover : login;
    case "logout":
      return hover ? logoutHover : logout;
    default:
      return hover ? taManagerHover : taManager;
  }
};

const ButtonLink = styled.a`
  width: 7.5em;
  height: 1.7em;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: center;
  background-image: url(${({ linkname, lang }) =>
    chooseButton(linkname, lang)});
  text-decoration: none;
  :hover {
    background-image: url(${({ linkname, lang }) =>
      chooseButton(linkname, lang, true)});
  }
  cursor: pointer;
`;

export default ButtonLink;
