import { OPEN_MODAL, CLOSE_MODAL } from "./type";

export const openModal = (component, close) => ({
  type: OPEN_MODAL,
  payload: { component, close }
});

export const closeModal = () => ({
  type: CLOSE_MODAL,
  payload: null
});
