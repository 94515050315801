export const defaultTheme = {
  primary: "#ffc700",
  secondary: "#f7aa14",
  tertiary: "#fee500",

  yellow: "#ffc700",
  yellowSecondary: "#f7aa14",
  yellowTertiary: "#fee500",

  red: "#e63850",
  redSecondary: "#e51e13",
  redTertiary: "#ff4053",

  green: "#28c985",
  greenSecondary: "#13b06e",
  greenTertiary: "#2ded9d",

  blue: "#7ec9ff",
  blueSecondary: "#68bffd",
  blueTertiary: "#7eddff",

  purple: "#a569ff",
  purpleSecondary: "#8a3dff",
  purpleTertiary: "#b785ff",

  product: "#ff8906",
  brand: "#6600ff"
};

export const adminTheme = {
  ...defaultTheme,
  primary: defaultTheme.blue,
  secondary: defaultTheme.blueSecondary,
  tertiary: defaultTheme.blueTertiary
};

export const superTheme = {
  ...defaultTheme,
  primary: defaultTheme.red,
  secondary: defaultTheme.redSecondary,
  tertiary: defaultTheme.redTertiary
};

export const teacherTheme = { ...defaultTheme };

export const principalTheme = {
  ...defaultTheme,
  primary: defaultTheme.green,
  secondary: defaultTheme.greenSecondary,
  tertiary: defaultTheme.greenTertiary
};

export const studentTheme = {
  ...defaultTheme,
  primary: defaultTheme.purple,
  secondary: defaultTheme.purpleSecondary,
  tertiary: defaultTheme.purpleTertiary
};

export const themeSelector = (roles) => {
  if (!roles) return defaultTheme;
  if (roles.indexOf("super") !== -1) return superTheme;
  if (roles.indexOf("melody") !== -1) return adminTheme;
  if (roles.indexOf("principal") !== -1) return principalTheme;
  if (roles.indexOf("student") !== -1) return studentTheme;

  return defaultTheme;
};
