import { FETCH_COMMENTS, UPDATE_COMMENT } from "../actions/type";

const INIT_STATE = { commentMap: {} };

export default (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case FETCH_COMMENTS:
      return {
        ...state,
        commentMap: { ...payload.commentMap }
      };

    case UPDATE_COMMENT:
      return {
        ...state,
        commentMap: {
          ...state.commentMap,
          // [payload.comment._page]: payload.comment,
          [payload.comment._id]: payload.comment
        }
      };

    default:
      return state;
  }
};
