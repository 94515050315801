import axios from "axios";
import {
  FETCH_CLASSALLDETAILS,
  FETCH_BOOKVIEWEDDETAILS,
  FETCH_SECTIONDETAILS
} from "./type";

// fetch all data from teacherStatusViewed
export const fetchTeacherViewedData =
  ({ productId, schoolId, classroomId, level }, callback) =>
  async (dispatch, getStore) => {
    let query = ``;

    if (productId !== undefined) query = `${query}&productId=${productId}`;
    if (schoolId !== undefined) query = `${query}&schoolId=${schoolId}`;
    if (classroomId !== undefined)
      query = `${query}&classroomId=${classroomId}`;
    if (level !== undefined) query = `${query}&level=${level}`;

    const route = `teacherviewedstatus${query ? `?${query.slice(1)}` : ``}`;

    const {
      data: { allbooksViewedStatusMap }
    } = await axios.get(route);

    dispatch({
      type: FETCH_CLASSALLDETAILS,
      payload: { allbooksViewedStatusMap }
    });

    if (callback) callback();
  };

//fetch book teaaching status
export const fetchBookViewedDate =
  ({ classroomId, bookId }, callback) =>
  async (dispatch, getStore) => {
    let query = ``;

    if (bookId !== undefined) query = `${query}&bookId=${bookId}`;
    if (classroomId !== undefined)
      query = `${query}&classroomId=${classroomId}`;

    const route = `bookviewedstatus${query ? `?${query.slice(1)}` : ``}`;

    const {
      data: { bookViewedStatusMap }
    } = await axios.get(route);

    dispatch({
      type: FETCH_BOOKVIEWEDDETAILS,
      payload: { bookViewedStatusMap }
    });

    if (callback) callback();
  };

//fetch units viewed status

export const fetchUnitsViewedDate =
  ({ classroomId, bookId }, callback) =>
  async (dispatch, getStore) => {
    let query = ``;

    if (bookId !== undefined) query = `${query}&bookId=${bookId}`;
    if (classroomId !== undefined)
      query = `${query}&classroomId=${classroomId}`;

    const route = `unitviewedstatus${query ? `?${query.slice(1)}` : ``}`;

    const {
      data: { sectionInfoMap }
    } = await axios.get(route);

    dispatch({
      type: FETCH_SECTIONDETAILS,
      payload: { sectionInfoMap }
    });
  };
