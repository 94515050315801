import { SET_NOTIFY, CLOSE_NOTIFY } from "../actions/type";

const INIT_STATE = {
  alertMessage: "",
  show: false,
  confirm: null,
  cancel: false
};

export default (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case SET_NOTIFY:
      return {
        alertMessage: payload.alert,
        confirm: payload.confirm,
        show: true,
        cancel: payload.cancel
      };

    case CLOSE_NOTIFY:
      return {
        alertMessage: "",
        show: false,
        confirm: null,
        cancel: false
      };

    default:
      return state;
  }
};
