import { cloneDeep } from "lodash-es";
import {
  CLEAN_AUTH,
  OPEN_TOOLBAR,
  CLOSE_TOOLBAR,
  ADD_TOOLBAR_ICON,
  MOVE_TOOLBAR_ICON,
  CHANGE_TOOLBAR_ICON,
  STORE_TOOLBAR_GRID,
  RESIZE_TOOLBAR_GRID,
  DELETE_TOOLBAR_ICON,
  UNDO_TOOLBAR_HISTORY,
  REDO_TOOLBAR_HISTORY,
  INITIAL_TOOLBAR_HISTORY,
  DELETEALL_TOOLBAR_HISTORY,
  DRAWLINE_TOOLBAR_HISTORY,
  ADD_TOOLBAR_TEXT,
  STORE_TOOLBAR_TEXT,
  MOVE_TOOLBAR_TEXT,
  DELETE_TOOLBAR_TEXT,
  RESIZE_TOOLBAR_TEXT,
  ADD_TOOLBAR_SHAPE,
  ADD_TOOLBAR_SCREENSHOT,
  DELETE_TOOLBAR_SCREENSHOT,
  STORE_TOOLBAR_SCOREBOARD_INFO
} from "../actions/type";

const INIT_STATE = {
  show: false,
  toolbarIcon: [],
  toolbarHistory: [[]],
  toolbarHistoryStep: 0,
  toolbarScreenShot: []
};

export default (state = INIT_STATE, { type, payload }) => {
  let newState;

  switch (type) {
    case OPEN_TOOLBAR:
      return { ...state, show: true };

    case CLOSE_TOOLBAR:
      return { ...state, show: false };
    // return { ...INIT_STATE };

    case ADD_TOOLBAR_ICON:
      newState = cloneDeep(state);

      newState.toolbarHistory = newState.toolbarHistory.slice(
        0,
        newState.toolbarHistoryStep + 1
      );

      if (payload.icon !== undefined) {
        newState.toolbarIcon.push(payload.icon);
        newState.toolbarHistory.push(cloneDeep(newState.toolbarIcon));
      } else {
        newState.toolbarHistoryStep = payload.step;
      }
      newState.toolbarHistoryStep++;
      return newState;

    case MOVE_TOOLBAR_ICON:
      newState = cloneDeep(state);
      newState.toolbarHistory = newState.toolbarHistory.slice(
        0,
        newState.toolbarHistoryStep + 1
      );

      newState.toolbarIcon[payload.index].movement = payload.movement;
      newState.toolbarHistory.push(cloneDeep(newState.toolbarIcon));
      newState.toolbarHistoryStep++;

      return newState;

    case CHANGE_TOOLBAR_ICON:
      newState = cloneDeep(state);

      newState.toolbarHistory = newState.toolbarHistory.slice(
        0,
        newState.toolbarHistoryStep + 1
      );

      newState.toolbarIcon[payload.index].icon = payload.icon;
      newState.toolbarIcon[payload.index].random = payload.random;
      newState.toolbarHistory.push(cloneDeep(newState.toolbarIcon));
      newState.toolbarHistoryStep++;
      return newState;

    case STORE_TOOLBAR_GRID:
      newState = cloneDeep(state);
      newState.toolbarIcon[payload.index].resize = payload.resize;
      return newState;

    case RESIZE_TOOLBAR_GRID:
      newState = cloneDeep(state);
      newState.toolbarHistory = newState.toolbarHistory.slice(
        0,
        newState.toolbarHistoryStep + 1
      );
      newState.toolbarIcon[payload.index].scale = payload.scale;
      newState.toolbarHistory.push(cloneDeep(newState.toolbarIcon));
      newState.toolbarHistoryStep++;
      return newState;

    case DELETE_TOOLBAR_ICON:
      newState = cloneDeep(state);
      // newState.toolbarIcon.splice(payload.index, 1);
      newState.toolbarIcon[payload.index] = null;
      newState.toolbarHistory = newState.toolbarHistory.slice(
        0,
        newState.toolbarHistoryStep + 1
      );
      newState.toolbarHistory.push(cloneDeep(newState.toolbarIcon));
      newState.toolbarHistoryStep++;
      return newState;

    case INITIAL_TOOLBAR_HISTORY:
      return { ...INIT_STATE, show: true };

    case DELETEALL_TOOLBAR_HISTORY:
      newState = cloneDeep(state);

      if (newState.toolbarIcon.length !== 0) {
        newState.toolbarHistory = newState.toolbarHistory.slice(
          0,
          newState.toolbarHistoryStep + 1
        );

        if (!newState.toolbarIcon.every((icon) => icon === null)) {
          newState.toolbarHistoryStep++;
        }
        newState.toolbarIcon.length = 0;
        newState.toolbarHistory.push(cloneDeep(newState.toolbarIcon));
      }
      newState.toolbarScreenShot.length = 0;
      return newState;

    case DRAWLINE_TOOLBAR_HISTORY:
      newState = cloneDeep(state);
      newState.toolbarHistory = newState.toolbarHistory.slice(
        0,
        newState.toolbarHistoryStep + 1
      );
      newState.toolbarIcon.push({
        points: cloneDeep(payload.points),
        mode: payload.mode,
        color: payload.color,
        brushMode: payload.brushMode
      });
      newState.toolbarHistory.push(cloneDeep(newState.toolbarIcon));
      newState.toolbarHistoryStep++;

      return newState;
    case UNDO_TOOLBAR_HISTORY:
      newState = cloneDeep(state);
      if (newState.toolbarHistoryStep === 0) {
        return newState;
      }
      // newState.toolbarHistoryStep = payload.step;
      newState.toolbarHistoryStep--;
      if (newState.toolbarHistoryStep === 0) {
        newState.toolbarIcon.length = 0;
      }
      newState.toolbarIcon = cloneDeep(
        newState.toolbarHistory[newState.toolbarHistoryStep]
      );

      return newState;

    case REDO_TOOLBAR_HISTORY:
      newState = cloneDeep(state);

      if (newState.toolbarHistoryStep >= newState.toolbarHistory.length) {
        return newState;
      }
      // newState.toolbarHistoryStep = payload.step;
      newState.toolbarHistoryStep++;
      newState.toolbarIcon = cloneDeep(
        newState.toolbarHistory[newState.toolbarHistoryStep]
      );

      return newState;

    case ADD_TOOLBAR_TEXT:
      newState = cloneDeep(state);
      newState.toolbarHistory = newState.toolbarHistory.slice(
        0,
        newState.toolbarHistoryStep + 1
      );
      newState.toolbarIcon.push({ style: payload.style });
      newState.toolbarHistory.push(cloneDeep(newState.toolbarIcon));
      newState.toolbarHistoryStep++;

      return newState;

    case STORE_TOOLBAR_TEXT:
      newState = cloneDeep(state);
      if (payload.mode === "info") {
        newState.toolbarIcon[payload.index].resize = payload.info;
      } else {
        newState.toolbarIcon[payload.index].style.text = payload.info;
        newState.toolbarHistory[newState.toolbarHistoryStep][
          payload.index
        ].style.text = payload.info;
      }

      // newState.toolbarIcon.push({ style: payload.style, words: payload.info });

      // newState.toolbarIcon[payload.index].style.text = payload.info;
      // newState.toolbarHistory.push(cloneDeep(newState.toolbarIcon));
      // newState.toolbarHistoryStep++;

      return newState;

    case MOVE_TOOLBAR_TEXT:
      newState = cloneDeep(state);
      newState.toolbarHistory = newState.toolbarHistory.slice(
        0,
        newState.toolbarHistoryStep + 1
      );

      newState.toolbarIcon[payload.index].style.left =
        payload.Textposition.left;

      newState.toolbarIcon[payload.index].style.top = payload.Textposition.top;

      newState.toolbarHistory.push(cloneDeep(newState.toolbarIcon));
      newState.toolbarHistoryStep++;
      return newState;

    case DELETE_TOOLBAR_TEXT:
      newState = cloneDeep(state);
      newState.toolbarHistory = newState.toolbarHistory.slice(
        0,
        newState.toolbarHistoryStep + 1
      );
      newState.toolbarIcon[payload.index] = null;
      newState.toolbarHistory.push(cloneDeep(newState.toolbarIcon));
      newState.toolbarHistoryStep++;
      return newState;

    case RESIZE_TOOLBAR_TEXT:
      newState = cloneDeep(state);
      newState.toolbarHistory = newState.toolbarHistory.slice(
        0,
        newState.toolbarHistoryStep + 1
      );
      newState.toolbarIcon[payload.index].style.width = payload.scale.width;
      newState.toolbarIcon[payload.index].style.height = payload.scale.height;
      newState.toolbarIcon[payload.index].style.left = payload.scale.left;
      newState.toolbarIcon[payload.index].style.top = payload.scale.top;

      newState.toolbarHistory.push(cloneDeep(newState.toolbarIcon));
      newState.toolbarHistoryStep++;
      return newState;

    case ADD_TOOLBAR_SHAPE:
      newState = cloneDeep(state);
      newState.toolbarHistory = newState.toolbarHistory.slice(
        0,
        newState.toolbarHistoryStep + 1
      );

      newState.toolbarIcon.push(payload.shape);
      newState.toolbarHistory.push(cloneDeep(newState.toolbarIcon));
      newState.toolbarHistoryStep++;
      return newState;

    case ADD_TOOLBAR_SCREENSHOT:
      newState = cloneDeep(state);
      newState.toolbarScreenShot.push(payload.screenshot);
      return newState;

    case DELETE_TOOLBAR_SCREENSHOT:
      newState = cloneDeep(state);
      newState.toolbarScreenShot.length = 0;
      return newState;

    case STORE_TOOLBAR_SCOREBOARD_INFO:
      newState = cloneDeep(state);
      // newState.toolbarHistory = newState.toolbarHistory.slice(
      //   0,
      //   newState.toolbarHistoryStep + 1
      // );
      newState.toolbarIcon[payload.index].boardInfo = payload.boardInfo;

      newState.toolbarHistory[newState.toolbarHistoryStep][
        payload.index
      ].boardInfo = payload.boardInfo;

      // newState.toolbarHistory.push(cloneDeep(newState.toolbarIcon));
      // newState.toolbarHistoryStep++;

      return newState;

    case CLEAN_AUTH:
      return { ...INIT_STATE };

    default:
      return state;
  }
};
