import React from "react";
import { Typography, Box } from "@material-ui/core";
import { useScriptConverter } from "../utils/languageHelpers";
import { MEDIA_STORAGE } from "../config/environment";

const Download = ({ children }) => (
  <a
    href={`${MEDIA_STORAGE}/public/download/ChromeSetup${
      window.navigator.userAgent.includes("Mac") ? ".dmg" : ".exe"
    }`}
    download
  >
    {children}
  </a>
);

// main
const Copyright = () => {
  const converter = useScriptConverter();
  return (
    <Box m={1}>
      <Typography variant="body2" color="textSecondary" align="center">
        {converter({
          en: (
            <>
              {"Please Use "}
              <Download>Chrome</Download>
              {" for Best Experience!"}
            </>
          ),
          tw: (
            <>
              {"請使用"}
              <Download>Chrome 瀏覽器</Download>
              {" 以達到最佳體驗！"}
            </>
          ),
          cn: (
            <>
              {"请使用 "}
              <Download>Chrome 浏览器</Download>
              {" 以达到最佳体验！"}
            </>
          )
        })}
      </Typography>
      <Typography variant="body2" color="textSecondary" align="center">
        {`Copyright © ${
          window.location.href.indexOf(".cn") !== -1
            ? "上海腾泓教育科技有限公司"
            : "美樂蒂文教科技興業股份有限公司"
        } `}
        {new Date().getFullYear()}
        {`. 統一編號:86921096 | 客服專線:07-3227493 | 客服時間:09:00am-18:00pm | 地址:高雄市三民區北平二街16號6樓`}
      </Typography>
      {window.location.href.indexOf(".cn") !== -1 && (
        <Typography variant="body2" color="textSecondary" align="center">
          {"工信部网站: "}
          <a
            style={{ textDecoration: "none" }}
            target="_blank"
            rel="noopener noreferrer"
            href="https://beian.miit.gov.cn"
          >
            beian.miit.gov.cn
          </a>
          {" ICP: "}
          <a
            style={{ textDecoration: "none" }}
            target="_blank"
            rel="noopener noreferrer"
            href="https://beian.miit.gov.cn"
          >
            沪ICP备20000940号
          </a>
        </Typography>
      )}
    </Box>
  );
};

export default Copyright;
