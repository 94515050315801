import { createGlobalStyle } from "styled-components/macro";
//font family-Work Sans Light
import WorkSansLightWoff2 from "../app/font/work-sans/WorkSans-Light.woff2";
import WorkSansLightWoff from "../app/font/work-sans/WorkSans-Light.woff";
import WorkSansLightTtf from "../app/font/work-sans/WorkSans-Light.ttf";
import WorkSansLightEot from "../app/font/work-sans/WorkSans-Light.eot?#iefix";
//font family-Work Sans Regular
import WorkSansRegularWoff2 from "../app/font/work-sans/WorkSans-Regular.woff2";
import WorkSansRegularWoff from "../app/font/work-sans/WorkSans-Regular.woff";
import WorkSansRegularTtf from "../app/font/work-sans/WorkSans-Regular.ttf";
import WorkSansRegularEot from "../app/font/work-sans/WorkSans-Regular.eot?#iefix";
//font family-Work Sans Bold
import WorkSansBoldWoff2 from "../app/font/work-sans/WorkSans-Bold.woff2";
import WorkSansBoldWoff from "../app/font/work-sans/WorkSans-Bold.woff";
import WorkSansBoldTtf from "../app/font/work-sans/WorkSans-Bold.ttf";
import WorkSansBoldEot from "../app/font/work-sans/WorkSans-Bold.eot?#iefix";
//font family-Quick Sand
import QuicksandMediumrWoff2 from "../app/font/quick-sand/Quicksand-Medium.woff2";
import QuicksandMediumrWoff from "../app/font/quick-sand/Quicksand-Medium.woff";
import QuicksandMediumTtf from "../app/font/quick-sand/Quicksand-Medium.ttf";
import QuicksandMediumEot from "../app/font/quick-sand/Quicksand-Medium.eot?#iefix";
//font family-display free Regular
import AlarmClockWoff2 from "../app/font/alarm-clock/alarm-clock.woff2";
import AlarmClockWoff from "../app/font/alarm-clock/alarm-clock.woff";
import AlarmClockTtf from "../app/font/alarm-clock/alarm-clock.ttf";
import AlarmClockEot from "../app/font/alarm-clock/alarm-clock.eot";
// Lastest: Woff2 > Woff > Ttf
// Eot: IE9 Compat Modes

export const GlobalStyle = createGlobalStyle`
::-webkit-scrollbar {
  width: 4px;
  height: 6px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:vertical {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(#ff9a9e),
    color-stop(99%, #fecfef),
    to(#fecfef)
  );
  background-image: linear-gradient(
    to top,
    #ff9a9e 0%,
    #fecfef 99%,
    #fecfef 100%
  );
}

::-webkit-scrollbar-thumb:horizontal {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-image: -webkit-gradient(
    linear,
    right top,
    left top,
    from(#ff9a9e),
    color-stop(99%, #fecfef),
    to(#fecfef)
  );
  background-image: linear-gradient(
    to left,
    #ff9a9e 0%,
    #fecfef 99%,
    #fecfef 100%
  );
}

body {
  overscroll-behavior-x: none;
  width: 100%;
  height: 100%;

  font-family:${({ lang }) =>
    lang === "tc" || lang === "sc"
      ? `"Microsoft JhengHei", "work sans", "sans-serif"`
      : `"work sans", "sans-serif"`} ;

  @font-face {
    font-family: "work sans";
    src: url(${WorkSansLightWoff2}) format("woff2"),
       url(${WorkSansLightWoff}) format("woff"),
       url(${WorkSansLightTtf}) format("ttf"),
       url(${WorkSansLightEot}) format("embedded-opentype"); 
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "work sans";
    src: url(${WorkSansRegularWoff2}) format("woff2"),
       url(${WorkSansRegularWoff}) format("woff"),
       url(${WorkSansRegularTtf}) format("ttf"),
       url(${WorkSansRegularEot}) format("embedded-opentype"); 
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "work sans";
    src: url(${WorkSansBoldWoff2}) format("woff2"),
       url(${WorkSansBoldWoff}) format("woff"),
       url(${WorkSansBoldTtf}) format("ttf"),
       url(${WorkSansBoldEot}) format("embedded-opentype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Quicksand";
    src: url(${QuicksandMediumrWoff2}) format("woff2"),
    url(${QuicksandMediumrWoff}) format("woff"),
    url(${QuicksandMediumTtf}) format("ttf"),
    url(${QuicksandMediumEot}) format("embedded-opentype");
    font-style: normal;
    font-weight: 500;
    font-display: swap;
  }

  @font-face {
    font-family: "Alarm-clock";
    src: url(${AlarmClockWoff2}) format("woff2"),
    url(${AlarmClockWoff}) format("woff"),
    url(${AlarmClockTtf}) format("ttf"),
    url(${AlarmClockEot}) format("embedded-opentype");
    font-style: normal;
    font-weight: 500;
    font-display: swap;
  }

  font-size: ${(250 / 555) * 125}%;

  @media (min-width: 740px) and (min-height: 440px) {
    font-size: ${(345 / 555) * 125}%;
  }

  @media (min-width: 992px) and (min-height: 595px) {
    font-size: ${(465 / 555) * 125}%;
  }

  @media (min-width: 1200px) and (min-height: 710px) {
    font-size: 125%;
  }

  @media (min-width: 1400px) and (min-height: 830px) {
    font-size: ${(645 / 555) * 125}%;
  }

  @media (min-width: 1590px) and (min-height: 940px) {
    font-size: ${(735 / 555) * 125}%;
  }

  @media (min-width: 1840px) and (min-height: 1080px) {
    font-size: ${(845 / 555) * 125}%;
  }

  @media (min-width: 2000px) and (min-height: 1220px) {
    font-size: ${(955 / 555) * 125}%;
  }
}

html {
  width: 100%;
  height: 100%;

  font-size: 16px;
}
`;
