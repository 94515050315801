import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";

import ErrorPNG from "./app/img/404.png";
import { getWithExpiry, setWithExpiry } from "./utils/reloadStorage";

const ErrorContainer = styled.div`
  width: 100%;
  height: 100%;

  background-image: url(${ErrorPNG});
  background-size: 100% 100%;
  background-repeat: no-repeat;

  position: relative;
`;

const Button = styled.button`
  border-radius: 1.5em;
  width: 10em;
  height: 3em;

  font-family: "Quicksand", sans-serif;

  background-color: #4caf50;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;

  bottom: 8%;
  left: 22%;
  position: absolute;

  :focus {
    outline: none;
  }

  :hover {
    background-color: orange;
  }
`;

const Text = styled.div`
  font-size: 1.5em;
`;

const ErrorTextScreen = styled.div`
  width: 37%;
  height: 29%;
  border: 0.2em solid white;
  border-radius: 0.5em;

  position: absolute;
  font-family: "Quicksand", sans-serif;
  color: white;

  bottom: 18%;
  left: 10%;

  overflow-y: auto;
`;

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  const [isReload, setIsReload] = useState(true);
  useEffect(() => {
    const chunkFailedMessage = /Loading chunk [\w]+ failed/;

    if (
      error?.message &&
      (error.message === "Unexpected token '<'" ||
        chunkFailedMessage.test(error.message)) &&
      !getWithExpiry("chunk_failed")
    ) {
      setWithExpiry("chunk_failed", "true", 10000);
      window.location.reload();
    } else {
      setIsReload(false);
    }
  }, [error]);

  return (
    !isReload && (
      <ErrorContainer role="alert">
        <ErrorTextScreen>
          <div style={{ color: "white" }}>{error.message}</div>
        </ErrorTextScreen>
        <Button onClick={resetErrorBoundary}>
          <Text>Try again</Text>
        </Button>
      </ErrorContainer>
    )
  );
};

export default ErrorFallback;
