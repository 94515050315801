import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";

import RatioBox from "./RatioBox";
import MainContainer from "./MainContainer";

import notFound from "../img/404.png";
import backHome from "../img/Back_Home.png";
import backHomeHover from "../img/Back_Home_Hover.png";
import backHomeActive from "../img/Back_Home_Active.png";
import goBack from "../img/Go_Back.png";
import goBackHover from "../img/Go_Back_Hover.png";
import goBackActive from "../img/Go_Back_Active.png";

const NotFoundImg = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: url(${notFound});
  background-size: 100% 100%;
  background-repeat: no-repeat;
`;

const HomeButton = styled.div`
  position: absolute;
  left: 18%;
  top: 75%;

  width: 20%;
  height: 9%;

  cursor: pointer;

  background: url(${backHome});
  background-size: 100% 100%;
  background-repeat: no-repeat;

  :hover {
    background: url(${backHomeHover});
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  :active {
    background: url(${backHomeActive});
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
`;

const GoBackButton = styled.div`
  position: absolute;
  left: 18%;
  top: 60%;

  width: 20%;
  height: 9%;

  cursor: pointer;

  background: url(${goBack});
  background-size: 100% 100%;
  background-repeat: no-repeat;

  :hover {
    background: url(${goBackHover});
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  :active {
    background: url(${goBackActive});
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
`;

const NoMatch = () => {
  const navigate = useNavigate();
  return (
    <MainContainer>
      <RatioBox ratio={16 / 9}>
        <NotFoundImg />
        <HomeButton onClick={() => navigate("/")} />
        <GoBackButton onClick={() => navigate(-1)} />
      </RatioBox>
    </MainContainer>
  );
};

export default NoMatch;
