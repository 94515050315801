import React from "react";
import styled from "styled-components/macro";
import { connect } from "react-redux";
import { closeModal } from "../actions";

import MainContainer from "./elements/MainContainer";

const Background = styled.div`
  /* user-select: none !important; */
  display: ${({ show }) => (show ? `flex` : `none`)}; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 96; /* Sit on top */

  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.5); /* Black w/ opacity */

  align-items: center;
  justify-content: center;
`;

const Modal = ({ modal: { show, close, component }, closeModal }) => {
  return (
    <Background show={show} onClick={() => (close ? closeModal() : null)}>
      <MainContainer>{component}</MainContainer>
    </Background>
  );
};

const mapStateToProps = ({ modal }) => ({ modal });

export default connect(mapStateToProps, { closeModal })(Modal);
