import {
  LOAD_AUDIO,
  PLAY_AUDIO,
  PAUSE_AUDIO,
  STOP_AUDIO,
  SET_AUDIO_DURATION,
  SET_AUDIO_CURRENT_TIME,
  CONTROL_AUDIO_CURRENT_TIME
} from "./type";

export const loadAudio = (src) => ({
  type: LOAD_AUDIO,
  payload: { src, isSoundEffect: false }
});

export const loadSoundEffect = (src) => ({
  type: LOAD_AUDIO,
  payload: { src, isSoundEffect: true }
});

export const playAudio = () => ({
  type: PLAY_AUDIO,
  payload: true
});

export const pauseAudio = () => ({
  type: PAUSE_AUDIO,
  payload: false
});

export const setAudioDuration = (duration) => ({
  type: SET_AUDIO_DURATION,
  payload: { duration }
});

export const setAudioCurrentTime = (currentTime) => ({
  type: SET_AUDIO_CURRENT_TIME,
  payload: { currentTime }
});

export const controlCurrentTime = (controlTime) => ({
  type: CONTROL_AUDIO_CURRENT_TIME,
  payload: { controlTime }
});

export const stopAudio = () => ({
  type: STOP_AUDIO,
  payload: {}
});
