import { v4 as uuidv4 } from "uuid";

import { ADD_NOTICE, DELETE_NOTICE } from "./type";

export const addNotice =
  (value, noticeType = "default", delay = 3000) =>
  async (dispatch) => {
    const id = uuidv4();

    const timeout = setTimeout(() => {
      dispatch({
        type: DELETE_NOTICE,
        payload: id
      });
    }, delay);

    dispatch({
      type: ADD_NOTICE,
      payload: { id, value, noticeType, timeout }
    });
  };

export const deleteNotice = (id) => async (dispatch, getStore) => {
  const notice = getStore().notice.notices.find((notice) => notice.id === id);

  notice && clearTimeout(notice.timeout);

  dispatch({
    type: DELETE_NOTICE,
    payload: id
  });
};
