import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { connect } from "react-redux";
import { closeContextMenu } from "../actions";

const Container = styled.div`
  background: #666;
  color: #fff;
  opacity: 95%;
  border-radius: 3px;
  border: 1px solid #666;
  min-width: 200px;
  padding: 3px 0;

  display: flex;
  flex-direction: column;
`;

const Item = styled.div`
  min-width: 200px;
  padding: 0 20px;
  font-size: 16px;
  line-height: 1.2;
  cursor: pointer;

  ${({ disabled }) =>
    disabled
      ? `opacity: 0.5;`
      : `  :hover {
    background: #0084e3;
  }`}
`;

const ContextMenu = ({ show, position, menu, closeContextMenu }) => {
  useEffect(() => {
    const onClickCloseMenu = () => {
      closeContextMenu();
    };
    document.addEventListener("click", onClickCloseMenu);

    return () => document.removeEventListener("click", onClickCloseMenu);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return show ? (
    <Container
      style={{ position: "fixed", top: position.top, left: position.left }}
      onContextMenu={(e) => {
        e.preventDefault();
      }}
    >
      {menu.map((item, index) => (
        <Item
          key={index}
          onClick={(e) => {
            if (item.disabled) {
              return;
            }
            if (item.callback) item.callback();
          }}
          disabled={item.disabled}
        >
          {item.label}
        </Item>
      ))}
    </Container>
  ) : null;
};

const mapStateToProps = ({ contextmenu }) => ({
  show: contextmenu.show,
  menu: contextmenu.menu,
  position: contextmenu.position
});

export default connect(mapStateToProps, { closeContextMenu })(ContextMenu);
