import axios from "axios";
import { batch } from "react-redux";

import {
  FETCH_CATEGORYS,
  UPDATE_CATEGORY,
  LOADING_CATEGORYS,
  FETCH_FOLDERS,
  LOADING_FOLDERS,
  UPDATE_FOLDER,
  FETCH_PAGES,
  UPDATE_PAGE
} from "./type";

import mediaServerAddress from "../config/mediaServerAddress";

/*-----------category start------------*/

// fetch all the category
export const fetchCategorys =
  (options, callback) => async (dispatch, getStore) => {
    dispatch({ type: LOADING_CATEGORYS, payload: {} });

    let query = ``;

    if (options?.isHidden !== undefined)
      query = `${query}&isHidden=${options.isHidden}`;
    if (options?.productIds !== undefined)
      query = `${query}&productIds=${options.productIds}`;
    if (options?.country !== undefined)
      query = `${query}&country=${options.country}`;

    if (options?.route !== undefined) query = `${query}&route=${options.route}`;

    const route = `category${query ? `?${query.slice(1)}` : ``}`;

    const {
      data: { categoryMap }
    } = await axios.get(route);

    dispatch({ type: FETCH_CATEGORYS, payload: { categoryMap } });

    if (callback) callback();
  };

// save add category(create a category)
export const saveCategory =
  (
    formProps,
    { coverSrc, title, isHidden, contentAccess, countriesAccess, userAccess },
    callback
  ) =>
  async (dispatch, getStore) => {
    let imageId = null;

    if (coverSrc) {
      if (formProps[`image_0`]) {
        const fd = new FormData();
        fd.append(`image`, formProps[`image_0`]);

        //upload new image
        const {
          data: { image }
        } = await mediaServerAddress.post(`image`, fd);
        imageId = image._id;
      }
    }

    const {
      data: { category }
    } = await axios.post(`category`, {
      title,
      isHidden,
      imageId,
      contentAccess,
      userAccess,
      countriesAccess
    });

    dispatch({ type: UPDATE_CATEGORY, payload: { category } });

    if (callback) callback();
  };

// save edit category
export const saveEditCategory =
  (
    formProps,
    {
      coverSrc,
      title,
      isHidden,
      contentAccess,
      userAccess,
      countriesAccess,
      categoryId
    },
    { coverImage },
    callback
  ) =>
  async (dispatch, getStore) => {
    let imageId = null;
    const uselessImages = {};

    if (coverImage) {
      uselessImages[coverImage._id] = true;
    }

    if (coverSrc) {
      if (formProps[`image_0`]) {
        const fd = new FormData();
        fd.append(`image`, formProps[`image_0`]);

        //upload new image
        const {
          data: { image }
        } = await mediaServerAddress.post(`image`, fd);
        imageId = image._id;
      } else {
        imageId = coverImage._id;
        delete uselessImages[coverImage._id];
      }
    }

    const {
      data: { category }
    } = await axios.patch(`category/${categoryId}`, {
      patch: {
        action: `changeCover`,
        data: {
          title,
          isHidden,
          imageId,
          contentAccess,
          userAccess,
          countriesAccess
        }
      }
    });

    //clean up old images
    await axios.delete(`image`, {
      data: { imageIds: Object.keys(uselessImages) }
    });

    dispatch({ type: UPDATE_CATEGORY, payload: { category } });

    if (callback) callback();
  };

export const moveCategory =
  (from, to, callback) => async (dispatch, getStore) => {
    const {
      data: { categoryMap }
    } = await axios.patch(`moveCategory`, {
      patch: {
        data: {
          from,
          to
        }
      }
    });

    dispatch({ type: FETCH_CATEGORYS, payload: { categoryMap } });

    if (callback) callback();
  };

// delete category
export const deletedownloadCategory =
  ({ categoryId }, callback) =>
  async (dispatch, getStore) => {
    // delete category
    await axios.delete(`category/${categoryId}`);

    //update categorys
    const {
      data: { categoryMap }
    } = await axios.get(`category`);

    dispatch({ type: FETCH_CATEGORYS, payload: { categoryMap } });

    if (callback) callback();
  };

/*-----------category end------------*/

/*-----------folder start------------*/
// fetch all the category's Folders
export const fetchFolders =
  (options, callback) => async (dispatch, getStore) => {
    dispatch({ type: LOADING_FOLDERS, payload: {} });

    let query = ``;
    if (options.categoryId !== undefined)
      query = `${query}&categoryId=${options.categoryId}`;
    if (options.isHidden !== undefined)
      query = `${query}&isHidden=${options.isHidden}`;

    const route = `category/folder${query ? `?${query.slice(1)}` : ``}`;

    const {
      data: { folderMap }
    } = await axios.get(route);

    dispatch({ type: FETCH_FOLDERS, payload: { folderMap } });

    if (callback) callback();
  };

// save add folder(create a folder)
export const saveFolder =
  (
    formProps,
    { categoryId, coverSrc, title, link, isHidden, userAccess },
    callback
  ) =>
  async (dispatch, getStore) => {
    let imageId = null;

    if (coverSrc) {
      if (formProps[`image_0`]) {
        const fd = new FormData();
        fd.append(`image`, formProps[`image_0`]);

        //upload new image
        const {
          data: { image }
        } = await mediaServerAddress.post(`image`, fd);
        imageId = image._id;
      }
    }

    const {
      data: { folder }
    } = await axios.post(`category/folder`, {
      categoryId,
      title,
      link,
      isHidden,
      imageId,
      userAccess
    });

    dispatch({ type: UPDATE_FOLDER, payload: { folder } });

    // update folder's order

    const {
      data: { category }
    } = await axios.patch(`category/${categoryId}`, {
      patch: {
        action: "addFolder",
        data: {
          folderId: folder._id
        }
      }
    });

    dispatch({ type: UPDATE_CATEGORY, payload: { category } });

    if (callback) callback();
  };

// save edit folder
export const saveEditFolder =
  (
    formProps,
    { coverSrc, title, link, isHidden, folderId, userAccess },
    { coverImage },
    callback
  ) =>
  async (dispatch, getStore) => {
    let imageId = null;
    const uselessImages = {};

    if (coverImage) {
      uselessImages[coverImage._id] = true;
    }

    if (coverSrc) {
      if (formProps[`image_0`]) {
        const fd = new FormData();
        fd.append(`image`, formProps[`image_0`]);

        //upload new image
        const {
          data: { image }
        } = await mediaServerAddress.post(`image`, fd);
        imageId = image._id;
      } else {
        imageId = coverImage._id;
        delete uselessImages[coverImage._id];
      }
    }

    const {
      data: { folder }
    } = await axios.patch(`category/folder/${folderId}`, {
      patch: {
        action: `changeCover`,
        data: {
          title,
          link,
          isHidden,
          imageId,
          userAccess
        }
      }
    });

    //clean up old images
    await axios.delete(`image`, {
      data: { imageIds: Object.keys(uselessImages) }
    });

    dispatch({ type: UPDATE_FOLDER, payload: { folder } });

    if (callback) callback();
  };

export const moveFolder =
  (categoryId, { folderId, from, to }, callback) =>
  async (dispatch, getStore) => {
    const {
      data: { category }
    } = await axios.patch(`category/${categoryId}`, {
      patch: {
        action: `moveFolder`,
        data: {
          folderId,
          from,
          to
        }
      }
    });

    dispatch({ type: UPDATE_CATEGORY, payload: { category } });

    if (callback) callback();
  };

// delete folder
export const deletedownloadFolder =
  ({ categoryId, folderId }, callback) =>
  async (dispatch, getStore) => {
    const {
      data: { category }
    } = await axios.patch(`category/${categoryId}`, {
      patch: {
        action: `deleteFolder`,
        data: {
          folderId
        }
      }
    });

    const {
      data: { folderMap }
    } = await axios.get(`category/folder?categoryId=${categoryId}`);

    batch(() => {
      dispatch({
        type: UPDATE_CATEGORY,
        payload: { category }
      });
      dispatch({ type: FETCH_FOLDERS, payload: { folderMap } });
    });
    if (callback) callback();
  };

/*-----------folder end------------*/

// /*---------------page start-----------*/

export const createDownloadPage =
  (formProps, callback) => async (dispatch, getStore) => {
    const {
      data: { page }
    } = await axios.post(`category/folder/page`, formProps);

    const {
      data: { folder }
    } = await axios.patch(`category/folder/${formProps.folderId}`, {
      patch: {
        action: `addDownloadPage`,
        data: {
          categoryId: formProps.categoryId,
          downloadPageId: page._id
        }
      }
    });

    batch(() => {
      dispatch({ type: UPDATE_PAGE, payload: { page } });

      dispatch({
        type: UPDATE_FOLDER,
        payload: { folder }
      });
    });

    if (callback) callback(page);
  };

// delete page
export const deletedownloadPage =
  ({ folderId, pageId }, callback) =>
  async (dispatch, getStore) => {
    const {
      data: { folder }
    } = await axios.patch(`category/folder/${folderId}`, {
      patch: {
        action: `deleteDownloadPage`,
        data: {
          pageId
        }
      }
    });

    const {
      data: { pageMap }
    } = await axios.get(`page?folderId=${folderId}`);

    batch(() => {
      dispatch({ type: UPDATE_FOLDER, payload: { folder } });
      dispatch({ type: FETCH_PAGES, payload: { pageMap } });
    });

    if (callback) callback();
  };

// move page
export const movedownloadPage =
  (folderId, { pageId, from, to }, callback) =>
  async (dispatch, getStore) => {
    const {
      data: { folder }
    } = await axios.patch(`category/folder/${folderId}`, {
      patch: {
        action: `moveDownloadPage`,
        data: {
          pageId,
          from,
          to
        }
      }
    });

    dispatch({ type: UPDATE_FOLDER, payload: { folder } });

    if (callback) callback();
  };

// /*---------------page end-----------*/

/*------------------import pages form other folder start---------------------*/

// import pages from other folder
export const importPages =
  ({ pageIds, folderId }, callback) =>
  async (dispatch, getStore) => {
    const {
      data: { folder }
    } = await axios.patch(`category/folder/${folderId}`, {
      patch: {
        action: "COPY_DOWNLOAD_PAGES",
        data: { pageIds }
      }
    });

    const {
      data: { pageMap }
    } = await axios.get(`page?folderId=${folderId}`);

    batch(() => {
      dispatch({ type: UPDATE_FOLDER, payload: { folder } });
      dispatch({ type: FETCH_PAGES, payload: { pageMap } });
    });

    if (callback) callback();
  };
/*------------------import pages form other folder end---------------------*/

/*------------------import folder from other category start-----------------*/
export const importFolders =
  ({ categoryId, folderIds, copy }, callback) =>
  async (dispatch, getStore) => {
    const {
      data: { category }
    } = await axios.patch(`category/${categoryId}`, {
      patch: {
        action: "COPY_DOWNLOAD_FOLDERS",
        data: { folderIds, categoryId }
      }
    });

    const {
      data: { folderMap }
    } = await axios.get(`category/folder?categoryId=${categoryId}`);

    batch(() => {
      dispatch({ type: UPDATE_CATEGORY, payload: { category } });
      dispatch({ type: FETCH_FOLDERS, payload: { folderMap } });
    });

    if (callback) callback();
  };

/*------------------import folder from other category end-----------------*/

/*------------------move pages to other folder start-----------------*/

// move the download page
export const moveDownloadPages =
  ({ folderId, toFolderId, toCategoryId, pageIds }, callback) =>
  async (dispatch, getStore) => {
    const {
      data: { folderMap }
    } = await axios.post(`moveDownloadPages`, {
      toFolderId,
      fromFolderId: folderId,
      pageIds
    });

    const {
      data: { pageMap }
    } = await axios.get(`page?folderId=${folderId}`);

    batch(() => {
      dispatch({ type: FETCH_PAGES, payload: { pageMap } });
      dispatch({ type: FETCH_FOLDERS, payload: { folderMap } });
    });

    if (callback) callback();
  };
/*------------------move pages to other folder end-----------------*/

/*------------------move folders to other category start-----------------*/

export const moveDownloadFolders =
  ({ categoryId, toCategoryId, folderIds }, callback) =>
  async (dispatch, getStore) => {
    const {
      data: { categoryMap }
    } = await axios.post(`moveDownloadFolders`, {
      toCategoryId,
      fromCategoryId: categoryId,
      folderIds
    });

    const {
      data: { folderMap }
    } = await axios.get(`category/folder?categoryId=${categoryId}`);

    batch(() => {
      dispatch({ type: FETCH_CATEGORYS, payload: { categoryMap } });
      dispatch({ type: FETCH_FOLDERS, payload: { folderMap } });
    });

    if (callback) callback();
  };

/*------------------move folders to other category end-----------------*/

/*------------------link download pages start-----------------*/

export const linkDownloadPages =
  ({ pageId, categoryId, folderId, downloadPageIds }, callback) =>
  async (dispatch, getStore) => {
    const data = { pageId, categoryId, folderId, downloadPageIds };

    const {
      data: { page }
    } = await axios.post(`download/attach/files`, data);

    batch(() => {
      dispatch({
        type: UPDATE_PAGE,
        payload: { page }
      });
    });

    if (callback) callback();
  };

export const downloadZipFile =
  ({ downloadPageIds, fileName }, callback) =>
  async (dispatch, getStore) => {
    const {
      data: { zipFile }
    } = await axios.post(`download/zipFile`, {
      downloadPageIds,
      fileName
    });

    if (callback) callback();

    return zipFile;
  };

/*------------------link download pages end-----------------*/
