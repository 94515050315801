export default {
  en: {
    // home
    "Reset Password": "Reset Password",
    "Delete School": "Delete School",
    Hello: "Hello",
    Recommendations: "Recommendations",
    Announcements: "Announcements",
    Announcement: "Announcement",
    Title: "Title",
    Date: "Date",
    "Frequently Visited": "Frequently Visited",
    Close: "Close",
    "Recently Viewed": "Recently Viewed",
    Content: "Content",
    Detail: "Detail",
    Progress: "Progress",
    // setting
    Username: "Username",
    "Account activated on": "Account activated on",
    "Date of Birth": "Date of Birth",
    Email: "Email",
    WeChat: "WeChat",
    unfilled: "unfilled",
    Language: "Language",
    Password: "Password",
    "New password": "New password",
    "Confirm new password": "Confirm new password",
    Submit: "Submit",
    Cancel: "Cancel",
    "Deactivate Account": "Deactivate Account",
    "Checking password": "Checking password",
    Modify: "Modify",
    Required: "Required",
    "Invalid email format": "Invalid email format",
    "Passwords are not the same": "Passwords are not the same",
    "Changing password": "Changing password",
    // schools
    Schools: "Schools",
    School: "School",
    Classroom: "Classroom",
    "Teaching Assistant": "Teaching Assistant",
    Downloads: "Downloads",
    "Teaching Video": "Teaching Video",
    Phone: "Phone",
    Location: "Location",
    "Contract Start": "Contract Start",
    "Contract End": "Contract End",
    Teachers: "Teachers",
    Name: "Name",
    "Signed in": "Signed in",
    Status: "Status",
    "Sign-up Code": "Sign-up Code",
    "Access Levels": "Access Levels",
    Groups: "Groups",
    "Online Monitor": "Online Monitor",
    Code: "Code",
    "Send Code": "Send Code",
    Send: "Send",
    To: "To",
    "Groups & Access": "Groups & Access",
    Tags: "Tags",
    "Group the accouts with tags. Add new tag in new line.":
      "Group the accouts with tags. Add new tag in new line.",
    "Content Access": "Content Access",
    "What can the teacher access? Select all that applies.":
      "What can the teacher access? Select all that applies.",
    "What can the school access?": "What can the school access?",
    "Select all that applies:": "Select all that applies:",
    "Not assigned": "Not assigned",
    Never: "Never",
    Expired: "Expired",
    "Will expire today": "Will expire today",
    "Will expire in ": "Will expire in ",
    " days": " days",
    Modification: "Modification",
    "Confirm Password": "Confirm Password",
    EXIT: "EXIT",
    "Teaching Notes": "Teaching Notes",
    "Add School": "Add School",
    "The contract has expired,please check school's information":
      "The contract has expired,please check school's information",
    // Resources / Downloads
    "Book Resources": "Book Resources",
    Others: "Others",
    "Lesson Plans": "Lesson Plans",
    "Teaching Videos": "Teaching Videos",
    Print: "Print",
    "Other Resources": "Other Resources",
    // Map
    Country: "Country",
    Province: "Province",
    City: "City",
    District: "District",
    Address: "Address",
    Book: "Book",
    Distance: "Distance",
    Reset: "Reset",
    Search: "Search",
    Searching: "Searching",
    "All Books": "All Books",
    Total: "Total",
    schools: "schools",
    "Ok! No school nearby.": "Ok! No school nearby.",
    "Warning! Other schools nearby.": "Warning! Other schools nearby.",
    "School Info": "School Info",
    Principal: "Principal",
    "Member Since": "Member Since",
    "Contract Duration": "Contract Duration",
    to: "to",
    Admin: "Admin",
    Editor: "Editor",
    Saler: "Saler",
    Exit: "Exit",
    "Zoom In": "Zoom In",
    // super
    Users: "Users",
    "Filter all the users by": "Filter all the users by",
    "User type": "User type",
    "Search for user": "Search for user",
    "Reactivate all selected": "Reactivate all selected",
    "Deactivate all selected": "Deactivate all selected",
    "Delete all selected": "Delete all selected",
    "Change email": "Change email",
    "Create a new admin、editor、saler account":
      "Create a new admin、editor、saler account",
    "Generate Login Code": "Generate Login Code",
    "Admin Login Code": "Admin Login Code",
    "Editor Login Code": "Editor Login Code",
    "Saler Login Code": "Saler Login Code",
    Mobile: "Mobile",
    "Please add a tag for classification of editors":
      "Please add a tag for classification of editors",
    Note: "Note",
    "Users must be": "Users must be",
    Deactivated: "Deactivated",
    or: "or",
    Active: "Active",
    "Never activated": "Never activated",
    "before they can be deleted.": "before they can be deleted.",
    Parent: "Parent",
    "User Type": "User Type",
    "Account activated": "Account activated",
    "Last signed in": "Last signed in",
    "Login Code": "Login Code",
    "Change Admin": "Change Admin",
    "Change Password": "Change Password",
    "Move from": "Move from",
    "Move to": "Move to",
    Move: "Move",
    "Please select a user!": "Please select a user!",
    "The account has been active": "The account has been active",
    "please select again": "please select again",
    "The account has been deactivated": "The account has been deactivated",
    "The account hasn't been signed up": "The account hasn't been signed up",
    "The account hasn't deactivated, please deactivate it first!":
      "The account hasn't deactivated, please deactivate it first!",
    "Please change Principal": "Please change Principal",
    "to another admin before you delete Admin":
      "to another admin before you delete Admin",
    "Changing Admin": "Changing Admin",
    "Are you sure you want to change this principal from":
      "Are you sure you want to change this principal from",
    Warning: "Warning",
    "Are you sure you want to delete the account(s)":
      "Are you sure you want to delete the account(s)",
    "This action cannot be undone.": "This action cannot be undone.",
    "The users’ information will be deleted forever.":
      "The users’ information will be deleted forever.",
    "Yes, I'm sure. Delete.": "Yes, I'm sure. Delete.",
    Confirm: "Confirm",
    "Please enter a name": "Please enter a name",
    "Are you sure you want to create": "Are you sure you want to create",
    admin: "admin",
    editor: "editor",
    saler: "saler",
    "Creating admin": "Creating admin",
    "Creating editor": "Creating editor",
    "Creating saler": "Creating saler",
    Reactivating: "Reactivating",
    Deactivating: "Deactivating",
    Deleting: "Deleting",
    "Other admins have deactivated": "Other admins have deactivated",
    "please activate one first": "please activate one first",
    "Please generate Admin Login Code first or select one never activated admin、editor、saler to resend email":
      "Please generate Admin Login Code first or select one never activated admin、editor、saler to resend email",
    "Please select one account to change email":
      "Please select one account to change email",
    "Email Login Code": "Email Login Code",
    "Please send the login code to the email below":
      "Please send the login code to the email below",
    "Please enter the email address": "Please enter the email address",
    Sending: "Sending",
    "Please wait": "Please wait",
    "The login code has been sent to the email you specified.":
      "The login code has been sent to the email you specified.",
    "Thank you.": "Thank you.",
    "Loading Accounts": "Loading Accounts",
    Accounts: "Accounts",
    "Principal Code": "Principal Code",
    "Principal Username": "Principal Username",
    "Principal Status": "Principal Status",
    "Number of teacher’s account to add": "Number of teacher’s account to add",
    "Add Teacher's Account": "Add Teacher's Account",
    "Teacher's Login Code": "Teacher's Login Code",
    "Account Status": "Account Status",
    "Teacher Groups": "Teacher Groups",
    Caution: "Caution",
    "The Customer will see the same thing":
      "The Customer will see the same thing",
    "Edit School": "Edit School",
    "School Name": "School Name",
    "The Customer will see what you've entered here":
      "The Customer will see what you've entered here",
    Next: "Next",
    Prev: "Prev",
    "Contract Start Date": "Contract Start Date",
    "Contract End Date": "Contract End Date",
    "Contract end date must come after contract start date!":
      "Contract end date must come after contract start date!",
    "Updating school": "Updating school",
    "Adding Teacher Accounts": "Adding Teacher Accounts",
    "Deleting School": "Deleting School",
    "Are you sure you want to delete this school":
      "Are you sure you want to delete this school",
    "All data will be erased forever.": "All data will be erased forever.",
    "If you are sure, please type the school name":
      "If you are sure, please type the school name",
    "Title Name": "Title Name",
    Hide: "Hide",
    "White Logo": "White Logo",
    "Colored Logo": "Colored Logo",
    Save: "Save",
    "Are you sure you want to delete this":
      "Are you sure you want to delete this",
    title: "title",
    "If you are sure, please type the title name":
      "If you are sure, please type the title name",
    "Are you sure you want to delete this title":
      "Are you sure you want to delete this title",
    "This title cannot be deleted because it is not empty.":
      "This title cannot be deleted because it is not empty.",
    bookset: "bookset",
    "If you are sure, please type the bookset name":
      "If you are sure, please type the bookset name",
    unit: "unit",
    "If you are sure, please type the unit name":
      "If you are sure, please type the unit name",
    "This unit cannot be deleted because it is not empty.":
      "This unit cannot be deleted because it is not empty.",
    "Are you sure you want to delete this unit":
      "Are you sure you want to delete this unit",
    "Section Name": "Section Name",
    section: "section",
    "If you are sure, please type the section name":
      "If you are sure, please type the section name",
    "Are you sure you want to delete this section":
      "Are you sure you want to delete this section",
    "This section cannot be deleted because it is not empty.":
      "This section cannot be deleted because it is not empty.",
    Import: "Import",
    Edit: "Edit",
    "Delete All Selected": "Delete All Selected",
    "Restore All Selected": "Restore All Selected",
    Style: "Style",
    "Last Modified": "Last Modified",
    Author: "Author",
    "Are you sure you want to delete the page":
      "Are you sure you want to delete the page",
    "This action is unrecoverable.": "This action is unrecoverable.",
    "Are you sure you want to delete all selected pages":
      "Are you sure you want to delete all selected pages",
    Toolbar: "Toolbar",
    "You have not saved the page.": "You have not saved the page.",
    "You will lose all the modifications which haven't been saved.":
      "You will lose all the modifications which haven't been saved.",
    "Add Video Lists": "Add Video Lists",
    "NEW VIDEO": "NEW VIDEO",
    "Are you sure you want to delete the video list":
      "Are you sure you want to delete the video list",
    "This act is unrecoverable.": "This act is unrecoverable.",
    "Are you sure you want to delete the folder":
      "Are you sure you want to delete the folder",
    "Are you sure you want to delete the video":
      "Are you sure you want to delete the video",
    Rating: "Rating",
    Comment: "Comment",
    "Resolved?": "Resolved?",
    UpdateDate: "UpdateDate",
    "Student Assistant": "Student Assistant"
  },
  tw: {
    // home
    "Reset Password": "重設密碼",
    "Delete School": "刪除學校",
    Hello: "哈囉",
    Recommendations: "推薦使用",
    Announcements: "公告",
    Announcement: "公告",
    Title: "標題",
    Date: "日期",
    "Frequently Visited": "經常瀏覽",
    Close: "關閉",
    "Recently Viewed": "近期瀏覽",
    Content: "內容",
    Detail: "詳細內容",
    Progress: "進度",
    Username: "使用者名稱",
    "Account activated on": "帳號啟用於",
    "Date of Birth": "生日",
    Email: "電子郵件",
    WeChat: "微信",
    unfilled: "未填寫",
    Language: "語言",
    Password: "密碼",
    "New password": "新密碼",
    "Confirm new password": "確認新密碼",
    Submit: "提交",
    Cancel: "取消",
    "Deactivate Account": "停用本帳號",
    "Checking password": "密碼確認中",
    Modify: "修改中",
    "Invalid email format": "無效電子郵件格式",
    Required: "必填",
    "Passwords are not the same": "新密碼不一致",
    "Changing password": "密碼更換中",
    // schools
    Schools: "學校",
    School: "學校",
    Classroom: "教室",
    "Teaching Assistant": "教學小助教",
    Downloads: "資源下載",
    "Teaching Video": "師訓影片",
    Phone: "電話",
    Location: "地點",
    "Contract Start": "合約生效",
    "Contract End": "合約中止",
    Teachers: "教師群",
    Name: "名稱",
    "Signed in": "登入",
    Status: "狀態",
    "Sign-up Code": "註冊碼",
    "Access Levels": "權限等級",
    Groups: "群組",
    "Online Monitor": "在線人數",
    Code: "註冊碼",
    "Send Code": "發送註冊碼",
    Send: "發送",
    To: "至",
    "Groups & Access": "群組&權限",
    Tags: "標籤",
    "Group the accouts with tags. Add new tag in new line.":
      "於新的一行新增標籤以建立新的群組",
    "Content Access": "權限內容",
    "What can the teacher access? Select all that applies.":
      "選取老師可以瀏覽的內容",
    "What can the school access?": "選取學校可以瀏覽的內容:",
    "Select all that applies:": " ",
    "Not assigned": "尚未註冊",
    Never: "從未登入",
    Expired: "過期",
    "Will expire today": "將於今日過期",
    "Will expire in ": "將於 ",
    " days": " 天後過期",
    Modification: "修改",
    "Confirm Password": "密碼確認",
    EXIT: "離開",
    "Teaching Notes": "教學筆記",
    "Add School": "新增學校",
    "The contract has expired,please check school's information":
      "學校合約已過期，請確認學校資訊",
    // Resources
    "Book Resources": "課本資源",
    Others: "其它",
    "Lesson Plans": "課堂計畫",
    "Teaching Videos": "教學影片",
    Print: "列印",
    "Other Resources": "其它資源",
    // Map
    Country: "地點",
    Province: "省",
    City: "城市",
    District: "區",
    Address: "地址",
    Book: "書名",
    Distance: "距離",
    Reset: "重設",
    Search: "搜尋",
    Searching: "搜尋中",
    "All Books": "所有書名",
    Total: "總數",
    schools: "學校",
    "Ok! No school nearby.": "附近無其它學校使用此本書籍!",
    "Warning! Other schools nearby.": "警告! 附近有學校使用此本書籍",
    "School Info": "學校資料",
    Principal: "校長",
    "Member Since": "加入日期",
    "Contract Duration": "合約期限",
    to: "至",
    Admin: "管理者",
    Editor: "編撰者",
    Saler: "業務",
    Exit: "離開",
    "Zoom In": "放大",
    // super
    Users: "使用者",
    "Filter all the users by": "篩選所有使用者",
    "User type": "使用者類型",
    "Search for user": "搜尋使用者",
    "Reactivate all selected": "重啟所選帳號",
    "Deactivate all selected": "停用所選帳號",
    "Delete all selected": "刪除所選帳號",
    "Change email": "更改電子郵件",
    "Create a new admin、editor、saler account": "新增管理者、編撰者、業務帳號",
    "Generate Login Code": "產生註冊碼",
    "Admin Login Code": "管理者註冊碼",
    "Editor Login Code": "編撰者註冊碼",
    "Saler Login Code": "業務註冊碼",
    Mobile: "手機簡訊",
    "Moving book...": "移動課本中...",
    "Please add a tag for classification of editors":
      "請新增標籤以對編輯者進行分類",
    Note: "備註",
    "Users must be": "使用者狀態必須是",
    Deactivated: "停用",
    or: "或",
    Active: "使用中",
    "Never activated": "從未啟動",
    "before they can be deleted.": "才能刪除",
    Parent: "父層",
    "User Type": "使用者類型",
    "Account activated": "帳號啟用日期",
    "Last signed in": "最近登入時間",
    "Login Code": "註冊碼",
    "Change Admin": "更換管理者",
    "Change Password": "更換密碼",
    "Move from": "從",
    "Move to": "移至",
    Move: "移動",
    "Please select a user!": "請勾選使用者!",
    "The account has been active": "帳號已啟動",
    "please select again": "請重新勾選",
    "The account has been deactivated": "帳號已停用",
    "The account hasn't been signed up": "帳號尚未註冊",
    "The account hasn't deactivated, please deactivate it first!":
      "請先停用帳號",
    "Please change Principal": "請更換校長",
    "to another admin before you delete Admin":
      "至另一個管理者,在刪除此管理者前",
    "Changing Admin": "管理者更換中",
    "Are you sure you want to change this principal from": "確定更換此校長從",
    Warning: "警告",
    "Are you sure you want to delete the account(s)": "確定要刪除此帳號",
    "This action cannot be undone.": "此動作將無法復原",
    "The users’ information will be deleted forever.": "使用者資料會永遠刪除",
    "Yes, I'm sure. Delete.": "是的,確定刪除",
    Notify: "提示",
    "When you logged into different browser or device, please login again.":
      "當您登入到不同的瀏覽器或設備時，請重新登入。",
    Confirm: "確定",
    "Please enter a name": "請輸入名稱",
    "Are you sure you want to create": "確定新增",
    admin: "管理者",
    editor: "編撰者",
    saler: "業務",
    "Creating admin": "新增管理者中",
    "Creating editor": "新增編撰者中",
    "Creating saler": "新增業務中",
    Reactivating: "重新啟動中",
    Deactivating: "停用中",
    Deleting: "正在刪除",
    "Other admins have deactivated": "其他管理者已停用",
    "please activate one first": "請先啟動任一管理者",
    "Please generate Admin Login Code first or select one never activated admin、editor、saler to resend email":
      "請先產生註冊碼或選擇尚未啟動的管理者、編撰者、業務以發送電子郵件",
    "Please select one account to change email": "請選擇一帳號改變電子郵件",
    "Email Login Code": "發送註冊碼",
    "Please send the login code to the email below":
      "請發送註冊碼至下方電子郵件",
    "Please enter the email address": "請輸入電子郵件地址",
    Sending: "發送中",
    "Please wait": "請稍後",
    "The login code has been sent to the email you specified.":
      "註冊碼已發送至您剛填入的郵件位址,",
    "Thank you.": "感謝您!",
    "Loading Accounts": "帳號資料讀取中",
    Accounts: "帳號",
    "Principal Code": "校長註冊碼",
    "Principal Username": "校長使用者名稱",
    "Principal Status": "校長狀態",
    "Number of teacher’s account to add": "老師帳號新增數量",
    "Add Teacher's Account": "新增老師帳號",
    "Teacher's Login Code": "老師註冊碼",
    "Account Status": "帳號狀態",
    "Teacher Groups": "群組",
    Caution: "注意",
    "The Customer will see the same thing": "客戶會看到此處輸入的內容",
    "Edit School": "修改學校",
    "School Name": "學校名稱",
    "The Customer will see what you've entered here":
      "客戶會看到此處輸入的內容",
    Next: "下一步",
    Prev: "上一步",
    "Contract Start Date": "合約生效日期",
    "Contract End Date": "合約結束日期",
    "Contract end date must come after contract start date!":
      "合約結束日期必須在生效日期之後",
    "Updating school": "更新學校中",
    "Adding Teacher Accounts": "新增老師帳號中",
    "Deleting School": "刪除學校中",
    "Are you sure you want to delete this school": "確定要刪除此間學校",
    "All data will be erased forever.": ",資料會永遠消失",
    "If you are sure, please type the school name":
      "如已確認,請輸入此間學校名稱",
    "Title Name": "標題名稱",
    Hide: "隱藏",
    "White Logo": "白色Logo",
    "Colored Logo": "彩色Logo",
    Save: "儲存",
    "Saving page...": "頁面儲存中...",
    "Are you sure you want to delete this": "確定要刪除此",
    title: "標題",
    "If you are sure, please type the title name": "如已確認,請輸入此標題名稱",
    "Are you sure you want to delete this title": "確定要刪除此標題",
    "This title cannot be deleted because it is not empty.":
      "無法刪除尚未清空的標題",
    bookset: "套書",
    "book type": "書類",
    "If you are sure, please type the bookset name": "如已確認,請輸入套書名稱",
    unit: "單元",
    "If you are sure, please type the unit name": "如已確認,請輸入單元名稱",
    "This unit cannot be deleted because it is not empty.":
      "無法刪除尚未清空的單元",
    "Are you sure you want to delete this unit": "確定要刪除此單元",
    "Section Name": "標題名稱",
    section: "小節",
    "If you are sure, please type the section name": "如已確認,請輸入小節名稱",
    "Are you sure you want to delete this section": "確定要刪除此小節",
    "This section cannot be deleted because it is not empty.":
      "無法刪除尚未清空的小節",
    Import: "匯入",
    Edit: "編輯",
    "Delete All Selected": "刪除所選",
    "Restore All Selected": "復原所選",
    Style: "樣式",
    "Last Modified": "上次修改日期",
    Author: "作者",
    "Are you sure you want to delete the page": "確定要刪除此頁面",
    "This action is unrecoverable.": "此動作將無法復原",
    "Are you sure you want to delete all selected pages": "確定要刪除所選頁面",
    Toolbar: "工具列",
    "You have not saved the page.": "此頁尚未儲存",
    "You will lose all the modifications which haven't been saved.":
      "您將會失去尚未儲存的修改",
    "Are you sure you want to leave?": "確定要離開?",
    "Add Video Lists": "新增影片清單",
    "NEW VIDEO": "新影片",
    "Are you sure you want to delete the video list": "確定要刪除此影片清單",
    "This act is unrecoverable.": "此動作將無法復原",
    "Are you sure you want to delete the folder": "確定要刪除此資料夾",
    "Are you sure you want to delete the video": "確定要刪除此影片",
    Drop: "放開",
    Drag: "拖曳",
    " a zip here ...": "一個壓縮檔到這 ...",
    " a zip here,": "一個壓縮檔到這，",
    " or ": "或",
    Click: "點擊",
    " to select a zip": "選取一個壓縮檔",
    "Max zip size is 300MB. Click me if you got a problem":
      "壓縮檔最大300MB。如果遇到問題，請點我",
    "Accepted file": "接收的檔案",
    "Rejected file": "拒絕的檔案",
    "Something went wrong ...": "發生了問題 ...",
    "Check Our Troubleshooting Guide": "查看我們的故障排除指南",
    "Try Upload Zip Again": "試著再上傳一次",
    "Preview and save it": "預覽完後，請保存它",
    "No data. ": "空白頁。",
    Upload: "上傳",
    "Uploading...": "上傳中...",
    "Uploading data...": "上傳檔案中...",
    "Upload Finished": "上傳完成",
    " a zip": "一個壓縮檔",
    "File type must be .zip": "檔案類型必須是.zip",
    "Too many files": "一次只能一個壓縮檔",
    "File is larger than 300000000 bytes": "壓縮檔超過300MB",
    "View mode": "瀏覽模式",
    "Edit mode": "編輯模式",
    "Please save or cancel your edit first": "請先保存或取消您的編輯",
    "Page saved": "頁面已保存",
    "System X High Efficiency X Rapid": "系統化 X 高效能 X 更快速",
    DownloadArea: "軟體下載",
    "This action cannot be undone and all data will be erased forever.":
      "此動作將無法復原且資料會永遠消失",
    "If you are sure, please type the book type name": "如已確認,請輸入書類名",
    "Are you sure you want to delete this book type? \nThis action cannot be undone.":
      "確定要刪除此書類? \n此動作將無法復原",
    "This book type cannot be deleted because it is not empty.":
      "此書類尚有內容,無法刪除",
    "Duplicate books": "複製課本",
    "Ready to move bookType ": "準備移動書類別 ",
    Trash: "資源垃圾桶",
    "Stats Main": "統計主頁",
    "Teacher list": "老師列表",
    Stats: "統計資料",
    Off: "斷線",
    Rating: "評分",
    Comment: "評論",
    "Resolved?": "已修正?",
    UpdateDate: "更新時間",
    "Student Assistant": "學生小助教"
  },
  cn: {
    // home
    "Reset Password": "重设密码",
    "Delete School": "删除学校",
    Hello: "哈罗",
    Recommendations: "推荐使用",
    Announcements: "公告",
    Announcement: "公告",
    Title: "标题",
    Date: "日期",
    "Frequently Visited": "经常浏览",
    Close: "关闭",
    "Recently Viewed": "近期浏览",
    Content: "内容",
    Detail: "详细内容",
    Progress: "进度",
    Username: "使用者名称",
    "Account activated on": "帐号激活於",
    "Date of Birth": "生日",
    Email: "电子邮件",
    WeChat: "微信",
    unfilled: "未填写",
    Language: "语言",
    Password: "密码",
    "New password": "新密码",
    "Confirm new password": "确认新密码",
    Submit: "提交",
    Cancel: "取消",
    "Deactivate Account": "停用本帐号",
    "Checking password": "密码确认中",
    Modify: "修改中",
    Required: "必填",
    "Invalid email format": "无效电子邮件格式",
    "Passwords are not the same": "新密码不一致",
    "Changing password": "密码更換中",
    // schools
    Schools: "学校",
    School: "学校",
    Classroom: "教室",
    "Teaching Assistant": "教学小助教",
    Downloads: "资源下载",
    "Teaching Video": "师训视频",
    Phone: "电话",
    Location: "地点",
    "Contract Start": "合同生效",
    "Contract End": "合同中止",
    Teachers: "教师群",
    Name: "名称",
    "Signed in": "登入",
    Status: "状态",
    "Sign-up Code": "注册码",
    "Access Levels": "权限等级",
    Groups: "群组",
    "Online Monitor": "在线人数",
    Code: "注册码",
    "Send Code": "发送注册码",
    Send: "发送",
    To: "至",
    "Groups & Access": "群组&权限",
    Tags: "标签",
    "Group the accouts with tags. Add new tag in new line.":
      "於新的一行新增标签以建立新的群组",
    "Content Access": "权限内容",
    "What can the teacher access? Select all that applies.":
      "选取老师可以浏览的内容",
    "What can the school access?": "选取老师可以浏览的内容:",
    "Select all that applies:": " ",
    "Not assigned": "尚未注册",
    Never: "从未登入",
    Expired: "过期",
    "Will expire today": "将於今日过期",
    "Will expire in ": "将於 ",
    " days": " 天後过期",
    Modification: "修改",
    "Confirm Password": "密码确认",
    EXIT: "离开",
    "Teaching Notes": "教学笔记",
    "Add School": "新增学校",
    "The contract has expired,please check school's information":
      "合同已过期，请检查学校资讯",
    // Resources
    "Book Resources": "课本资源",
    Others: "其它",
    "Lesson Plans": "课堂计画",
    "Teaching Videos": "教学影片",
    Print: "列印",
    "Other Resources": "其它资源",
    // Map
    Country: "地点",
    Province: "省",
    City: "城市",
    District: "区",
    Address: "地址",
    Book: "书名",
    Distance: "距离",
    Reset: "重设",
    Search: "搜寻",
    Searching: "搜寻中",
    "All Books": "所有书名",
    Total: "总数",
    schools: "学校",
    "Ok! No school nearby.": "附近无其它学校使用此本书籍!",
    "Warning! Other schools nearby.": "警告! 附近有学校使用此本书籍",
    "School Info": "学校资料",
    Principal: "校长",
    "Member Since": "加入日期",
    "Contract Duration": "合同期限",
    to: "至",
    Admin: "管理者",
    Editor: "编撰者",
    Saler: "业务",
    Exit: "离开",
    "Zoom In": "放大",
    // super
    Users: "使用者",
    "Filter all the users by": "筛选所有使用者",
    "User type": "使用者类型",
    "Search for user": "搜寻使用者",
    "Reactivate all selected": "重启所选帐号",
    "Deactivate all selected": "停用所选帐号",
    "Delete all selected": "删除所选帐号",
    "Change email": "更改电子邮件",
    "Create a new admin、editor、saler account": "新增管理者、编撰者、业务帐号",
    "Generate Login Code": "产生注册码",
    "Admin Login Code": "管理者注册码",
    "Editor Login Code": "编撰者注册码",
    "Saler Login Code": "业务注册码",
    Mobile: "手机简讯",
    "Moving book...": "移动课本中...",
    "Please add a tag for classification of editors":
      "请添加标签以对编辑者进行分类",
    Note: "备注",
    "Users must be": "使用者状态必须是",
    Deactivated: "停用",
    or: "或",
    Active: "使用中",
    "Never activated": "从未激活",
    "before they can be deleted.": "才能删除",
    Parent: "父层",
    "User Type": "使用者类型",
    "Account activated": "帐号激活日期",
    "Last signed in": "最近登入时间",
    "Login Code": "注册码",
    "Change Admin": "更换管理者",
    "Change Password": "更换密码",
    "Move from": "从",
    "Move to": "移至",
    Move: "移动",
    "Please select a user!": "请勾选使用者!",
    "The account has been active": "帐号已激活",
    "please select again": "请重新勾选",
    "The account has been deactivated": "帐号已停用",
    "The account hasn't been signed up": "帐号尚未注册",
    "The account hasn't deactivated, please deactivate it first!":
      "请先停用帐号",
    "Please change Principal": "请更换校长",
    "to another admin before you delete Admin":
      "至另一个管理者,在删除此管理者前",
    "Changing Admin": "管理者更换中",
    "Are you sure you want to change this principal from": "确定更换此校长从",
    Warning: "警告",
    "Are you sure you want to delete the account(s)": "确定要删除此帐号",
    "This action cannot be undone.": "此动作将无法复原",
    "The users’ information will be deleted forever.": "使用者资料会永远删除",
    "Yes, I'm sure. Delete.": "是的,确定删除",
    Notify: "提示",
    "When you logged into different browser or device, please login again.":
      "当您登入到不同的浏览器或设备时，请重新登入。",
    Confirm: "确定",
    "Please enter a name": "请输入名称",
    "Are you sure you want to create": "确定新增",
    admin: "管理者",
    editor: "编辑者",
    saler: "业务",
    "Creating admin": "新增管理者中",
    "Creating editor": "新增编辑者中",
    "Creating saler": "新增业务中",
    Reactivating: "重新激活中",
    Deactivating: "停用中",
    Deleting: "正在删除",
    "Other admins have deactivated": "其他管理者已停用",
    "please activate one first": "请先激活任一管理者",
    "Please generate Admin Login Code first or select one never activated admin、editor、saler to resend email":
      "请先产生注册码或选择尚未激活的管理者、编撰者、业务以发送电子邮件",
    "Please select one account to change email": "请选择一个帐户更改电子邮件",
    "Email Login Code": "发送注册码",
    "Please send the login code to the email below":
      "请发送注册码至下方电子邮件",
    "Please enter the email address": "请输入电子邮件地址",
    Sending: "发送中",
    "Please wait": "请稍後",
    "The login code has been sent to the email you specified.":
      "注册码已发送至您刚填入的邮件位址,",
    "Thank you.": "感谢您!",
    "Loading Accounts": "帐号资料读取中",
    Accounts: "帐号",
    "Principal Username": "校长使用者名称",
    "Principal Status": "校长状态",
    "Number of teacher’s account to add": "老师帐号新增数量",
    "Add Teacher's Account": "新增老师帐号",
    "Teacher's Login Code": "老师注册码",
    "Account Status": "帐号状态",
    "Teacher Groups": "群组",
    Caution: "注意",
    "The Customer will see the same thing": "客户会看到此处输入的内容",
    "Edit School": "修改",
    "School Name": "学校名称",
    "The Customer will see what you've entered here":
      "客户会看到此处输入的内容",
    Next: "下一步",
    Prev: "上一步",
    "Contract Start Date": "合同生效日期",
    "Contract End Date": "合同结束日期",
    "Contract end date must come after contract start date!":
      "合同结束日期必须在生效日期之後",
    "Updating school": "更新学校中...",
    "Adding Teacher Accounts": "新增老师帐号中",
    "Deleting School": "删除学校中",
    "Are you sure you want to delete this school": "确定要删除此间学校",
    "All data will be erased forever.": ",资料会永远消失",
    "If you are sure, please type the school name":
      "如已确认,请输入此间学校名称",
    "Title Name": "标题名称",
    Hide: "隐藏",
    "White Logo": "白底Logo",
    "Colored Logo": "彩色Logo",
    Save: "储存",
    "Saving page...": "页面储存中...",
    "Are you sure you want to delete this": "确定要删除此",
    title: "标题",
    "If you are sure, please type the title name": "如已确认,请输入此标题名称",
    "Are you sure you want to delete this title": "确定要删除此标题",
    "This title cannot be deleted because it is not empty.":
      "无法删除尚未清空的标题",
    bookset: "套书",
    "book type": "书类",
    "If you are sure, please type the bookset name": "如已确认,请输入套书名称",
    unit: "单元",
    "If you are sure, please type the unit name": "如已确认,请输入单元名称",
    "This unit cannot be deleted because it is not empty.":
      "无法删除尚未清空的单元",
    "Are you sure you want to delete this unit": "确定要删除此单元",
    "Section Name": "标题名称",
    section: "小節",
    "If you are sure, please type the section name": "如已确认,请输入小节名称",
    "Are you sure you want to delete this section": "确定要删除此小节",
    "This section cannot be deleted because it is not empty.":
      "无法删除尚未清空的小节",
    Import: "汇入",
    Edit: "编辑",
    "Delete All Selected": "删除所选",
    "Restore All Selected": "复原所选",
    Style: "样式",
    "Last Modified": "上次修改日期",
    Author: "作者",
    "Are you sure you want to delete the page": "确定要删除此页面",
    "This action is unrecoverable.": "此动作将无法复原",
    "Are you sure you want to delete all selected pages": "确定要删除所选页面",
    Toolbar: "工具列",
    "You have not saved the page.": "此页尚未储存",
    "You will lose all the modifications which haven't been saved.":
      "您将会失去尚未储存的修改",
    "Are you sure you want to leave?": "确定要离开?",
    "Add Video Lists": "新增视频清单",
    "NEW VIDEO": "新视频",
    "Are you sure you want to delete the video list": "确定要删除此视频清单",
    "This act is unrecoverable.": "此动作将无法复原",
    "Are you sure you want to delete the folder": "确定要删除此资料夹",
    "Are you sure you want to delete the video": "确定要删除此视频",
    Drop: "放开",
    Drag: "拖曳",
    " a zip here ...": "一个压缩文件到这 ...",
    " a zip here,": "一个压缩文件到这，",
    " or ": "或",
    Click: "点击",
    " to select a zip": "选一个压缩文件",
    "Max zip size is 300MB. Click me if you got a problem":
      "压缩文件最大300MB。如果遇到问题，请点我",
    "Accepted file": "接收的文件",
    "Rejected file": "拒绝的文件",
    "Something went wrong ...": "发生了问题 ...",
    "Check Our Troubleshooting Guide": "查看我们的故障排除指南",
    "Try Upload Zip Again": "试着再上传一次",
    "Preview and save it": "预览完后，请保存它",
    "No data. ": "空白页。",
    Upload: "上传",
    "Uploading...": "上传中...",
    "Uploading data...": "上传档案中...",
    "Upload Finished": "上传完成",
    " a zip": "一个压缩文件",
    "File type must be .zip": "文件类型必须是.zip",
    "Too many files": "一次只能一个压缩文件",
    "File is larger than 300000000 bytes": "压缩文件超过300MB",
    "View mode": "浏览模式",
    "Edit mode": "编辑模式",
    "Please save or cancel your edit first": "请先保存或取消您的编辑",
    "Page saved": "页面已保存",

    "System X High Efficiency X Rapid": "系统化 X 高效能 X 更快速",
    DownloadArea: "软件下载",
    "This action cannot be undone and all data will be erased forever.":
      "此动作将无法复原且资料会永远消失",
    "If you are sure, please type the book type name": "如已确认,请输入书类名",
    "Are you sure you want to delete this book type? \nThis action cannot be undone.":
      "确定要删除此书类? \n此动作将无法复原",
    "This book type cannot be deleted because it is not empty.":
      "此书类尚有内容,无法删除",
    "Duplicate books": "复制课本",
    "Ready to move bookType ": "准备移动书类别 ",
    Trash: "资源回收桶",
    "Stats Main": "统计主页",
    "Teacher list": "老师列表",
    Stats: "统计资料",
    Off: "断线",
    Rating: "评分",
    Comment: "评论",
    "Resolved?": "已修正?",
    UpdateDate: "更新时间",
    "Student Assistant": "学生小助教"
  }
};
