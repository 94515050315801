import {
  UPDATE_STUDENTANSWER,
  FETCH_STUDENTANSWER,
  FETCH_CLASSROOMSTUDENTANSWER
} from "../actions/type";

const INIT_STATE = { studentAnswerMap: {} };

export default (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case FETCH_STUDENTANSWER:
      return {
        ...state,
        studentAnswerMap: payload?.reduce((map, item) => {
          map[item._unit] = item; // 使用每個 payload item 的 _unit 作為 key
          return map;
        }, {}) // 這裡沒有保留舊的 state.studentAnswerMap，完全取代
      };

    case FETCH_CLASSROOMSTUDENTANSWER:
      return {
        ...state,
        studentAnswerMap: payload?.reduce((map, item) => {
          // 檢查 map[item._user] 是否已經存在
          if (map[item._user]) {
            // 如果存在，將新的 item 加入到該 _user 的陣列中
            map[item._user] = [...map[item._user], item];
          } else {
            // 如果不存在，初始化為包含當前 item 的陣列
            map[item._user] = [item];
          }
          return map;
        }, {}) // 注意這裡傳入空對象，新的 payload 直接蓋過舊的資料
      };

    case UPDATE_STUDENTANSWER:
      return {
        ...state,
        studentAnswerMap: {
          ...state.studentAnswerMap,
          [payload.studentAnswer._unit]: {
            ...payload.studentAnswer
          }
        }
      };

    default:
      return state;
  }
};
