import { SET_ERROR, CLEAN_AUTH, CLOSE_ERROR } from "../actions/type";

const INIT_STATE = { errorMessage: "", show: false };

export default (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case SET_ERROR:
      return {
        errorMessage: payload || `Some bad thing happened!`,
        show: true
      };

    case CLOSE_ERROR:
      return { errorMessage: "", show: false };

    case CLEAN_AUTH:
      return { ...INIT_STATE };

    default:
      return state;
  }
};
