import {
  FETCH_BOOKS,
  UPDATE_BOOK,
  CLEAN_AUTH,
  LOADING_BOOKS
} from "../actions/type";

const INIT_STATE = { bookMap: {}, isLoadingBooks: false };

export default (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case FETCH_BOOKS:
      return {
        ...state,
        bookMap: { ...payload.bookMap },
        isLoadingBooks: false
      };

    case LOADING_BOOKS:
      return { ...state, isLoadingBooks: true };

    case UPDATE_BOOK:
      return {
        ...state,
        bookMap: { ...state.bookMap, [payload.book._id]: payload.book }
      };

    case CLEAN_AUTH:
      return { ...INIT_STATE };

    default:
      return state;
  }
};
