import { SET_CONTEXTMENU, CLOSE_CONTEXTMENU } from "./type";

export const setContextMenu = (menu, position) => ({
  type: SET_CONTEXTMENU,
  payload: { menu, position }
});

export const closeContextMenu = () => ({
  type: CLOSE_CONTEXTMENU,
  payload: {}
});
