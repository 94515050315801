import { useContext } from "react";
import { useConfirm } from "material-ui-confirm";

import StrictConfirmContext from "./StrictConfirmContext";

// utils
import { makeScripts } from "../languageHelpers";

export { default as StrictConfirmProvider } from "./StrictConfirmProvider";

export const useConfirmAction = (when, action, options = {}) => {
  const confirm = useConfirm();
  const scripts = useScripts();

  const {
    title = scripts.title,
    description = "",
    confirmationText = scripts.confirmationText,
    cancellationText = scripts.cancellationText
  } = options;

  return (...args) =>
    when
      ? confirm({
          title,
          description,
          confirmationText,
          cancellationText,
          confirmationButtonProps: { color: "secondary" }
        })
          .then(() => action(...args))
          .catch(() => {})
      : action(...args);
};

export const useConfirmActions = (when, ...actions) => {
  const confirm = useConfirm();
  const scripts = useScripts();
  let options = {};

  if (actions.length && typeof actions[actions.length - 1] !== "function") {
    options = actions[actions.length - 1];
    actions = actions.slice(0, actions.length - 1);
  }

  const {
    title = scripts.title,
    description = "",
    confirmationText = scripts.confirmationText,
    cancellationText = scripts.cancellationText
  } = options;

  return actions.map(
    (action) =>
      (...args) =>
        when
          ? confirm({ title, description, confirmationText, cancellationText })
              .then(() => action(...args))
              .catch(() => {})
          : action(...args)
  );
};

export const useStrictConfirm = () => {
  const confirm = useContext(StrictConfirmContext);
  return confirm;
};

export const useStrictConfirmAction = (when, action, options = {}) => {
  const confirm = useStrictConfirm();

  return (...args) =>
    when
      ? confirm(options)
          .then(() => action(...args))
          .catch(() => {})
      : action(...args);
};

// language
const useScripts = makeScripts({
  title: { en: "Are you sure to do this?", tw: "您確定要執行嗎？" },
  confirmationText: { en: "Confirm", tw: "確認" },
  cancellationText: { en: "Cancel", tw: "取消" }
});
