import React from "react";

// utils
import { useScriptConverter } from "../utils/languageHelpers";

const DevMode = () => {
  const converter = useScriptConverter();

  if (
    process.env.NODE_ENV === "production" &&
    !(window.location.hostname && window.location.hostname.includes("dev"))
  )
    return null;

  return (
    <div
      style={{
        width: "100vw",
        minHeight: "100vh",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        pointerEvents: "none",
        position: "fixed",
        alignItems: "center",
        zIndex: 10000000
      }}
    >
      <span
        style={{
          color: "red",
          fontSize: 50,
          marginTop: 5
        }}
      >
        {converter({ en: "DEV ver.", tw: "開發中版本", cn: "开發中版本" })}
      </span>
    </div>
  );
};

export default DevMode;
