import React from "react";
import styled from "styled-components/macro";
import { connect } from "react-redux";
import { closeWarning } from "../actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

import MainContainer from "./elements/MainContainer";
import PrimaryButton from "./elements/PrimaryButton";
import SecondaryButton from "./elements/SecondaryButton";
import TranslateScript from "./elements/TranslateScript";

const Background = styled.div`
  display: ${({ show }) => (show ? `flex` : `none`)}; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 98; /* Sit on top */

  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.9); /* Black w/ opacity */

  align-items: center;
  justify-content: center;
`;

const AlertIcon = styled(FontAwesomeIcon)`
  color: white;
`;

const Container = styled.div`
  width: ${({ width }) => width || "30%"};
  height: ${({ height }) => height || "50%"};

  overflow: hidden;

  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 20% 50% 30%;
  grid-template-areas:
    "title"
    "content"
    "buttons";

  border-radius: 0.5em;
  background: white;
  margin: auto;
  position: relative;
`;

const Title = styled.div`
  grid-area: title;
  text-align: center;
  font-size: 2em;
  font-weight: 700;
  background: ${({ theme }) => theme.yellowSecondary};
  color: white;
`;

const Content = styled.div`
  grid-area: content;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const Description = styled.div`
  font-size: 1.2em;
  width: 80%;
  height: 80%;
  overflow-y: auto;
  white-space: pre-wrap;
  word-break: break-word;
`;

const Buttons = styled.div`
  grid-area: buttons;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const Warning = ({
  warning: { show, alertMessage, confirm, cancel, width, height },
  closeWarning
}) => {
  return (
    <Background show={show}>
      <MainContainer>
        <Container width={width} height={height}>
          <Title>
            <AlertIcon icon={faExclamationCircle} />{" "}
            <TranslateScript>Warning</TranslateScript>!
          </Title>

          <Content>
            <Description>{alertMessage}</Description>
          </Content>

          <Buttons>
            <PrimaryButton
              type="submit"
              themeColor="yellow"
              onClick={confirm || closeWarning}
            >
              Confirm
            </PrimaryButton>
            {cancel && (
              <SecondaryButton
                type="submit"
                onClick={() => {
                  closeWarning();
                  typeof cancel === "function" && cancel();
                }}
              >
                Cancel
              </SecondaryButton>
            )}
          </Buttons>
        </Container>
      </MainContainer>
    </Background>
  );
};

const mapStateToProps = ({ warning }) => ({ warning });

export default connect(mapStateToProps, { closeWarning })(Warning);
