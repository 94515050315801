import React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import styled from "styled-components/macro";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import { deleteNotice } from "../actions";

const Container = styled.div`
  width: 18em;
  position: fixed;
  right: 0;
  bottom: 3.5em;

  margin-right: 0.5em;

  display: flex;
  flex-direction: column;
  z-index: 95;

  pointer-events: none;
  background: rgba(0, 0, 0, 0);
`;

const FadeNotice = styled.div`
  width: 100%;
  opacity: 0.8;
  border-radius: 0.5em;
  margin: 0.1em 0;
  padding: 0.5em 1em;

  ${({ noticeType, theme }) => {
    switch (noticeType) {
      case "error":
        return `background: ${theme.red};
                color: #ffffff;`;

      case "alert":
        return `background: ${theme.yellow};
                color: #ffffff;`;

      case "clear":
        return `background: ${theme.green};
                color: #ffffff;`;

      default:
        return `background: #777777;
                color: #ffffff;`;
    }
  }}

  &.notice-enter {
    opacity: 0;
  }

  &.notice-enter-active {
    opacity: 0.6;
    transition: opacity 500ms ease-in;
  }

  &.notice-exit {
    opacity: 0.6;
  }

  &.notice-exit-active {
    opacity: 0;
    transition: opacity 500ms ease-in;
  }

  display: flex;
`;

const NoticeText = styled.div`
  width: 90%;
  grid-area: text;
`;

const NoticeButton = styled.div`
  width: 10%;
  grid-area: button;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  pointer-events: auto;
`;

const Notice = ({ noticelist, deleteNotice }) => {
  return (
    <Container>
      <TransitionGroup>
        {noticelist.map((notice) => (
          <CSSTransition classNames="notice" timeout={500} key={notice.id}>
            <FadeNotice noticeType={notice.noticeType}>
              <NoticeText> {notice.value}</NoticeText>
              <NoticeButton>
                <FontAwesomeIcon
                  icon={faTimes}
                  onClick={() => deleteNotice(notice.id)}
                />
              </NoticeButton>
            </FadeNotice>
          </CSSTransition>
        ))}
      </TransitionGroup>
    </Container>
  );
};

const mapStateToProps = ({ notice }) => ({
  noticelist: notice.notices
});

export default connect(mapStateToProps, { deleteNotice })(Notice);
