import { OPEN_FULLSCREEN, CLOSE_FULLSCREEN } from "./type";

// show full screen
export const openFullScreen = () => ({
  type: OPEN_FULLSCREEN
});

// close full screen
export const closeFullScreen = () => ({
  type: CLOSE_FULLSCREEN
});
