import {
  SET_CONTEXTMENU,
  CLEAN_AUTH,
  CLOSE_CONTEXTMENU
} from "../actions/type";

const INIT_STATE = {
  menu: [],
  position: {},
  show: false
};

export default (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case SET_CONTEXTMENU:
      return {
        show: true,
        menu: payload.menu,
        position: payload.position
      };

    case CLOSE_CONTEXTMENU:
      return { ...INIT_STATE };

    case CLEAN_AUTH:
      return { ...INIT_STATE };

    default:
      return state;
  }
};
