import {
  FETCH_UNITS,
  CLEAN_AUTH,
  UPDATE_UNIT,
  LOADING_UNITS
} from "../actions/type";

const INIT_STATE = { unitMap: {}, isLoadingUnits: false };

export default (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case FETCH_UNITS:
      return {
        ...state,
        unitMap: { ...payload.unitMap },
        isLoadingUnits: false
      };

    case LOADING_UNITS:
      return { ...state, isLoadingUnits: true };

    case UPDATE_UNIT:
      return {
        ...state,
        unitMap: { ...state.unitMap, [payload.unit._id]: payload.unit }
      };

    case CLEAN_AUTH:
      return { ...INIT_STATE };

    default:
      return state;
  }
};
