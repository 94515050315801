import React, { useMemo } from "react";

// utils
import { useScriptConverter } from "../../utils/languageHelpers";

// configs
import languageScripts from "../../config/languageScripts";

export default ({ children }) => {
  const converter = useScriptConverter();
  const scripts = useMemo(() => converter(languageScripts), [converter]);

  return <>{scripts[children] ? scripts[children] : children}</>;
};
