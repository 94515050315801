import { FETCH_STYLEVIEWS, UPDATE_STYLEVIEW } from "../actions/type";

const INIT_STATE = { styleviewMap: {} };

export default (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case FETCH_STYLEVIEWS:
      return {
        ...state,
        styleviewMap: { ...payload.styleviewMap }
      };

    case UPDATE_STYLEVIEW:
      return {
        ...state,
        styleviewMap: {
          ...state.styleviewMap,
          [payload.styleview._id]: payload.styleview
        }
      };

    default:
      return state;
  }
};
