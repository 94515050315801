import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import useResizeAware from "react-resize-aware";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const RatioBox = ({ width, height, ratio, children, ...props }) => {
  const [boxWidth, setBoxWidth] = useState(0);
  const boxRatio = width && height ? width / height : ratio || 1;

  const [resizeListener, sizes] = useResizeAware();

  useEffect(() => {
    const maxWidth = sizes.width;
    const maxHeight = sizes.height;
    if (maxWidth && maxHeight)
      setBoxWidth(Math.min(1, (maxHeight * boxRatio) / maxWidth) * maxWidth);
  }, [sizes.width, sizes.height, boxRatio]);

  return (
    <Wrapper {...props}>
      {resizeListener}
      <div
        style={{
          width: `${boxWidth}px`,
          height: `${boxWidth / boxRatio}px`,
          position: `relative`
        }}
      >
        {children}
      </div>
    </Wrapper>
  );
};

export default RatioBox;
