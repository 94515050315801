import React from "react";
import styled from "styled-components/macro";

// components
import TranslateScript from "./TranslateScript";

const Button = styled.button`
  cursor: pointer;
  border-radius: 1em;
  padding: 0.2em 1em;
  margin: 0.2em 1em;
  color: #999;
  font-weight: 700;

  border: none;
  outline: none;

  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);

  background: #e1e1e1;

  :hover {
    background: #f1f1f1;
    color: #999;
    text-decoration: none;
  }

  :focus {
    outline: none;
    color: #999;
    text-decoration: none;
  }

  :active {
    background: #d1d1d1;
  }

  :disabled {
    color: #e9e9e9;
    background: #f9f9f9;
    box-shadow: none;
    cursor: not-allowed;
    border: solid 1px #e9e9e9;
  }
`;

export default ({ children, ...rest }) => {
  return (
    <Button {...rest}>
      <TranslateScript>{children}</TranslateScript>
    </Button>
  );
};
