import axios from "axios";
import { batch } from "react-redux";

import {
  FETCH_SECTIONS,
  UPDATE_UNIT,
  UPDATE_SECTION,
  FETCH_PAGES,
  LOADING_SECTIONS
} from "./type";

// fetch all the sections
export const fetchSections =
  (options, callback) => async (dispatch, getStore) => {
    dispatch({ type: LOADING_SECTIONS, payload: {} });

    let query = ``;

    if (options.unitId) query = `${query}&unitId=${options.unitId}`;

    if (options.bookId !== undefined)
      query = `${query}&bookId=${options.bookId}`;

    if (options.bookTypeId !== undefined)
      query = `${query}&bookTypeId=${options.bookTypeId}`;

    if (options.hideChildren !== undefined)
      query = `${query}&hideChildren=${options.hideChildren}`;

    const route = `section${query ? `?${query.slice(1)}` : ``}`;

    const {
      data: { sectionMap }
    } = await axios.get(route);

    dispatch({ type: FETCH_SECTIONS, payload: { sectionMap } });

    if (callback) callback();
  };

//add  section
export const saveAddSection =
  (booksetId, bookTypeId, bookId, unitId, title, isHidden, callback) =>
  async (dispatch, getStore) => {
    // add section
    const {
      data: { section }
    } = await axios.post(`section`, {
      booksetId,
      bookTypeId,
      bookId,
      unitId,
      title,
      isHidden
    });

    //update unit._sections
    const {
      data: { unit }
    } = await axios.patch(`unit/${unitId}`, {
      patch: {
        action: "addSection",
        data: {
          sectionId: section._id
        }
      }
    });

    batch(() => {
      dispatch({ type: UPDATE_SECTION, payload: { section } });
      dispatch({ type: UPDATE_UNIT, payload: { unit } });
    });

    if (callback) callback();
  };

//save edit section
export const saveEditSection =
  (sectionId, title, isHidden, callback) => async (dispatch, getStore) => {
    const {
      data: { section }
    } = await axios.patch(`section/${sectionId}`, {
      patch: {
        action: "updateSection",
        data: {
          title: title,
          isHidden: isHidden
        }
      }
    });

    dispatch({ type: UPDATE_SECTION, payload: { section } });

    if (callback) callback();
  };

export const deleteSection =
  (bookId, unitId, sectionId, callback) => async (dispatch, getStore) => {
    // delete section & update unit._sections
    const {
      data: { unit }
    } = await axios.patch(`unit/${unitId}`, {
      patch: {
        action: "deleteSection",
        data: { sectionId: sectionId }
      }
    });

    batch(() => {
      dispatch({ type: UPDATE_UNIT, payload: { unit } });
    });

    if (callback) callback();
  };

// move section
export const moveSection =
  ({ unitId, sectionId }, from, to, callback) =>
  async (dispatch, getStore) => {
    const {
      data: { unit }
    } = await axios.patch(`unit/${unitId}`, {
      patch: {
        action: "moveSection",
        data: { sectionId, from, to }
      }
    });

    // update unit
    dispatch({ type: UPDATE_UNIT, payload: { unit } });

    if (callback) callback();
  };

// import teaching assistant from other section
export const importTeachingAssistant =
  ({ pageIds, sectionId, copy }, callback) =>
  async (dispatch, getStore) => {
    const {
      data: { section }
    } = await axios.patch(`section/${sectionId}`, {
      patch: {
        action: "COPY_TEACHING_ASSISTANT",
        data: { pageIds }
      }
    });

    const {
      data: { pageMap }
    } = await axios.get(`page?sectionId=${sectionId}`);

    batch(() => {
      dispatch({ type: UPDATE_SECTION, payload: { section } });
      dispatch({ type: FETCH_PAGES, payload: { pageMap } });
    });

    if (callback) callback(section.teachingAssistant._pages);
  };

// duplicate teaching assistant
export const duplicateTeachingAssistant =
  ({ pageIds, sectionId }, callback) =>
  async (dispatch, getStore) => {
    const {
      data: { section }
    } = await axios.patch(`section/${sectionId}`, {
      patch: {
        action: "DUPLICATE_TEACHING_ASSISTANT",
        data: { pageIds }
      }
    });

    const {
      data: { pageMap }
    } = await axios.get(`page?sectionId=${sectionId}`);

    batch(() => {
      dispatch({ type: UPDATE_SECTION, payload: { section } });
      dispatch({ type: FETCH_PAGES, payload: { pageMap } });
    });

    if (callback) callback(section.teachingAssistant._pages);
  };
