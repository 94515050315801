import axios from "axios";
import { batch } from "react-redux";

import {
  FETCH_VIDEO_CATEGORYS,
  UPDATE_VIDEO_CATEGORY,
  LOADING_VIDEO_CATEGORYS,
  FETCH_VIDEO_FOLDERS,
  LOADING_VIDEO_FOLDERS,
  UPDATE_VIDEO_FOLDER,
  UPDATE_PAGE,
  FETCH_PAGES,
  FETCH_VIDEOLISTS,
  UPDATE_VIDEOLIST,
  LOADING_VIDEOLISTS,
  UPDATE_STYLEVIEW,
  FETCH_STYLEVIEWS
} from "./type";

import mediaServerAddress from "../config/mediaServerAddress";

/*-----------category start------------*/

// fetch all the category
export const fetchVideoCategorys =
  (options, callback) => async (dispatch, getStore) => {
    dispatch({ type: LOADING_VIDEO_CATEGORYS, payload: {} });

    let query = ``;

    if (options?.isHidden !== undefined)
      query = `${query}&isHidden=${options.isHidden}`;

    if (options?.productIds !== undefined)
      query = `${query}&productIds=${options.productIds}`;
    if (options?.country !== undefined)
      query = `${query}&country=${options.country}`;

    if (options?.route !== undefined) query = `${query}&route=${options.route}`;

    const route = `videos/category${query ? `?${query.slice(1)}` : ``}`;

    const {
      data: { categoryMap }
    } = await axios.get(route);

    dispatch({ type: FETCH_VIDEO_CATEGORYS, payload: { categoryMap } });

    if (callback) callback();
  };

// save add category(create a category)
export const saveVideoCategory =
  (
    formProps,
    { coverSrc, title, isHidden, contentAccess, countriesAccess, userAccess },
    callback
  ) =>
  async (dispatch, getStore) => {
    let imageId = null;

    if (coverSrc) {
      if (formProps[`image_0`]) {
        const fd = new FormData();
        fd.append(`image`, formProps[`image_0`]);

        //upload new image
        const {
          data: { image }
        } = await mediaServerAddress.post(`image`, fd);
        imageId = image._id;
      }
    }

    const {
      data: { category }
    } = await axios.post(`videos/category`, {
      title,
      isHidden,
      imageId,
      contentAccess,
      userAccess,
      countriesAccess
    });

    dispatch({ type: UPDATE_VIDEO_CATEGORY, payload: { category } });

    if (callback) callback();
  };

// save edit category
export const saveVideoEditCategory =
  (
    formProps,
    {
      coverSrc,
      title,
      isHidden,
      contentAccess,
      userAccess,
      countriesAccess,
      categoryId
    },
    { coverImage },
    callback
  ) =>
  async (dispatch, getStore) => {
    let imageId = null;
    const uselessImages = {};

    if (coverImage) {
      uselessImages[coverImage._id] = true;
    }

    if (coverSrc) {
      if (formProps[`image_0`]) {
        const fd = new FormData();
        fd.append(`image`, formProps[`image_0`]);

        //upload new image
        const {
          data: { image }
        } = await mediaServerAddress.post(`image`, fd);
        imageId = image._id;
      } else {
        imageId = coverImage._id;
        delete uselessImages[coverImage._id];
      }
    }

    const {
      data: { category }
    } = await axios.patch(`videos/category/${categoryId}`, {
      patch: {
        action: `changeCover`,
        data: {
          title,
          isHidden,
          imageId,
          contentAccess,
          userAccess,
          countriesAccess
        }
      }
    });

    //clean up old images
    await axios.delete(`image`, {
      data: { imageIds: Object.keys(uselessImages) }
    });

    dispatch({ type: UPDATE_VIDEO_CATEGORY, payload: { category } });

    if (callback) callback();
  };

export const moveVideoCategory =
  (from, to, callback) => async (dispatch, getStore) => {
    const {
      data: { categoryMap }
    } = await axios.patch(`videos/moveCategory`, {
      patch: {
        data: {
          from,
          to
        }
      }
    });

    dispatch({ type: FETCH_VIDEO_CATEGORYS, payload: { categoryMap } });

    if (callback) callback();
  };

// delete category
export const deleteVideoCategory =
  ({ categoryId }, callback) =>
  async (dispatch, getStore) => {
    // delete category
    await axios.delete(`videos/category/${categoryId}`);

    //update categorys
    const {
      data: { categoryMap }
    } = await axios.get(`videos/category`);

    dispatch({ type: FETCH_VIDEO_CATEGORYS, payload: { categoryMap } });

    if (callback) callback();
  };

/*-----------category end------------*/

/*-------------videoType start-------------*/
//fetch all the category's videoTypes
// export const fetchVideoTypes = (options, callback) => async (
//   dispatch,
//   getStore
// ) => {
//
//     // dispatch({ type: LOADING_VIDEO_VIDEOTYPES, payload: {} });
//     let query = ``;
//     if (options.categoryId !== undefined)
//       query = `${query}&categoryId=${options.categoryId}`;

//     // const route = `videos/category/folder${
//     //   query ? `?${query.slice(1)}` : ``
//     // }`;

//     const {
//       data: { videoType },
//     } = await axios.get(route, );

//     // dispatch({ type: FETCH_VIDEO_VIDEOTYPE, payload: { videoType } });

//     if (callback) callback();
// };

// export const saveVideoType = (
//   { categoryId, title, isHidden },
//   callback
// ) => async (dispatch, getStore) => {
//
//     const {
//       data: { category },
//     } = await axios.patch(
//       `videos/category/${categoryId}`,
//       {
//         patch: {
//           action: `addVideoType`,
//           data: {
//             title,
//             isHidden,
//             isHidden,
//           },
//         },
//       },
//
//     );

//     dispatch({ type: UPDATE_VIDEO_CATEGORY, payload: { category } });

//     if (callback) callback();
// };

export const saveVideoType =
  (mode, videoTypeName, categoryId, videoTypeId, callback) =>
  async (dispatch, getStore) => {
    const action = mode === "add" ? "addVideoType" : "updateVideoType";

    const {
      data: { category }
    } = await axios.patch(`videos/category/${categoryId}`, {
      patch: {
        action: action,
        data: {
          title: videoTypeName,
          videoTypeId
        }
      }
    });

    dispatch({ type: UPDATE_VIDEO_CATEGORY, payload: { category } });

    if (callback) callback();
  };

//move videoType

export const moveVideoType =
  ({ categoryId, videoTypeId }, to, callback) =>
  async (dispatch, getStore) => {
    const {
      data: { category }
    } = await axios.patch(`videos/category/${categoryId}`, {
      patch: {
        action: "MOVE_VIDEOTYPE",
        data: { videoTypeId, to }
      }
    });

    dispatch({ type: UPDATE_VIDEO_CATEGORY, payload: { category } });
    if (callback) callback();
  };

//delete videoType
export const deleteVideoType =
  ({ categoryId, videoTypeId, videoTypeIndex }, callback) =>
  async (dispatch, getStore) => {
    const {
      data: { category }
    } = await axios.patch(`videos/category/${categoryId}`, {
      patch: {
        action: `deleteVideoType`,
        data: {
          // folderId,
          categoryId,
          videoTypeId,
          videoTypeIndex
        }
      }
    });

    const {
      data: { folderMap }
    } = await axios.get(`videos/category/folder?categoryId=${categoryId}`);

    batch(() => {
      dispatch({
        type: UPDATE_VIDEO_CATEGORY,
        payload: { category }
      });
      dispatch({ type: FETCH_VIDEO_FOLDERS, payload: { folderMap } });
    });
    if (callback) callback();
  };

/*-------------videoType end---------------*/

/*-----------folder start------------*/
// fetch all the category's Folders

export const fetchVideoFolders =
  (options, callback) => async (dispatch, getStore) => {
    dispatch({ type: LOADING_VIDEO_FOLDERS, payload: {} });

    let query = ``;
    if (options.categoryId !== undefined)
      query = `${query}&categoryId=${options.categoryId}`;

    const route = `videos/category/folder${query ? `?${query.slice(1)}` : ``}`;

    const {
      data: { folderMap }
    } = await axios.get(route);

    dispatch({ type: FETCH_VIDEO_FOLDERS, payload: { folderMap } });

    if (callback) callback();
  };

// save add folder(create a folder)
export const saveVideoFolder =
  (
    formProps,
    { categoryId, coverSrc, title, isHidden, userAccess, videoTypeId },
    callback
  ) =>
  async (dispatch, getStore) => {
    let imageId = null;

    if (coverSrc) {
      if (formProps[`image_0`]) {
        const fd = new FormData();
        fd.append(`image`, formProps[`image_0`]);

        //upload new image
        const {
          data: { image }
        } = await mediaServerAddress.post(`image`, fd);
        imageId = image._id;
      }
    }

    const {
      data: { folder }
    } = await axios.post(`videos/category/folder`, {
      categoryId,
      title,
      isHidden,
      imageId,
      userAccess,
      videoTypeId
    });

    dispatch({ type: UPDATE_VIDEO_FOLDER, payload: { folder } });

    // update folder's order

    const {
      data: { category }
    } = await axios.patch(`videos/category/${categoryId}`, {
      patch: {
        action: "addFolder",
        data: {
          videoTypeId: videoTypeId,
          folderId: folder._id
        }
      }
    });

    dispatch({ type: UPDATE_VIDEO_CATEGORY, payload: { category } });

    if (callback) callback();
  };

// save edit folder
export const saveVideoEditFolder =
  (
    formProps,
    { coverSrc, title, isHidden, folderId, userAccess },
    { coverImage },
    callback
  ) =>
  async (dispatch, getStore) => {
    let imageId = null;
    const uselessImages = {};
    if (coverImage) {
      uselessImages[coverImage._id] = true;
    }
    if (coverSrc) {
      if (formProps[`image_0`]) {
        const fd = new FormData();
        fd.append(`image`, formProps[`image_0`]);

        //upload new image
        const {
          data: { image }
        } = await mediaServerAddress.post(`image`, fd);
        imageId = image._id;
      } else {
        imageId = coverImage._id;
        delete uselessImages[coverImage._id];
      }
    }

    const {
      data: { folder }
    } = await axios.patch(`videos/category/folder/${folderId}`, {
      patch: {
        action: `changeCover`,
        data: {
          title,
          isHidden,
          imageId,
          userAccess
        }
      }
    });
    //clean up old images
    await axios.delete(`image`, {
      data: { imageIds: Object.keys(uselessImages) }
    });

    dispatch({ type: UPDATE_VIDEO_FOLDER, payload: { folder } });

    if (callback) callback();
  };

export const moveVideoFolder =
  ({ categoryId, videoTypeId, folderId }, from, to, callback) =>
  async (dispatch, getStore) => {
    const {
      data: { category }
    } = await axios.patch(`videos/category/${categoryId}`, {
      patch: {
        action: `moveFolder`,
        data: {
          videoTypeId,
          folderId,
          from,
          to
        }
      }
    });

    dispatch({ type: UPDATE_VIDEO_CATEGORY, payload: { category } });

    if (callback) callback();
  };

// delete folder
export const deleteVideoFolder =
  ({ categoryId, folderId, videoTypeId }, callback) =>
  async (dispatch, getStore) => {
    const {
      data: { category }
    } = await axios.patch(`videos/category/${categoryId}`, {
      patch: {
        action: `deleteFolder`,
        data: {
          folderId,
          videoTypeId
        }
      }
    });

    const {
      data: { folderMap }
    } = await axios.get(`videos/category/folder?categoryId=${categoryId}`);

    batch(() => {
      dispatch({
        type: UPDATE_VIDEO_CATEGORY,
        payload: { category }
      });
      dispatch({ type: FETCH_VIDEO_FOLDERS, payload: { folderMap } });
    });
    if (callback) callback();
  };

/*-----------folder end------------*/

/*-----------videolist start------------*/

//fetch all the videoList(category -> folder -> videoList)
export const fetchVideoLists =
  (options, callback) => async (dispatch, getStore) => {
    dispatch({
      type: LOADING_VIDEOLISTS,
      payload: {}
    });

    let query = ``;
    if (options.folderId !== undefined) {
      query = `${query}&folderId=${options.folderId}`;
    }

    const route = `videos/category/folder/videolist${
      query ? `?${query.slice(1)}` : ``
    }`;

    const {
      data: { videoListMap }
    } = await axios.get(route);

    dispatch({ type: FETCH_VIDEOLISTS, payload: { videoListMap } });

    if (callback) callback();
  };

// add Videos video list
export const addVideoList =
  ({ title, categoryId, videoTypeId, folderId }, callback) =>
  async (dispatch, getStore) => {
    const {
      data: { videoList }
    } = await axios.post(
      `videos/category/folder/videolist`,

      {
        title,
        categoryId,
        videoTypeId,
        folderId
      }
    );

    dispatch({ type: UPDATE_VIDEOLIST, payload: { videoList } });

    const {
      data: { folder }
    } = await axios.patch(`videos/category/folder/${folderId}`, {
      patch: {
        action: "addVideoList",
        data: {
          videoTypeId,
          _videoList: videoList._id
        }
      }
    });

    dispatch({ type: UPDATE_VIDEO_FOLDER, payload: { folder } });

    if (callback) callback();
  };

// edit videos video list title
export const editVideoList =
  ({ title, videoListId }, callback) =>
  async (dispatch, getStore) => {
    const {
      data: { videoList }
    } = await axios.patch(`videos/category/folder/videolist/${videoListId}`, {
      patch: {
        action: "CHANGE_TITLE",
        data: {
          title
        }
      }
    });

    dispatch({ type: UPDATE_VIDEOLIST, payload: { videoList } });
    if (callback) callback();
  };

export const changeVideoListLocation =
  (categoryId, { folderId, from, to }, callback) =>
  async (dispatch, getStore) => {
    const {
      data: { folder }
    } = await axios.patch(`videos/category/folder/${folderId}`, {
      patch: {
        action: `moveVideoList`,
        data: {
          folderId,
          from,
          to
        }
      }
    });

    dispatch({ type: UPDATE_VIDEO_FOLDER, payload: { folder } });

    if (callback) callback();
  };

// delete videos video list
export const deleteVideoList =
  ({ videoListId, folderId }, callback) =>
  async (dispatch, getStore) => {
    const {
      data: { folder }
    } = await axios.patch(`videos/category/folder/${folderId}`, {
      patch: {
        action: "deleteVideoList",
        data: { videoListId }
      }
    });

    //fetch videoList
    const {
      data: { videoListMap }
    } = await axios.get(
      `videos/category/folder/videolist?folderId=${folderId}`
    );

    batch(() => {
      dispatch({ type: UPDATE_VIDEO_FOLDER, payload: { folder } });
      dispatch({ type: FETCH_VIDEOLISTS, payload: { videoListMap } });
      // dispatch({ type: FETCH_PAGES, payload: { pageMap } });
    });

    if (callback) callback();
  };

/*-----------videolist end------------*/

/*-------------------------videosPage start-----------------*/

export const createVideosPage =
  (formProps, callback) => async (dispatch, getStore) => {
    const {
      data: { page }
    } = await axios.post(`videos/category/folder/videolist/page`, formProps);

    const {
      data: { videoList }
    } = await axios.patch(
      `videos/category/folder/videolist/${formProps.videoListId}`,
      {
        patch: {
          action: `addVideosPage`,
          data: {
            categoryId: formProps.categoryId,
            videoTypeId: formProps.videoTypeId,
            folderId: formProps.folderId,
            videosPageId: page._id
          }
        }
      }
    );

    batch(() => {
      dispatch({ type: UPDATE_PAGE, payload: { page } });

      dispatch({
        type: UPDATE_VIDEOLIST,
        payload: { videoList }
      });
    });

    if (callback) callback(page);
  };

// delete videosPage
export const deleteVideosPage =
  ({ videoListId, videosPageId }, callback) =>
  async (dispatch, getStore) => {
    const {
      data: { videoList }
    } = await axios.patch(`videos/category/folder/videolist/${videoListId}`, {
      patch: {
        action: `deleteVideosPage`,
        data: {
          videosPageId
        }
      }
    });

    dispatch({ type: UPDATE_VIDEOLIST, payload: { videoList } });

    if (callback) callback();
  };

//move videoPage position
export const moveVideoPage =
  ({ videoListId }, from, to, callback) =>
  async (dispatch, getStore) => {
    const {
      data: { videoList }
    } = await axios.patch(`videos/category/folder/videolist/${videoListId}`, {
      patch: {
        action: `MOVE_VIDEO_PAGE`,
        data: {
          from,
          to
        }
      }
    });

    dispatch({ type: UPDATE_VIDEOLIST, payload: { videoList } });

    if (callback) callback();
  };

/*-------------------------videosPage end-------------------*/

/*----------------import folder form other category start---------------*/
export const importVideoFolders =
  ({ categoryId, targetVideoTypeId, folderIds }, callback) =>
  async (dispatch, getStore) => {
    const {
      data: { category }
    } = await axios.patch(`videos/category/${categoryId}`, {
      patch: {
        action: "COPY_VIDEO_FOLDERS",
        data: { folderIds, categoryId, targetVideoTypeId }
      }
    });

    const {
      data: { folderMap }
    } = await axios.get(`videos/category/folder?categoryId=${categoryId}`);

    batch(() => {
      dispatch({ type: UPDATE_VIDEO_CATEGORY, payload: { category } });
      dispatch({ type: FETCH_VIDEO_FOLDERS, payload: { folderMap } });
    });

    if (callback) callback();
  };

/*----------------import folder form other category end---------------*/

/*-------------------import videolist from other folder start---------------*/
export const importVideoLists =
  ({ videoListIds, folderId, categoryId }, callback) =>
  async (dispatch, getStore) => {
    const {
      data: { folder }
    } = await axios.patch(`videos/category/folder/${folderId}`, {
      patch: {
        action: "COPY_VIDEOLIST",
        data: { videoListIds, folderId, categoryId }
      }
    });

    const {
      data: { videoListMap }
    } = await axios.get(
      `videos/category/folder/videolist?folderId=${folderId}`
    );

    const {
      data: { pageMap }
    } = await axios.get(`page?videoFolderId=${folderId}`);

    batch(() => {
      dispatch({ type: UPDATE_VIDEO_FOLDER, payload: { folder } });
      dispatch({ type: FETCH_VIDEOLISTS, payload: { videoListMap } });
      dispatch({ type: FETCH_PAGES, payload: { pageMap } });
    });

    if (callback) callback();
  };
/*-------------------import videolist from other folder end---------------*/

/*--------------import videoPages from other videoList start---------------*/
// import pages from other folder
export const importVideoPages =
  (
    {
      categoryId,
      videoTypeId,
      folderId,
      videoListId,
      currentFolderId,
      currentVideoListId,
      pageIds
    },
    callback
  ) =>
  async (dispatch, getStore) => {
    const {
      data: { videoList }
    } = await axios.patch(
      `videos/category/folder/videolist/${currentVideoListId}`,
      {
        patch: {
          action: "COPY_VIDEO_PAGES",
          data: { pageIds }
        }
      }
    );

    const {
      data: { videoListMap }
    } = await axios.get(
      `videos/category/folder/videolist?folderId=${currentFolderId}`
    );

    const {
      data: { pageMap }
    } = await axios.get(`page?videoFolderId=${currentFolderId}`);

    batch(() => {
      dispatch({ type: UPDATE_VIDEOLIST, payload: { videoList } });
      dispatch({ type: FETCH_VIDEOLISTS, payload: { videoListMap } });
      dispatch({ type: FETCH_PAGES, payload: { pageMap } });
    });

    if (callback) callback();
  };
/*--------------import videoPages from other videoList end---------------*/

/*--------------move the folder to other category start--------------*/
export const moveVideoFolders =
  (
    { categoryId, toCategoryId, toVideoTypeId, fromVideoTypeId, folderIds },
    callback
  ) =>
  async (dispatch, getStore) => {
    const {
      data: { categoryMap }
    } = await axios.post(`moveVideoFolders`, {
      fromCategoryId: categoryId,
      toCategoryId,
      toVideoTypeId,
      fromVideoTypeId,
      folderIds
    });

    const {
      data: { folderMap }
    } = await axios.get(`videos/category/folder?categoryId=${categoryId}`);

    batch(() => {
      dispatch({ type: FETCH_VIDEO_CATEGORYS, payload: { categoryMap } });
      dispatch({ type: FETCH_VIDEO_FOLDERS, payload: { folderMap } });
    });

    if (callback) callback();
  };
/*--------------move the folder to other category end--------------*/

/*------move videoLists to other folder start------*/
// move the videoLists
export const moveVideoLists =
  (
    { folderId, toCategoryId, toVideoTypeId, toFolderId, fromVideoListId },
    callback
  ) =>
  async (dispatch, getStore) => {
    const {
      data: { folderMap }
    } = await axios.post(`moveVideoLists`, {
      toCategoryId,
      toVideoTypeId,
      toFolderId,
      fromFolderId: folderId,
      fromVideoListId
    });

    const {
      data: { videoListMap }
    } = await axios.get(
      `videos/category/folder/videolist?folderId=${folderId}`
    );

    batch(() => {
      dispatch({ type: FETCH_VIDEO_FOLDERS, payload: { folderMap } });
      dispatch({ type: FETCH_VIDEOLISTS, payload: { videoListMap } });
    });
    if (callback) {
      callback();
    }
  };

/*------move videoLists to other folder end------*/

/*---------move videopage to other videolist start----------*/

// move the video page
export const moveVideoPages =
  (
    {
      fromCategoryId,
      fromVideoTypeId,
      fromFolderId,
      toCategoryId,
      toVideoTypeId,
      toFolderId,
      toVideoListId,
      pageIds
    },
    callback
  ) =>
  async (dispatch, getStore) => {
    const {
      data: { videoListMap }
    } = await axios.post(`moveVideoPages`, {
      fromCategoryId,
      fromVideoTypeId,
      fromFolderId,
      toCategoryId,
      toVideoTypeId,
      toFolderId,
      toVideoListId,
      pageIds
    });

    const {
      data: { pageMap }
    } = await axios.get(`page?videoFolderId=${fromFolderId}`);

    batch(() => {
      dispatch({ type: FETCH_PAGES, payload: { pageMap } });
      dispatch({ type: FETCH_VIDEOLISTS, payload: { videoListMap } });
    });

    if (callback) callback();
  };

/*---------move videopage to other videolist end----------*/

/*---------video view start----------*/

//update(create) video view
export const createVideoView =
  (formProps, callback) => async (dispatch, getStore) => {
    const {
      data: { styleview }
    } = await axios.patch(`video/views`, formProps);

    dispatch({ type: UPDATE_STYLEVIEW, payload: { styleview } });

    if (callback) callback();
  };

//fetch user's styleviews
export const fetchStyleViews =
  (formProps, callback) => async (dispatch, getStore) => {
    const {
      data: { styleviewMap }
    } = await axios.post("video/views", formProps);

    dispatch({ type: FETCH_STYLEVIEWS, payload: { styleviewMap } });

    if (callback) callback();
  };

/*---------video view end----------*/
