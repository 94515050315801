import {
  FETCH_RESOURCES,
  UPDATE_RESOURCE,
  CLEAN_AUTH,
  LOADING_RESOURCES
} from "../actions/type";

const INIT_STATE = { resourceMap: {}, isLoadingResources: false };

export default (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case FETCH_RESOURCES:
      return {
        ...state,
        resourceMap: { ...payload.resourceMap },
        isLoadingResources: false
      };

    case LOADING_RESOURCES:
      return { ...state, isLoadingResources: true };

    case UPDATE_RESOURCE:
      return {
        ...state,
        resourceMap: {
          ...state.resourceMap,
          [payload.resource._id]: payload.resource
        }
      };

    case CLEAN_AUTH:
      return { ...INIT_STATE };

    default:
      return state;
  }
};
