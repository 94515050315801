import { SET_WARNING, CLEAN_AUTH, CLOSE_WARNING } from "../actions/type";

const INIT_STATE = {
  alertMessage: "",
  show: false,
  confirm: null,
  cancel: false
};

export default (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case SET_WARNING:
      return {
        alertMessage: payload.alert,
        confirm: payload.confirm,
        show: true,
        cancel: payload.cancel,
        width: payload.width,
        height: payload.height
      };

    case CLOSE_WARNING:
      return { alertMessage: "", show: false, confirm: null, cancel: false };

    case CLEAN_AUTH:
      return { ...INIT_STATE };

    default:
      return state;
  }
};
