import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

import ACCESS_LEVELS from "../config/accessLevels";

// actions
import { useScriptConverter } from "../utils/languageHelpers";

const Authorization = ({ component: AuthComponent, level }, ...props) => {
  const isLogin = useSelector((state) => state.auth.isLogin);
  const roles = useSelector((state) => state.auth.user?.roles);
  const canEdit = useSelector((state) => state.auth.user?.canEdit); // NOTE: ID of canEdit array is product ID!
  const converter = useScriptConverter();
  const check = ({ isLogin, roles }) => {
    if (!isLogin) return false;

    if (
      (level !== undefined &&
        roles.some((role) => ACCESS_LEVELS[level].indexOf(role) !== -1)) ||
      (Array.isArray(canEdit) && canEdit.length > 0)
    ) {
      return true;
    } else {
      alert(
        converter({
          en: "Identity error! \nPlease check invitation code with your principal or Melody consultants,Or contact the Academic Affairs Office and engineers.",
          tw: "身分認證錯誤! \n請洽詢您的校長索取登入邀請碼,或是聯繫教務及工程師",
          cn: "身分认证错误! \n请洽询您的校长索取登入邀请码,或是联繫教务及工程师"
        })
      );
      return false;
    }
  };

  return check({ isLogin, roles }) ? (
    <AuthComponent {...props} />
  ) : (
    <Navigate to="/" replace />
  );
};
export default Authorization;
