exports.roles = {
  MELODY: "melody",
  SUPER: "super",
  ADMIN: "admin",
  DEVELOPER: "developer",
  EDITOR: "editor",
  SALES_REP: "sales_rep",
  CUSTOMER_SERVICE_REP: "customer_service_rep",
  EDUCATIONAL_CONS: "educational_cons",

  // customers
  PRINCIPAL: "principal",
  TEACHER: "teacher",
  STUDENT: "student",
  SECRETARY: "secretary"
};
