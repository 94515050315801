import { useState, useEffect, useRef, useReducer } from "react";
import { mergeWith, isArray, omit, isNil } from "lodash-es";
import { NetworkStatus } from "@apollo/client";

export const paginationBase = { offset: 0, order: "desc", sortBy: null };

export const mergeInputs = (...args) =>
  mergeWith({}, ...args, (objValue, srcValue) =>
    isArray(objValue) ? objValue.concat(srcValue) : undefined
  );

const fetchReducer = (state, action) => {
  switch (action.type) {
    case "changeOffset":
      return {
        ...state,
        pagination: { ...state.pagination, offset: action.payload }
      };
    case "changeLimit":
      return {
        ...state,
        pagination: {
          ...state.pagination,
          offset: 0,
          limit: action.payload
        }
      };
    case "changeSortBy":
      return {
        ...state,
        pagination: {
          ...state.pagination,
          offset: 0,
          sortBy: action.payload,
          order:
            state.pagination.sortBy !== action.payload ||
            state.pagination.order === "asc"
              ? "desc"
              : "asc"
        }
      };
    case "toggleOrder":
      return {
        ...state,
        pagination: {
          ...state.pagination,
          offset: 0,
          order: state.pagination.order === "asc" ? "desc" : "asc"
        }
      };
    case "changeFields":
      return {
        filter: {
          ...omit(state.filter, action.payload.remove),
          ...action.payload.set
        },
        pagination: { ...state.pagination, ...paginationBase }
      };
    case "reset":
      return {
        filter: action.payload,
        pagination: { ...state.pagination, ...paginationBase }
      };
    default:
      return state;
  }
};

const buildFetchStates = (
  state,
  dispatch,
  { defaultFilter, parseVariables }
) => ({
  ...state,
  variables: parseVariables(state),
  changePage: async (page, { fetchMore } = {}) => {
    if (page >= 0) {
      typeof fetchMore === "function" &&
        (await fetchMore({
          variables: parseVariables({
            ...state,
            pagination: {
              ...state.pagination,
              offset: page * state.pagination.limit
            }
          })
        }));
      dispatch({
        type: "changeOffset",
        payload: page * state.pagination.limit
      });
    }
  },
  changeRowsPerPage: async (limit, { fetchMore } = {}) => {
    if (limit >= 1) {
      typeof fetchMore === "function" &&
        (await fetchMore({
          variables: parseVariables({
            ...state,
            pagination: { ...state.pagination, offset: 0, limit }
          })
        }));
      dispatch({ type: "changeLimit", payload: limit });
    }
  },
  changeSortBy: async (sortBy, { fetchMore } = {}) => {
    dispatch({ type: "changeSortBy", payload: sortBy });
    typeof fetchMore === "function" &&
      (await fetchMore({
        variables: parseVariables({
          ...state,
          pagination: {
            ...state.pagination,
            offset: 0,
            sortBy,
            order:
              state.pagination.sortBy !== sortBy ||
              state.pagination.order === "asc"
                ? "desc"
                : "asc"
          }
        })
      }));
  },
  toggleOrder: async ({ fetchMore } = {}) => {
    dispatch({ type: "toggleOrder" });
    typeof fetchMore === "function" &&
      (await fetchMore({
        variables: parseVariables({
          ...state,
          pagination: {
            ...state.pagination,
            offset: 0,
            order: state.pagination.order === "asc" ? "desc" : "asc"
          }
        })
      }));
  },
  changeField: async (field, value, { fetchMore } = {}) => {
    const set = {};
    const remove = [];
    isNil(value) ? remove.push(field) : (set[field] = value);
    dispatch({ type: "changeFields", payload: { set, remove } });
    typeof fetchMore === "function" &&
      (await fetchMore({
        variables: parseVariables({
          filter: { ...omit(state.filter, remove), ...set },
          pagination: { ...state.pagination, ...paginationBase }
        })
      }));
  },
  changeFields: async (fieldValueMap, { fetchMore } = {}) => {
    const set = {};
    const remove = [];
    for (const field in fieldValueMap)
      isNil(fieldValueMap[field])
        ? remove.push(field)
        : (set[field] = fieldValueMap[field]);
    dispatch({ type: "changeFields", payload: { set, remove } });
    typeof fetchMore === "function" &&
      (await fetchMore({
        variables: parseVariables({
          filter: { ...omit(state.filter, remove), ...set },
          pagination: { ...state.pagination, ...paginationBase }
        })
      }));
  },
  removeField: async (field, { fetchMore } = {}) => {
    const set = {};
    const remove = [field];
    dispatch({ type: "changeFields", payload: { set, remove } });
    typeof fetchMore === "function" &&
      (await fetchMore({
        variables: parseVariables({
          filter: { ...omit(state.filter, remove), ...set },
          pagination: { ...state.pagination, ...paginationBase }
        })
      }));
  },
  reset: async ({ fetchMore } = {}) => {
    dispatch({ type: "reset", payload: defaultFilter });
    typeof fetchMore === "function" &&
      (await fetchMore({
        variables: parseVariables({
          filter: defaultFilter,
          pagination: { ...state.pagination, ...paginationBase }
        })
      }));
  }
});

export const useFetchStates = ({
  defaultRowsPerPage = 10,
  defaultFilter = {},
  parseVariables = (state) => state
} = {}) => {
  const options = useRef({ defaultFilter });
  options.current.parseVariables = parseVariables;

  const [state, dispatch] = useReducer(fetchReducer, {
    filter: defaultFilter,
    pagination: { ...paginationBase, limit: defaultRowsPerPage }
  });

  const [value, setValue] = useState(() =>
    buildFetchStates(state, dispatch, options.current)
  );

  useEffect(() => {
    setValue(buildFetchStates(state, dispatch, options.current));
  }, [state]);

  return value;
};

export const paginationCacheHandler = ({
  keyArgs = ["filter", "pagination", ["sortBy", "order"]],
  repagination = true
} = {}) => ({
  keyArgs,
  merge(
    existing,
    incoming,
    { args: { pagination: { offset = 0 } = {} } = {} }
  ) {
    if (!incoming) return existing;
    const merged = existing ? [...existing] : [];
    for (let i = 0; i < incoming.length; ++i) merged[offset + i] = incoming[i];
    return merged;
  },
  ...(repagination
    ? {
        read(
          existing,
          { args: { pagination: { offset = 0, limit = 0 } = {} } = {} }
        ) {
          return (
            existing &&
            (limit === 0
              ? existing.slice(offset)
              : existing.slice(offset, offset + limit))
          );
        }
      }
    : {})
});

export const replaceWithIncomingCacheHandler = () => ({
  merge(_, incoming) {
    return incoming;
  }
});

export const isReloading = (networkStatus) =>
  networkStatus === NetworkStatus.setVariables ||
  networkStatus === NetworkStatus.fetchMore ||
  networkStatus === NetworkStatus.refetch;
