import { GraphQLClient } from "graphql-request";
import { MYBESTUSER_API } from "../config/environment";

let graphQLClient = null;

const create = () => {
  const token = localStorage.getItem("token");
  return new GraphQLClient(MYBESTUSER_API + "/graphql", {
    headers: {
      authorization: token ? `Bearer ${token}` : null
    }
  });
};

export const initGQLClient = () => {
  if (!graphQLClient) {
    graphQLClient = create();
  }

  return graphQLClient;
};
