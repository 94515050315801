import axios from "axios";
import { batch } from "react-redux";
import {
  FETCH_BOOKS,
  UPDATE_BOOK,
  UPDATE_BOOKSET,
  LOADING_BOOKS,
  FETCH_UNITS,
  FETCH_SECTIONS
} from "./type";

import mediaServerAddress from "../config/mediaServerAddress";

/**
 * BOOK
 */

// fetch all the books
export const fetchBooks = (options, callback) => async (dispatch, getStore) => {
  dispatch({ type: LOADING_BOOKS, payload: {} });

  let query = ``;

  if (options.booksetId !== undefined)
    query = `${query}&booksetId=${options.booksetId}`;
  if (options.bookTypeId !== undefined)
    query = `${query}&bookTypeId=${options.bookTypeId}`;
  if (options.isHidden !== undefined)
    query = `${query}&isHidden=${options.isHidden}`;

  const route = `book${query ? `?${query.slice(1)}` : ``}`;

  const {
    data: { bookMap }
  } = await axios.get(route);

  dispatch({ type: FETCH_BOOKS, payload: { bookMap } });

  if (callback) callback();
};

// save edit book
export const saveEditBook =
  (formProps, title, bookId, coverImage, src, isHidden, level, callback) =>
  async (dispatch, getStore) => {
    const uselessImages = {};
    let _image = coverImage;

    if (coverImage) uselessImages[coverImage._id] = true;

    if (src) {
      if (formProps[`image_0`]) {
        const fd = new FormData();

        fd.append(`image`, formProps[`image_0`]);

        // upload new image
        const {
          data: { image }
        } = await mediaServerAddress.post(`image`, fd);

        _image = image;
      } else {
        delete uselessImages[coverImage._id];
      }
    }

    const {
      data: { book }
    } = await axios.patch(`book/${bookId}`, {
      patch: {
        action: `changeCover`,
        data: {
          title: title,
          imageId: src && _image ? _image._id : null,
          isHidden: isHidden,
          level: level
        }
      }
    });

    // clean up old images
    await axios.delete(`image`, {
      data: { imageIds: Object.keys(uselessImages) }
    });

    dispatch({ type: UPDATE_BOOK, payload: { book } });

    if (callback) callback();
  };

//save add book
export const saveAddBook =
  ({ title, booksetId, booktypeId, isHidden, level, ...formProps }, callback) =>
  async (dispatch, getStore) => {
    let imageId = null;

    if (formProps[`image_0`]) {
      const fd = new FormData();

      fd.append(`image`, formProps[`image_0`]);

      // upload new image
      const {
        data: { image }
      } = await mediaServerAddress.post(`image`, fd);

      imageId = image._id;
    }

    const {
      data: { book }
    } = await axios.post(`book`, {
      title: title,
      booksetId,
      booktypeId,
      isHidden,
      imageId,
      level: parseInt(level, 10)
    });
    dispatch({ type: UPDATE_BOOK, payload: { book } });

    //update bookset.bookTypes._book
    const {
      data: { bookset }
    } = await axios.patch(`bookset/${booksetId}`, {
      patch: {
        action: "addBook",
        data: {
          booktypeId,
          bookId: book._id
        }
      }
    });
    dispatch({ type: UPDATE_BOOKSET, payload: { bookset } });

    if (callback) callback();
  };

export const deleteBook =
  (_booksetId, _booktypeId, _bookId, callback) =>
  async (dispatch, getStore) => {
    // delete book & update bookset.bookTypes._book
    const {
      data: { bookset }
    } = await axios.patch(`bookset/${_booksetId}`, {
      patch: {
        action: "deleteBook",
        data: {
          booktypeId: _booktypeId,
          bookId: _bookId
        }
      }
    });

    dispatch({ type: UPDATE_BOOKSET, payload: { bookset } });

    if (callback) callback();
  };

// move book
export const moveBook =
  ({ booksetId, booktypeId, bookId }, from, to, callback) =>
  async (dispatch, getStore) => {
    const {
      data: { bookset }
    } = await axios.patch(`bookset/${booksetId}`, {
      patch: {
        action: "moveBook",
        data: { booktypeId, bookId, from, to }
      }
    });

    dispatch({ type: UPDATE_BOOKSET, payload: { bookset } });

    if (callback) callback();
  };

// duplicate unit
export const duplicateUnits =
  ({ unitIds, bookId }, callback, mode) =>
  async (dispatch, getStore) => {
    const {
      data: { book }
    } = await axios.patch(`book/${bookId}`, {
      patch: {
        action: "duplicate_units",
        data: { unitIds, mode: mode || "" }
      }
    });

    const {
      data: { unitMap }
    } = await axios.get(`unit?bookId=${bookId}`);

    const {
      data: { sectionMap }
    } = await axios.get(`section?bookId=${bookId}`);

    batch(() => {
      dispatch({ type: UPDATE_BOOK, payload: { book } });
      dispatch({ type: FETCH_UNITS, payload: { unitMap } });
      dispatch({ type: FETCH_SECTIONS, payload: { sectionMap } });
    });

    if (callback) callback();
  };
