import axios from "axios";

import { LOADING_LOGS, FETCH_LOGS } from "./type";

// fetch all the logs
export const fetchLogs = (options, callback) => async (dispatch, getStore) => {
  dispatch({
    type: LOADING_LOGS,
    payload: {}
  });

  let query = ``;

  if (options.bookId !== undefined) query = `${query}&bookId=${options.bookId}`;

  if (options.event !== undefined) query = `${query}&event=${options.event}`;

  const route = `view${query ? `?${query.slice(1)}` : ``}`;

  const {
    data: { logMap }
  } = await axios.get(route);

  dispatch({ type: FETCH_LOGS, payload: { logMap } });

  if (callback) callback();
};
