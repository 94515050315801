import { AUTH_USER, CLEAN_AUTH, AUTH_ERROR } from "../actions/type";

const INIT_STATE = { user: null, errorMessage: "", isLogin: false };

export default (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case AUTH_USER:
      return {
        ...state,
        user: payload.user,
        isLogin: true,
        errorMessage: ""
      };
    case CLEAN_AUTH:
      return { ...INIT_STATE };
    case AUTH_ERROR:
      return { ...state, errorMessage: payload };
    default:
      return state;
  }
};
