import axios from "axios";
import { batch } from "react-redux";

import {
  FETCH_RESOURCES,
  UPDATE_RESOURCE,
  SET_ERROR,
  UPDATE_BOOKSET,
  LOADING_RESOURCES
} from "./type";

// fetch all the resources
export const fetchResources =
  (options, callback) => async (dispatch, getStore) => {
    dispatch({ type: LOADING_RESOURCES, payload: {} });

    let query = ``;

    if (options.booksetId !== undefined)
      query = `${query}&booksetId=${options.booksetId}`;
    if (options.resourceId !== undefined)
      query = `${query}&resourceId=${options.resourceId}`;

    const route = `resource${query ? `?${query.slice(1)}` : ``}`;

    const {
      data: { resourceMap }
    } = await axios.get(route);

    dispatch({ type: FETCH_RESOURCES, payload: { resourceMap } });

    if (callback) callback();
  };

// create one resource
export const createResource =
  (formProps, callback) => async (dispatch, getStore) => {
    if (formProps.booksetId === undefined)
      return dispatch({
        type: SET_ERROR,
        payload: `Bookset Id is Required`
      });

    const {
      data: { resource }
    } = await axios.post(`resource`, formProps);

    let res;

    if (formProps.resourceId === undefined) {
      res = await axios.patch(`bookset/${formProps.booksetId}`, {
        patch: {
          action: `ADD_RESOURCE`,
          data: { resourceId: resource._id }
        }
      });

      batch(() => {
        dispatch({
          type: UPDATE_BOOKSET,
          payload: { bookset: res.data?.bookset }
        });

        dispatch({ type: UPDATE_RESOURCE, payload: { resource } });
      });
    } else {
      res = await axios.patch(`resource/${formProps.resourceId}`, {
        patch: {
          action: `ADD_RESOURCE`,
          data: { resourceId: resource._id }
        }
      });

      batch(() => {
        dispatch({
          type: UPDATE_RESOURCE,
          payload: { resource: res.data?.resource }
        });

        dispatch({ type: UPDATE_RESOURCE, payload: { resource } });
      });
    }

    if (callback) callback(resource);
  };

export const renameResource =
  ({ resourceId, title }, callback) =>
  async (dispatch, getStore) => {
    if (resourceId === undefined)
      return dispatch({
        type: SET_ERROR,
        payload: `Resource Id is Required`
      });

    const {
      data: { resource }
    } = await axios.patch(`resource/${resourceId}`, {
      patch: {
        action: `RENAME`,
        data: {
          title
        }
      }
    });

    dispatch({
      type: UPDATE_RESOURCE,
      payload: { resource }
    });

    if (callback) callback(resource);
  };

export const deleteResource =
  ({ resourceId, booksetId }, callback) =>
  async (dispatch, getStore) => {
    const {
      data: { bookset }
    } = await axios.patch(`bookset/${booksetId}`, {
      patch: {
        action: `DELETE_RESOURCE`,
        data: {
          resourceId
        }
      }
    });

    dispatch({
      type: UPDATE_BOOKSET,
      payload: { bookset }
    });

    if (callback) callback();
  };

export const deleteFolder =
  ({ resourceId, parentId }, callback) =>
  async (dispatch, getStore) => {
    const {
      data: { resource }
    } = await axios.patch(`resource/${parentId}`, {
      patch: {
        action: `DELETE_RESOURCE`,
        data: {
          resourceId
        }
      }
    });

    dispatch({
      type: UPDATE_RESOURCE,
      payload: { resource }
    });

    if (callback) callback();
  };
