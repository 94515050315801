import { CLEAN_AUTH, ADD_NOTICE, DELETE_NOTICE } from "../actions/type";

const INIT_STATE = { notices: [] };

export default (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case ADD_NOTICE:
      return {
        ...state,
        notices: [
          ...state.notices,
          {
            id: payload.id,
            noticeType: payload.noticeType,
            value: payload.value,
            timeout: payload.timeout
          }
        ]
      };

    case DELETE_NOTICE:
      return {
        ...state,
        notices: state.notices.filter((notice) => notice.id !== payload)
      };

    case CLEAN_AUTH:
      return { ...INIT_STATE };

    default:
      return state;
  }
};
