import { ApolloClient, from, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { createUploadLink } from "apollo-upload-client";

import { SERVER } from "../config/environment";
import { paginationCacheHandler } from "../utils/fetchHelpers";
import { rollbar } from "../loggers";

let apolloClient = null;

// write access token to headers
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("token");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ""
    }
  };
});

// connection to graphql server
const uploadLink = createUploadLink({
  uri: SERVER.replace("/api/", "/graphql")
});

const errorLink = onError(({ graphQLErrors }) => {
  let errMessage = "";
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      const format = (locations) => {
        let x = "";
        locations.forEach(
          ({ line, column }) => (x = x + `(line: ${line}, column: ${column}) `)
        );
        return x;
      };
      errMessage = errMessage.concat(
        `[GraphQL error]: Message: ${message}, Location: ${format(
          locations
        )} Path: ${path}
            `
      );
    });

  if (!errMessage.includes("non-nullable field User.id"))
    rollbar.error(errMessage); // TODO: fix "Cannot return null for non-nullable field User.id." first
});

function create() {
  // Check out https://github.com/zeit/next.js/pull/4611 if you want to use the AWSAppSyncClient
  return new ApolloClient({
    link: from([errorLink, authLink, uploadLink]),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            getSchools: paginationCacheHandler()
          }
        },
        School: {
          fields: {
            teachers: paginationCacheHandler()
          }
        }
      }
    }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-first"
      }
    }
  });
}

export const initApollo = () => {
  if (!apolloClient) {
    apolloClient = create();
  }

  return apolloClient;
};
