import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";

import {
  playAudio,
  pauseAudio,
  stopAudio,
  setAudioDuration,
  setAudioCurrentTime
} from "../../actions";

const AudioPlayer = ({
  src,
  controlTime,
  isPlaying,
  //redux
  playAudio,
  pauseAudio,
  stopAudio,
  setAudioDuration,
  setAudioCurrentTime
}) => {
  const audioTrack = useRef(new Audio());
  const { pathname } = useLocation();

  useEffect(() => {
    audioTrack.current.onended = () => {
      pauseAudio();
    };

    audioTrack.current.ondurationchange = (e) => {
      setAudioDuration(audioTrack.current.duration);
    };
    audioTrack.current.ontimeupdate = () => {
      setAudioCurrentTime(audioTrack.current.currentTime);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (src) {
      if (isPlaying) audioTrack.current.play().catch(() => {});
      // Ignore "The play() request was interrupted by a call to pause()", we want to interrupt previous one
      else audioTrack.current.pause();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaying]);

  useEffect(() => {
    if (src) {
      audioTrack.current.currentTime = controlTime;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controlTime]);

  useEffect(() => {
    if (src) {
      setAudioDuration(0);
      setAudioCurrentTime(0);

      audioTrack.current.src = src;
      playAudio();
    } else {
      audioTrack.current.src = "";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src]);

  useEffect(() => {
    stopAudio();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return null;
};

const mapStateToProps = ({ audioPlayer }) => ({
  src: audioPlayer.src,
  controlTime: audioPlayer.controlTime,
  isPlaying: audioPlayer.isPlaying
});

export default connect(mapStateToProps, {
  playAudio,
  pauseAudio,
  stopAudio,
  setAudioDuration,
  setAudioCurrentTime
})(AudioPlayer);
