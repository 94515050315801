import axios from "axios";

import {
  FETCH_SCHOOLS,
  FETCH_ACCOUNTS,
  UPDATE_ACCOUNT,
  UPDATE_ACCOUNTS
} from "./type";

// graphql
import { gql } from "graphql-request";
import { initGQLClient } from "../graphql/gqlRequest";

// gql
const QUERYSCHOOLS = gql`
  query TaWeb_Schools {
    me {
      schools {
        id
        name
        teacherIds
        codes(pagination: { limit: 0 }) {
          codeString
          status
          expiresAt
          type
        }
        teachers(pagination: { limit: 0 }) {
          id
          name
          username
          status
        }
      }
    }
  }
`;

// fetch all the schools(alone this user)
export const fetchSchools = ({ user, dispatch }) => {
  const client = initGQLClient();
  client
    .request(QUERYSCHOOLS, {
      filter: {
        principalId: {
          eq: user.id
        }
      },
      pagination: {
        limit: 0
      }
    })
    .then(async (data) => {
      if (data && data?.me?.schools) {
        const schools = data.me.schools;
        let schoolMap = {};
        let teacherIds = [];

        for (let school of schools) {
          schoolMap[school.id] = school;
          teacherIds = teacherIds.concat(school.teacherIds);
        }

        teacherIds = [...new Set(teacherIds)]; // change to Set to remove duplicate elements

        dispatch({ type: FETCH_SCHOOLS, payload: { schoolMap } });

        if (teacherIds.length !== 0) {
          const {
            data: { accountMap }
          } = await axios.get(`account/access?userIds=${teacherIds.join()}`);

          dispatch({ type: FETCH_ACCOUNTS, payload: { accountMap } }); // get teacher access
        }
      }
    });
};

// update one school
export const updateTeacherAccount =
  ({ teacherId, unAccess }, callback) =>
  async (dispatch, getStore) => {
    const {
      data: { account }
    } = await axios.patch(`account/${teacherId}`, {
      patch: {
        action: `UPDATE_TEACHER`,
        data: { unAccess }
      }
    });

    dispatch({ type: UPDATE_ACCOUNT, payload: { account } });

    if (callback) callback();
  };

//update Principal&Teacher account unAccess (video part)
export const updatePrincipalTeacherAccountVideoAccess =
  ({ userIds, banList }, callback) =>
  async (dispatch, getStore) => {
    const {
      data: { accountMap }
    } = await axios.patch(`/accountVideoAccess?userIds=${userIds.join()}`, {
      patch: {
        data: { banList }
      }
    });
    // dispatch({ type: UPDATE_ACCOUNT, payload: { account } });
    dispatch({ type: UPDATE_ACCOUNTS, payload: { accountMap } });

    if (callback) callback();
  };

//update Principal&Teacher account unAccess (download part)
export const updatePrincipalTeacherAccountDownloadAccess =
  ({ userIds, banList }, callback) =>
  async (dispatch, getStore) => {
    const {
      data: { accountMap }
    } = await axios.patch(`/accountDownloadAccess?userIds=${userIds.join()}`, {
      patch: {
        data: { banList }
      }
    });
    // dispatch({ type: UPDATE_ACCOUNT, payload: { account } });
    dispatch({ type: UPDATE_ACCOUNTS, payload: { accountMap } });

    if (callback) callback();
  };

// //fetch school teachers&principal
export const fetchPrincipalsTeachers =
  (userIds, callback) => async (dispatch, getStore) => {
    const {
      data: { accountMap }
    } = await axios.get(`account/access?userIds=${userIds.join()}`);

    dispatch({ type: FETCH_ACCOUNTS, payload: { accountMap } });

    if (callback) callback();
  };
