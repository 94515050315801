import {
  FETCH_BOOKSETS,
  UPDATE_BOOKSET,
  LOADING_BOOKSETS,
  CLEAN_AUTH
} from "../actions/type";

const INIT_STATE = { booksetMap: {}, isLoadingBooksets: false };

export default (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case FETCH_BOOKSETS:
      return {
        ...state,
        booksetMap: { ...payload.booksetMap },
        isLoadingBooksets: false
      };

    case LOADING_BOOKSETS:
      return { ...state, isLoadingBooksets: true };

    case UPDATE_BOOKSET:
      return {
        ...state,
        booksetMap: {
          ...state.booksetMap,
          [payload.bookset._id]: payload.bookset
        }
      };

    case CLEAN_AUTH:
      return { ...INIT_STATE };

    default:
      return state;
  }
};
