import {
  LOAD_AUDIO,
  PLAY_AUDIO,
  PAUSE_AUDIO,
  STOP_AUDIO,
  CLEAN_AUTH,
  SET_AUDIO_DURATION,
  SET_AUDIO_CURRENT_TIME,
  CONTROL_AUDIO_CURRENT_TIME
} from "../actions/type";

const INIT_STATE = {
  src: null,
  name: "",
  isPlaying: false,
  isSoundEffect: true,
  duration: 0,
  currentTime: 0,
  controlTime: 0
};

export default (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case LOAD_AUDIO:
      return {
        ...state,
        isPlaying: false,
        src: payload.src,
        isSoundEffect: payload.isSoundEffect
      };

    case PLAY_AUDIO:
      return { ...state, isPlaying: payload };

    case PAUSE_AUDIO:
      return { ...state, isPlaying: payload };

    case SET_AUDIO_DURATION:
      return { ...state, duration: payload.duration };

    case SET_AUDIO_CURRENT_TIME:
      return { ...state, currentTime: payload.currentTime };

    case CONTROL_AUDIO_CURRENT_TIME:
      return { ...state, controlTime: payload.controlTime };

    case STOP_AUDIO:
      return { ...state, ...INIT_STATE };

    case CLEAN_AUTH:
      return { ...state, ...INIT_STATE };

    default:
      return state;
  }
};
