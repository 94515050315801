import {
  FETCH_ACCOUNTS,
  UPDATE_ACCOUNT,
  UPDATE_ACCOUNTS,
  CLEAN_AUTH
} from "../actions/type";

const INIT_STATE = {
  newAccount: "",
  accountMap: {}
};

export default (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case FETCH_ACCOUNTS:
      return {
        ...state,
        accountMap: { ...payload.accountMap }
      };

    case UPDATE_ACCOUNT:
      return {
        ...state,
        accountMap: {
          ...state.accountMap,
          [payload.account._user]: payload.account
        }
      };
    case UPDATE_ACCOUNTS:
      let accountMap = {};
      payload.accountMap.forEach((account) => {
        accountMap[account._user] = account;
      });

      return {
        ...state,
        accountMap: {
          ...state.accountMap,
          ...accountMap
        }
      };

    case CLEAN_AUTH:
      return { ...INIT_STATE };

    default:
      return state;
  }
};
