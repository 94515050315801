import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import qs from "qs";
import { useDispatch } from "react-redux";
import { useSigninWithCode } from "../utils/authHelpers";
import { SET_WARNING, CLOSE_WARNING } from "../actions/type";
import {
  default as ACCESS_LEVELS,
  ADMINS,
  STUDENT
} from "../config/accessLevels";

const MyBestUserLogin = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const signinWithCode = useSigninWithCode();
  const dispatch = useDispatch();

  useEffect(() => {
    const search = qs.parse(location.search, { ignoreQueryPrefix: true });

    signinWithCode(search.code)
      .then(({ data }) => {
        const roles = data.signinWithCode.me.roles;

        let previousUrl = sessionStorage.getItem("previousURL");

        if (!previousUrl) {
          navigate(
            roles.some((role) => ACCESS_LEVELS[ADMINS].includes(role))
              ? "/admin"
              : roles.some((role) => ACCESS_LEVELS[STUDENT].includes(role))
              ? "/student"
              : "/teacher"
          );
        } else {
          sessionStorage.removeItem("previousURL");
          navigate(previousUrl);
        }
      })
      .catch((error) => {
        dispatch({
          type: SET_WARNING,
          payload: {
            alert: error.message,
            confirm: () => {
              dispatch({ type: CLOSE_WARNING, payload: {} });
              navigate("/");
            }
          }
        });
      });
  }, [signinWithCode, navigate, location, dispatch]);

  return null;
};

export default MyBestUserLogin;
