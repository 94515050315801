import React, { createContext, useContext, useCallback, useState } from "react";
import { useMutation, useApolloClient, gql } from "@apollo/client";

import { useDispatch } from "react-redux";

// utils
import { useScriptConverter } from "../languageHelpers";
import { AUTH_USER, CLEAN_AUTH } from "../../actions/type";
import { setRollbarUserInfo } from "../../loggers";

// components
import SignInDialog from "./SignInDialog";

export const useSignout = () => {
  const dispatch = useDispatch();

  const client = useApolloClient();
  return useCallback(async () => {
    //listen teacher use status
    localStorage.removeItem("token");
    localStorage.removeItem("time");
    localStorage.removeItem("isSendtoServer");
    setRollbarUserInfo();

    sessionStorage.removeItem("mode");
    sessionStorage.removeItem("classroom");
    sessionStorage.removeItem("school");

    // redux compatibility
    localStorage.removeItem("user");
    dispatch({ type: CLEAN_AUTH, payload: {} });

    client.writeQuery({ query: AUTH_CHECK, data: { me: null } });
    client.cache.data.clear();
    client.cache.optimisticData = client.cache.data;
    // client.clearStore();
  }, [client, dispatch]);
};

export const useSigninWithCode = () => {
  const dispatch = useDispatch();

  // language
  const converter = useScriptConverter();

  const [signinWithCode] = useMutation(SIGNIN_WITH_CODE, {
    update: (caches, { data: { signinWithCode } }) => {
      caches.writeQuery({
        query: AUTH_CHECK,
        data: signinWithCode
      });
    }
  });
  return useCallback(
    async (code) => {
      if (!code)
        throw new Error(
          converter({
            en: "Abnormal login, please try again",
            tw: "異常登入, 請重試一次",
            cn: "异常登入，请重试一次"
          })
        );
      const res = await signinWithCode({ variables: { code } });

      if (!res.data || !res.data?.signinWithCode.accessToken) {
        const errorMessage = {
          invalid: converter({
            en: `Invalid username or password`,
            tw: `帳號、密碼輸入錯誤`,
            cn: `帐号、密码输入错误`
          }),
          signinFail: converter({
            en: `Sign in Fail`,
            tw: `登入失敗`,
            cn: `登入失败`
          }),
          contractExpired: converter({
            en: `The school contract has expired, please contact your English consultant`,
            tw: `學校合約已到期，請洽詢您的專屬教務`,
            cn: `分校合约已到期，请洽询您的专属教务`
          }),
          noSchool: converter({
            en: `No school invitation code, please confirm whether you have entered the invitation code or contact your English consultant`,
            tw: `無學校邀請碼，請確認您是否輸入邀請碼或洽詢您的專屬教務`,
            cn: `无学校邀请码，请确认您是否输入邀请码或洽询您的专属教务`
          })
        };
        throw new Error(errorMessage[res.data?.signinWithCode.message]);
      }
      localStorage.setItem("token", res.data?.signinWithCode.accessToken);
      // redux compatibility
      localStorage.setItem("user", JSON.stringify(res.data?.signinWithCode.me));
      setRollbarUserInfo(res.data?.signinWithCode.me);
      dispatch({
        type: AUTH_USER,
        payload: {
          user: res.data?.signinWithCode.me
        }
      });

      return res;
    },
    [signinWithCode, dispatch, converter]
  );
};

// gql
const AUTH_CHECK = gql`
  query TaWeb_AuthCheck {
    me {
      id
      username
      name
      email
      type
      roles
      role
      status
    }
  }
`;

const SIGNIN_WITH_CODE = gql`
  mutation Signin($code: String!) {
    signinWithCode(code: $code) {
      accessToken
      me {
        id
        username
        name
        email
        type
        roles
        role
        status
        canEdit
      }
      message
    }
  }
`;

export const MyBestUserSigninContext = createContext();

export const MyBestUserSigninProvider = ({ children }) => {
  const [open, setOpen] = useState(null);
  const onClose = useCallback(() => setOpen(null), []);

  const signin = useCallback((src) => setOpen(src), []);

  return (
    <MyBestUserSigninContext.Provider value={signin}>
      {children}
      <SignInDialog src={open} onClose={onClose} />
    </MyBestUserSigninContext.Provider>
  );
};

export const useMyBestUserSignin = () => useContext(MyBestUserSigninContext);
