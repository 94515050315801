import { OPEN_FULLSCREEN, CLOSE_FULLSCREEN } from "../actions/type";

const INIT_STATE = {
  show: false
};

export default (state = INIT_STATE, { type }) => {
  switch (type) {
    case OPEN_FULLSCREEN:
      return { ...state, show: true };
    case CLOSE_FULLSCREEN:
      return { ...INIT_STATE };

    default:
      return state;
  }
};
