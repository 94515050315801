import {
  FETCH_CLASSALLDETAILS,
  FETCH_BOOKVIEWEDDETAILS,
  FETCH_SECTIONDETAILS
} from "../actions/type";

const INIT_STATE = {
  allbooksViewedStatusMap: {},
  bookViewedStatusMap: {},
  sectionInfoMap: {}
};

export default (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case FETCH_CLASSALLDETAILS:
      return {
        ...state,
        allbooksViewedStatusMap: { ...payload.allbooksViewedStatusMap }
      };

    case FETCH_BOOKVIEWEDDETAILS:
      return {
        ...state,
        bookViewedStatusMap: { ...payload.bookViewedStatusMap }
      };

    case FETCH_SECTIONDETAILS:
      return {
        ...state,
        sectionInfoMap: { ...payload.sectionInfoMap }
      };

    default:
      return state;
  }
};
