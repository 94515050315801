import React, { useState } from "react";
import styled from "styled-components/macro";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

import MainContainer from "./elements/MainContainer";

const Background = styled.div`
  display: ${({ show }) => (show ? `flex` : `none`)}; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 99; /* Sit on top */

  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.9); /* Black w/ opacity */

  align-items: center;
  justify-content: center;
`;

const ErrorIcon = styled(FontAwesomeIcon)`
  color: #fff;
`;

const Container = styled.div`
  height: 50%;
  width: 30%;

  overflow: hidden;

  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 20% 50% 30%;
  grid-template-areas:
    "title"
    "content"
    "bottom";

  border-radius: 0.5em;
  background: white;
  margin: auto;
  position: relative;
`;

const Title = styled.div`
  grid-area: title;
  text-align: center;
  font-size: 2em;
  font-weight: 700;
  background: ${({ theme }) => theme.redSecondary};
  color: #fff;
`;

const Content = styled.div`
  grid-area: content;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const Description = styled.div`
  font-size: 1.2em;
  width: 80%;
  height: 80%;
  overflow-y: auto;
  white-space: pre-wrap;
  word-break: break-word;
`;

const Bottom = styled.div`
  grid-area: bottom;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Face = styled.div`
  text-align: center;
  font-size: 1.1em;
  cursor: pointer;

  :hover {
    color: #ee0000;
  }
`;
const Help = styled.div`
  font-size: 0.6em;
  text-align: center;
`;

const faces = [
  "(´◓Д◔`)",
  "(´⊙ω⊙`)",
  "(╯‵□′)╯︵┴─┴",
  "(☉д⊙)",
  "இдஇ",
  "_(´ཀ`」 ∠)_"
];

const Error = ({ error: { show, errorMessage } }) => {
  const [faceIndex, setFaceIndex] = useState(
    Math.floor(Math.random() * faces.length)
  );

  const changeFace = () => {
    setFaceIndex((faceIndex) => {
      const newIndex = Math.floor(Math.random() * faces.length);

      return faceIndex === newIndex ? (newIndex + 1) % faces.length : newIndex;
    });
  };

  return (
    <Background show={show}>
      <MainContainer>
        <Container>
          <Title>
            <ErrorIcon icon={faExclamationTriangle} /> Opps!
          </Title>

          <Content>
            <Description>{errorMessage}</Description>
          </Content>

          <Bottom>
            <Help>Please ask the engineers for help.</Help>
            <Face onClick={changeFace}>{faces[faceIndex]}</Face>
          </Bottom>
        </Container>
      </MainContainer>
    </Background>
  );
};

const mapStateToProps = ({ error }) => ({ error });

export default connect(mapStateToProps, null)(Error);
