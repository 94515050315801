import {
  FETCH_CATEGORYS,
  UPDATE_CATEGORY,
  LOADING_CATEGORYS,
  FETCH_FOLDERS,
  UPDATE_FOLDER,
  LOADING_FOLDERS,
  // FETCH_PAGES,
  // LOADING_PAGES,
  CLEAN_AUTH
} from "../actions/type";

const INIT_STATE = {
  categoryMap: {},
  isLoadingCategorys: false,
  folderMap: {},
  isLoadingFolders: false,
  downloadPageMap: {},
  isLoadingPages: false
};

export default (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    // category
    case FETCH_CATEGORYS:
      return {
        ...state,
        categoryMap: { ...payload.categoryMap },
        isLoadingCategorys: false
      };

    case LOADING_CATEGORYS:
      return { ...state, isLoadingCategorys: true };

    case UPDATE_CATEGORY:
      return {
        ...state,
        categoryMap: {
          ...state.categoryMap,
          [payload.category._id]: payload.category
        }
      };

    // folder
    case FETCH_FOLDERS:
      return {
        ...state,
        folderMap: { ...payload.folderMap },
        isLoadingFolders: false
      };
    case LOADING_FOLDERS:
      return { ...state, isLoadingFolders: true };

    case UPDATE_FOLDER:
      return {
        ...state,
        folderMap: {
          ...state.folderMap,
          [payload.folder._id]: payload.folder
        }
      };

    case CLEAN_AUTH:
      return { ...INIT_STATE };

    default:
      return state;
  }
};
