import { SET_LOAD, CLEAN_AUTH, CLOSE_LOAD } from "../actions/type";

const INIT_STATE = { loadMessage: "", show: false, isLoading: false };

export default (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case SET_LOAD:
      return {
        loadMessage: payload || `Loading...`,
        show: true,
        isLoading: true
      };

    case CLOSE_LOAD:
      return { loadMessage: "", show: false, isLoading: false };

    case CLEAN_AUTH:
      return { ...INIT_STATE };

    default:
      return state;
  }
};
