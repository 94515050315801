import React, { Suspense } from "react";
import { PuffSpinner, SmallSpinner } from "./Spinner";

export const DefaultSuspense = ({ children }) => (
  <Suspense fallback={<PuffSpinner />}>{children}</Suspense>
);

export const SmallSuspense = ({ children }) => (
  <Suspense fallback={<SmallSpinner />}>{children}</Suspense>
);
