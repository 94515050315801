import axios from "axios";
import { batch } from "react-redux";

import {
  UPDATE_BOOK,
  FETCH_UNITS,
  UPDATE_UNIT,
  UPDATE_SECTION,
  LOADING_UNITS,
  FETCH_SECTIONS
} from "./type";

import mediaServerAddress from "../config/mediaServerAddress";

// fetch all the units
export const fetchUnits = (options, callback) => async (dispatch, getStore) => {
  dispatch({
    type: LOADING_UNITS,
    payload: {}
  });

  let query = ``;

  if (options.bookId !== undefined) query = `${query}&bookId=${options.bookId}`;

  if (options.bookTypeId !== undefined)
    query = `${query}&bookTypeId=${options.bookTypeId}`;

  if (options.hideChildren !== undefined)
    query = `${query}&hideChildren=${options.hideChildren}`;

  const route = `unit${query ? `?${query.slice(1)}` : ``}`;

  const {
    data: { unitMap }
  } = await axios.get(route);

  dispatch({ type: FETCH_UNITS, payload: { unitMap } });

  if (callback) callback();
};

// save edit unit
export const saveEditUnit =
  (formProps, unitId, coverImage, src, title, isHidden, callback) =>
  async (dispatch, getStore) => {
    const uselessImages = {};
    let _image = coverImage;

    if (coverImage) uselessImages[coverImage._id] = true;

    if (src) {
      if (formProps[`image_0`]) {
        const fd = new FormData();

        fd.append(`image`, formProps[`image_0`]);

        // upload new image
        const {
          data: { image }
        } = await mediaServerAddress.post(`image`, fd);

        _image = image;
      } else {
        delete uselessImages[coverImage._id];
      }
    }

    const {
      data: { unit }
    } = await axios.patch(`unit/${unitId}`, {
      patch: {
        action: `changeCover`,
        data: {
          imageId: src && _image ? _image._id : null,
          title: title,
          isHidden: isHidden
        }
      }
    });

    // clean up old images
    await axios.delete(`image`, {
      data: { imageIds: Object.keys(uselessImages) }
    });

    dispatch({ type: UPDATE_UNIT, payload: { unit } });

    if (callback) callback();
  };

//save add unit
export const saveAddUnit =
  (booksetId, bookTypeId, bookId, formProps, title, isHidden, callback) =>
  async (dispatch, getStore) => {
    let imageId = null;

    if (formProps["image_0"]) {
      const fd = new FormData();

      fd.append(`image`, formProps[`image_0`]);

      //upload new image
      const {
        data: { image }
      } = await mediaServerAddress.post(`image`, fd);

      imageId = image;
    }

    const {
      data: { unit }
    } = await axios.post(`unit`, {
      title,
      booksetId,
      bookTypeId,
      bookId,
      imageId,
      isHidden
    });

    //update book._units
    const {
      data: { book }
    } = await axios.patch(`book/${bookId}`, {
      patch: {
        action: "addUnit",
        data: {
          unitId: unit._id
        }
      }
    });

    // automatically add a section
    const {
      data: { section }
    } = await axios.post(`section`, {
      title: "New Section",
      booksetId,
      bookTypeId,
      bookId,
      unitId: unit._id
    });

    //update unit._sections
    const updateUnit = await axios.patch(`unit/${unit._id}`, {
      patch: {
        action: "addSection",
        data: {
          sectionId: section._id
        }
      }
    });

    batch(() => {
      dispatch({ type: UPDATE_UNIT, payload: { unit } });
      dispatch({ type: UPDATE_BOOK, payload: { book } });
      dispatch({ type: UPDATE_SECTION, payload: { section } });
      dispatch({ type: UPDATE_UNIT, payload: updateUnit.data });
    });

    if (callback) callback();
  };

// delete unit
export const deleteUnit =
  (bookId, unitId, callback) => async (dispatch, getStore) => {
    // delete unit & update book._units
    const {
      data: { book }
    } = await axios.patch(`book/${bookId}`, {
      patch: {
        action: "deleteUnit",
        data: { unitId: unitId }
      }
    });

    batch(() => {
      dispatch({ type: UPDATE_BOOK, payload: { book } });
    });

    if (callback) callback();
  };

export const editTeachingVideo =
  (formProps, callback) => async (dispatch, getStore) => {
    const {
      data: { unit }
    } = await axios.patch(`unit/${formProps.unitId}`, {
      patch: {
        action: "EDIT_TEACHING_VIDEO_LIST",
        data: {
          teachingVideoId: formProps.teachingVideoId,
          title: formProps.title
        }
      }
    });

    dispatch({ type: UPDATE_UNIT, payload: { unit } });
    if (callback) callback();
  };

// move unit
export const moveUnit =
  ({ bookId, unitId }, from, to, callback) =>
  async (dispatch, getStore) => {
    const {
      data: { book }
    } = await axios.patch(`book/${bookId}`, {
      patch: {
        action: "moveUnit",
        data: { unitId, from, to }
      }
    });

    // update book
    dispatch({ type: UPDATE_BOOK, payload: { book } });

    if (callback) callback();
  };

// duplicate section
export const duplicateSections =
  ({ sectionIds, unitId }, callback, mode) =>
  async (dispatch, getStore) => {
    const {
      data: { unit }
    } = await axios.patch(`unit/${unitId}`, {
      patch: {
        action: "duplicate_sections",
        data: { sectionIds, mode: mode || "" }
      }
    });

    const {
      data: { sectionMap }
    } = await axios.get(`section`);

    batch(() => {
      dispatch({ type: UPDATE_UNIT, payload: { unit } });
      dispatch({ type: FETCH_SECTIONS, payload: { sectionMap } });
    });

    if (callback) callback();
  };
