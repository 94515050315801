import React, { useContext } from "react";
import { ThemeContext } from "styled-components/macro";
import PuffLoader from "react-spinners/PuffLoader";
import { Container } from "../app/elements/Container.styled";

export const SmallSpinner = () => {
  const { primary } = useContext(ThemeContext);

  return <PuffLoader color={primary} loading={true} size={"3em"} />;
};

export const PuffSpinner = () => {
  const { primary } = useContext(ThemeContext);

  return (
    <Container>
      <PuffLoader color={primary} loading={true} size={"5em"} />
    </Container>
  );
};
