import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  FormHelperText
} from "@material-ui/core";

// utils
import { makeScripts } from "../languageHelpers";

const DialogBody = ({ options, onCancel, onConfirm }) => {
  const scripts = useScripts();
  const {
    title = scripts.title,
    description = "",
    confirmationText = scripts.confirmationText,
    cancellationText = scripts.cancellationText,
    confirmationCheck = scripts.confirmationCheck,
    confirmationButtonProps = {},
    cancellationButtonProps = {}
  } = options;

  const [check, setCheck] = React.useState("");

  const handleChange = (event) => setCheck(event.target.value);

  return (
    <>
      {title && <DialogTitle>{title}</DialogTitle>}

      <DialogContent>
        {description && <DialogContentText>{description}</DialogContentText>}

        <FormHelperText>
          {scripts.confirmationHint1 +
            confirmationCheck +
            scripts.confirmationHint2}
        </FormHelperText>
        <TextField
          fullWidth
          variant="outlined"
          margin="dense"
          onChange={handleChange}
          placeholder={confirmationCheck}
          type="text"
          name="check"
          value={check}
          autoFocus
          autoComplete="off"
        />
      </DialogContent>

      <DialogActions>
        <Button {...cancellationButtonProps} onClick={onCancel}>
          {cancellationText}
        </Button>
        <Button
          color="secondary"
          {...confirmationButtonProps}
          onClick={onConfirm}
          disabled={check !== confirmationCheck}
        >
          {confirmationText}
        </Button>
      </DialogActions>
    </>
  );
};

// language
const useScripts = makeScripts({
  title: { en: "Are you sure to do this?", tw: "您確定要執行嗎？" },
  confirmationText: { en: "Confirm", tw: "確認" },
  cancellationText: { en: "Cancel", tw: "取消" },
  confirmationCheck: { en: "confirm", tw: "確認" },
  confirmationHint1: {
    en: `To confirm action, type "`,
    tw: "若確認執行，請在文字輸入欄位輸入“"
  },
  confirmationHint2: { en: `" in the text input field.`, tw: "”" }
});

const StrictConfirmationDialog = ({
  open,
  options,
  onCancel,
  onConfirm,
  onClose
}) => {
  const { dialogProps = {} } = options;

  return (
    <Dialog fullWidth {...dialogProps} open={open} onClose={onClose}>
      <DialogBody options={options} onCancel={onCancel} onConfirm={onConfirm} />
    </Dialog>
  );
};

export default StrictConfirmationDialog;
