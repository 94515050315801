import axios from "axios";
// utils
import { MEDIA_SERVER } from "./environment";

const mediaServerAddress = axios.create({
  baseURL: `${MEDIA_SERVER}`,
  headers: {
    "Content-Type": "multipart/form-data"
  }
});

mediaServerAddress.interceptors.request.use(
  async (req) => {
    const token = localStorage.getItem("token");
    req.headers.authorization = token ? `Bearer ${token}` : null;
    return req;
  },
  (error) => Promise.reject(error)
);

export default mediaServerAddress;
