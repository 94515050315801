import { CHANGE_TEACHINGMODE } from "../actions/type";

const INIT_STATE = {
  mode: ""
};

export default (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case CHANGE_TEACHINGMODE:
      return {
        mode: payload
      };

    default:
      return state;
  }
};
