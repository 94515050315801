import axios from "axios";
import {
  SET_NOTIFY,
  CLOSE_NOTIFY,
  SET_WARNING,
  CLOSE_WARNING,
  SET_LOAD,
  CLOSE_LOAD
} from "../actions/type";
import { rollbar } from "../loggers";
import mediaServerAddress from "../config/mediaServerAddress";
import { batch } from "react-redux";
import * as rax from "retry-axios";

const maxRetry = 10;

const errorHandlingInterceptor = (dispatch) => {
  const instance = [axios, mediaServerAddress];
  let retryLoading = false;
  instance.forEach((x) => {
    let interceptorId = rax.attach(x);
    x.defaults.raxConfig = {
      instance: x,
      retry: maxRetry,
      noResponseRetries: maxRetry,
      retryDelay: 2000,
      statusCodesToRetry: [
        [100, 199],
        [429, 429],
        [501, 599]
      ],
      backoffType: "static",
      onRetryAttempt: (err) => {
        const cfg = rax.getConfig(err);
        retryLoading = true;
        dispatch({
          type: SET_LOAD,
          payload: `Unstable Internet:\nReconnecting ... (${cfg.currentRetryAttempt})`
        });
      }
    };
    x.interceptors.response.use(
      (res) => {
        if (retryLoading) {
          retryLoading = false;
          dispatch({ type: CLOSE_LOAD, payload: {} });
        }
        return res;
      },
      (err) => {
        let errMessage = null;
        let dispatchType = SET_WARNING;
        let doLog = true;

        if (err.response) {
          errMessage = err.response.data?.error || err.message;

          if (err.response.status === 401) {
            // no 403, because S3 of TA server can not find the file will also send back 403
            errMessage =
              "When you logged into different browser or device, please login again.";
            // Force re-login to refresh JWT token
            dispatchType = SET_NOTIFY;
            doLog = false;
          }
        } else if (err.request) {
          errMessage = `Unstable Internet:\nPlease check your internet connection and try again.`;
        } else {
          errMessage = "[Special Case] " + err.message;
        }

        dispatch({
          type: dispatchType,
          payload: {
            alert: errMessage,
            confirm: () => {
              batch(() => {
                dispatch({ type: CLOSE_LOAD, payload: {} });

                if (dispatchType === SET_WARNING) {
                  dispatch({ type: CLOSE_WARNING, payload: {} });
                } else if (dispatchType === SET_NOTIFY) {
                  dispatch({ type: CLOSE_NOTIFY, payload: {} });
                }
              });
            }
          }
        });
        if (doLog) rollbar.error(err);
        return Promise.reject(err);
      }
    );
  });
};

export default errorHandlingInterceptor;
