import {
  FETCH_LOGS,
  CLEAN_AUTH,
  LOADING_LOGS,
  VIEW_LOG
} from "../actions/type";

const INIT_STATE = { logMap: {}, isLoadingLogs: false };

export default (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case FETCH_LOGS:
      return {
        ...state,
        logMap: { ...payload.logMap },
        isLoadingLogs: false
      };

    case LOADING_LOGS:
      return { ...state, isLoadingLogs: true };

    case VIEW_LOG:
      return {
        ...state,
        logMap: {
          ...state.logMap,
          [payload.sectionId]: {
            ...state.logMap[payload.sectionId],
            [payload.pageId]: payload.timestamp
          }
        }
      };

    case CLEAN_AUTH:
      return { ...INIT_STATE };

    default:
      return state;
  }
};
