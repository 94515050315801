import {
  FETCH_SECTIONS,
  CLEAN_AUTH,
  UPDATE_SECTION,
  LOADING_SECTIONS
} from "../actions/type";

const INIT_STATE = { sectionMap: {}, isLoadingSections: false };

export default (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case FETCH_SECTIONS:
      return {
        ...state,
        sectionMap: { ...payload.sectionMap },
        isLoadingSections: false
      };

    case LOADING_SECTIONS:
      return { ...state, isLoadingSections: true };

    case UPDATE_SECTION:
      return {
        ...state,
        sectionMap: {
          ...state.sectionMap,
          [payload.section._id]: payload.section
        }
      };

    case CLEAN_AUTH:
      return { ...INIT_STATE };

    default:
      return state;
  }
};
