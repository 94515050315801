import { OPEN_ANIMATION_MODAL, CLOSE_ANIMATION_MODAL } from "./type";

export const openAnimationModal = (component, close) => ({
  type: OPEN_ANIMATION_MODAL,
  payload: { component, close }
});

export const closeAnimationModal = () => ({
  type: CLOSE_ANIMATION_MODAL,
  payload: null
});
