export const CLIENTID = process.env.REACT_APP_CLIENTID;
export const SERVER = process.env.REACT_APP_SERVER;
export const MEDIA_SERVER = process.env.REACT_APP_MEDIA_SERVER;
export const MEDIA_STORAGE = process.env.REACT_APP_MEDIA_STORAGE;
export const MYBESTUSER = process.env.REACT_APP_MYBESTUSER;
export const MYBESTUSER_API = process.env.REACT_APP_MYBESTUSER_API;
export const ROLLBARACCESSTOKEN = process.env.REACT_APP_ROLLBARACCESSTOKEN;
export const HTML5EXPORT = process.env.REACT_APP_HTML5EXPORT;

// TODO: check variable import null, or write a test
