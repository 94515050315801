import {
  FETCH_VIDEO_CATEGORYS,
  UPDATE_VIDEO_CATEGORY,
  LOADING_VIDEO_CATEGORYS,
  FETCH_VIDEO_FOLDERS,
  UPDATE_VIDEO_FOLDER,
  LOADING_VIDEO_FOLDERS,
  FETCH_VIDEOLISTS,
  UPDATE_VIDEOLIST,
  LOADING_VIDEOLISTS,
  CLEAN_AUTH
} from "../actions/type";

const INIT_STATE = {
  categoryMap: {},
  isLoadingCategorys: false,
  folderMap: {},
  isLoadingFolders: false,
  videoPageMap: {},
  isLoadingPages: false,
  videoListMap: {},
  isLoadingVideoLists: false
};

export default (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    // category
    case FETCH_VIDEO_CATEGORYS:
      return {
        ...state,
        categoryMap: { ...payload.categoryMap },
        isLoadingCategorys: false
      };

    case LOADING_VIDEO_CATEGORYS:
      return { ...state, isLoadingCategorys: true };

    case UPDATE_VIDEO_CATEGORY:
      return {
        ...state,
        categoryMap: {
          ...state.categoryMap,
          [payload.category._id]: payload.category
        }
      };

    // folder
    case FETCH_VIDEO_FOLDERS:
      return {
        ...state,
        folderMap: { ...payload.folderMap },
        isLoadingFolders: false
      };
    case LOADING_VIDEO_FOLDERS:
      return { ...state, isLoadingFolders: true };

    case UPDATE_VIDEO_FOLDER:
      return {
        ...state,
        folderMap: {
          ...state.folderMap,
          [payload.folder._id]: payload.folder
        }
      };

    //videoList
    case FETCH_VIDEOLISTS:
      return {
        ...state,
        videoListMap: { ...payload.videoListMap },
        isLoadingVideoLists: false
      };
    case LOADING_VIDEOLISTS:
      return { ...state, isLoadingVideoLists: true };

    case UPDATE_VIDEOLIST:
      return {
        ...state,
        videoListMap: {
          ...state.videoListMap,
          [payload.videoList._id]: payload.videoList
        }
      };

    case CLEAN_AUTH:
      return { ...INIT_STATE };

    default:
      return state;
  }
};
