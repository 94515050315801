import {
  OPEN_TOOLBAR,
  CLOSE_TOOLBAR,
  ADD_TOOLBAR_ICON,
  MOVE_TOOLBAR_ICON,
  CHANGE_TOOLBAR_ICON,
  STORE_TOOLBAR_GRID,
  RESIZE_TOOLBAR_GRID,
  DELETE_TOOLBAR_ICON,
  UNDO_TOOLBAR_HISTORY,
  REDO_TOOLBAR_HISTORY,
  INITIAL_TOOLBAR_HISTORY,
  DELETEALL_TOOLBAR_HISTORY,
  DRAWLINE_TOOLBAR_HISTORY,
  ADD_TOOLBAR_TEXT,
  STORE_TOOLBAR_TEXT,
  MOVE_TOOLBAR_TEXT,
  DELETE_TOOLBAR_TEXT,
  RESIZE_TOOLBAR_TEXT,
  ADD_TOOLBAR_SHAPE,
  ADD_TOOLBAR_SCREENSHOT,
  DELETE_TOOLBAR_SCREENSHOT,
  STORE_TOOLBAR_SCOREBOARD_INFO
} from "./type";

//show toolbar
export const openToolbar = () => ({
  type: OPEN_TOOLBAR,
  payload: null
});

//close toolbar
export const closeToolbar = () => ({
  type: CLOSE_TOOLBAR,
  payload: null
});

// add toolbar icon
export const addToolbarIcon = (icon) => {
  return { type: ADD_TOOLBAR_ICON, payload: { icon } };
};

//move toolbar icon
export const moveToolbarIcon = (index, movement) => {
  return { type: MOVE_TOOLBAR_ICON, payload: { index, movement } };
};

//change toolbar icon
export const changeToolbarIcon = (index, icon, random) => {
  return { type: CHANGE_TOOLBAR_ICON, payload: { index, icon, random } };
};

//store initial grid width,height
export const storeToolbarGrid = (index, resize) => {
  return { type: STORE_TOOLBAR_GRID, payload: { index, resize } };
};

//resize toolbar grid
export const resizeToolbarGrid = (index, scale) => {
  return { type: RESIZE_TOOLBAR_GRID, payload: { index, scale } };
};

//delete icon
export const deleteToolbarIcon = (index) => {
  return { type: DELETE_TOOLBAR_ICON, payload: { index } };
};

// update parent props
export const updateToolbarHistoryStep = (step) => {
  return { type: ADD_TOOLBAR_ICON, payload: { step } };
};

// set history step to default 0
export const initialToolbarHistory = () => {
  return { type: INITIAL_TOOLBAR_HISTORY };
};

// delete all
export const deleteAllToolbarHistory = () => {
  return { type: DELETEALL_TOOLBAR_HISTORY };
};

// drawing lines
export const drawlineToolbarHistory = (points, mode, color, brushMode) => {
  return {
    type: DRAWLINE_TOOLBAR_HISTORY,
    payload: { points, mode, color, brushMode }
  };
};

// undo
export const undoToolbarHistory = () => {
  // return { type: UNDO_TOOLBAR_HISTORY, payload: { step } };
  return { type: UNDO_TOOLBAR_HISTORY };
};

// redo
export const redoToolbarHistory = () => {
  // return { type: REDO_TOOLBAR_HISTORY, payload: { step } };
  return { type: REDO_TOOLBAR_HISTORY };
};

// add text
export const addToolbarText = (style) => {
  return { type: ADD_TOOLBAR_TEXT, payload: { style } };
};

// store words
export const storeToolbarTextInfo = (index, mode, info) => {
  return { type: STORE_TOOLBAR_TEXT, payload: { index, mode, info } };
};

// move text
export const moveToolbarText = (index, Textposition) => {
  return { type: MOVE_TOOLBAR_TEXT, payload: { index, Textposition } };
};

// delete text
export const deleteToolbarText = (index) => {
  return { type: DELETE_TOOLBAR_TEXT, payload: { index } };
};

// resize toolbar text
export const resizeToolbarText = (index, scale) => {
  return { type: RESIZE_TOOLBAR_TEXT, payload: { index, scale } };
};

// add toolbar shape
export const addToolbarShape = (shape) => {
  return { type: ADD_TOOLBAR_SHAPE, payload: { shape } };
};

// add toolbar screenshot
export const addToolbarScreenShot = (screenshot) => {
  return { type: ADD_TOOLBAR_SCREENSHOT, payload: { screenshot } };
};

// delete toolbar screenshot
export const deleteToolbarScreenShot = () => {
  return { type: DELETE_TOOLBAR_SCREENSHOT };
};

// store toolbar scoreBoard information
export const storeToolbarScoreBoardInfo = (index, boardInfo) => {
  return { type: STORE_TOOLBAR_SCOREBOARD_INFO, payload: { index, boardInfo } };
};
