import {
  FETCH_TRAININGS,
  CLEAN_AUTH,
  UPDATE_TRAINING,
  LOADING_TRAININGS
} from "../actions/type";

const INIT_STATE = { trainingMap: {}, isLoadingTrainings: false };

export default (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case FETCH_TRAININGS:
      return {
        ...state,
        trainingMap: { ...payload.trainingMap },
        isLoadingTrainings: false
      };

    case LOADING_TRAININGS:
      return { ...state, isLoadingTrainings: true };

    case UPDATE_TRAINING:
      return {
        ...state,
        trainingMap: {
          ...state.trainingMap,
          [payload.training._id]: payload.training
        }
      };

    case CLEAN_AUTH:
      return { ...INIT_STATE };

    default:
      return state;
  }
};
