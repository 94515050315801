import "bootstrap/dist/css/bootstrap.min.css";

// load configs
import React from "react";
import ReactDOM from "react-dom";
import { ApolloProvider } from "@apollo/client";

// redux
import configureStore from "./store/configureStore";
import { Provider as ReduxProvider } from "react-redux";

// Components
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

// utils
import { initApollo } from "./graphql/init";
import { tokenInterceptor, errorHandlingInterceptor } from "./interceptors";
import { setRollbarUserInfo } from "./loggers";

// configure apollo client
let client = initApollo();

// get user and token from local storage
const _user = localStorage.getItem("user");
const user = _user ? JSON.parse(_user) : null;
const token = localStorage.getItem("token");
const isLogin = token && user && user.roles ? true : false;

// set up redux store
const store = configureStore({
  auth: isLogin ? { user, isLogin } : { user: null, isLogin },

  teachingMode: { mode: sessionStorage.getItem("mode") || "" }
});
errorHandlingInterceptor(store.dispatch);
if (isLogin) {
  setRollbarUserInfo(user);
  tokenInterceptor(true);
}

ReactDOM.render(
  <ReduxProvider store={store}>
    <ApolloProvider client={client}>
      <div
        style={{
          minHeight: "100vh",
          height: "100vh",
          display: "flex",
          flexDirection: "column"
        }}
      >
        {/* <CssBaseline /> */}
        <App />
      </div>
    </ApolloProvider>
  </ReduxProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
let refreshing = false;
serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    if (refreshing) {
      return;
    }

    refreshing = true;
    window.location.reload();
  }
});
