import React from "react";
import Dialog from "@material-ui/core/Dialog";

const SignInDialog = ({ onClose, src }) => (
  <Dialog open={Boolean(src)} onClose={onClose} maxWidth="sm" fullWidth>
    <iframe
      src={src}
      title="My Best User Sign In"
      style={{ border: "none", minHeight: "70vh" }}
    />
  </Dialog>
);

export default SignInDialog;
