import { cloneDeep } from "lodash-es";

import {
  FETCH_PAGES,
  UPDATE_PAGE,
  RESTORE_PAGES,
  OPEN_EDITOR,
  CLOSE_EDITOR,
  CHANGE_TITLE,
  CHANGE_TEACHING_NOTES,
  CHANGE_REMARK_TEACHING_NOTES,
  CHANGE_TEXT,
  CHANGE_REMARK_TEXT,
  CHANGE_VIDEO,
  CHANGE_IMAGE,
  REMOVE_IMAGE,
  ADD_AUDIO,
  ADD_VIDEO,
  MOVE_AUDIO,
  CHANGE_AUDIO,
  REMOVE_AUDIO,
  DELETE_AUDIO,
  ADD_CARD,
  CHANGE_CARD_TEXT,
  CHANGE_CARD_REMARK_TEXT,
  CHANGE_CARD_IMAGE,
  CHANGE_CARD_AUDIO,
  REMOVE_VIDEO,
  CHANGE_VIDEO_STATUS,
  REMOVE_CARD_IMAGE,
  REMOVE_CARD_AUDIO,
  DELETE_CARD,
  CLEAN_AUTH,
  ADD_IMAGE,
  ADD_FILE,
  LOADING_PAGES,
  MOVE_CARD,
  FETCH_PAGES_NUMBER,
  ADD_PLAYGROUND,
  DELETE_PLAYGROUND,
  ADD_PLAYGROUNDLEVEL
} from "../actions/type";

const INIT_STATE = {
  editor: {},
  pageMap: {},
  isEditing: false,
  isLoadingPages: false,
  pagesNumbers: {}
};

export default (state = INIT_STATE, { type, payload }) => {
  let newState;

  switch (type) {
    case FETCH_PAGES:
      return {
        ...state,
        pageMap: { ...payload.pageMap },
        isLoadingPages: false
      };

    case LOADING_PAGES:
      return { ...state, isLoadingPages: true };

    case UPDATE_PAGE:
      return {
        ...state,
        pageMap: {
          ...state.pageMap,
          [payload.page._id]: payload.page
        }
      };

    case RESTORE_PAGES:
      newState = cloneDeep(state);
      payload.pageIds.forEach((pageId) => {
        newState.pageMap[pageId].isTrash = false;
      });
      return newState;

    /**
     *  EDITOR
     */

    case OPEN_EDITOR:
      return {
        ...state,
        editor: cloneDeep(state.pageMap[payload.pageId]),
        isEditing: true
      };

    case CLOSE_EDITOR:
      return { ...state, editor: {}, isEditing: false };

    case CHANGE_TITLE:
      return {
        ...state,
        editor: { ...state.editor, title: payload.value }
      };

    case CHANGE_TEACHING_NOTES:
      return {
        ...state,
        editor: {
          ...state.editor,
          data: { ...state.editor.data, teachingNotes: payload.value }
        }
      };

    case CHANGE_REMARK_TEACHING_NOTES:
      return {
        ...state,
        editor: {
          ...state.editor,
          data: { ...state.editor.data, remarkTeachingNotes: payload.value }
        }
      };

    case CHANGE_TEXT:
      newState = cloneDeep(state);
      if (newState.editor.data.texts[payload.index]) {
        newState.editor.data.texts[payload.index].value = payload.value;
      } else {
        newState.editor.data.texts[payload.index] = { value: payload.value };
      }
      return newState;

    case CHANGE_REMARK_TEXT:
      newState = cloneDeep(state);

      if (!newState.editor.data.remarks) {
        newState.editor.data.remarks = [];
      }

      if (newState.editor.data.remarks[payload.index]) {
        newState.editor.data.remarks[payload.index].value = payload.value;
      } else {
        newState.editor.data.remarks[payload.index] = { value: payload.value };
      }
      return newState;

    case ADD_IMAGE:
      return {
        ...state,
        editor: {
          ...state.editor,
          data: {
            ...state.editor.data,
            images: [...state.editor.data.images, payload.image]
          }
        }
      };

    case CHANGE_IMAGE:
      newState = cloneDeep(state);
      if (newState.editor.data.images[payload.index]) {
        newState.editor.data.images[payload.index]._image = {
          ...newState.editor.data.images[payload.index]._image,
          src: payload.src,
          name: payload.name
        };
      } else {
        newState.editor.data.images[payload.index] = {
          _image: {
            src: payload.src,
            name: payload.name
          }
        };
      }
      return newState;

    case REMOVE_IMAGE:
      newState = cloneDeep(state);
      if (newState.editor.data.images[payload.index])
        newState.editor.data.images[payload.index]._image = null;
      return newState;

    // ===============================================================
    case ADD_VIDEO:
      newState = cloneDeep(state);
      newState.editor.data.videos.push(payload.video);
      return newState;

    case CHANGE_VIDEO:
      newState = cloneDeep(state);

      if (newState.editor.data.videos[payload.index]) {
        newState.editor.data.videos[payload.index]._video = {
          ...newState.editor.data.videos[payload.index]._video,
          src: payload.src,
          name: payload.name
        };
      } else {
        newState.editor.data.videos[payload.index] = {
          _video: {
            src: payload.src,
            name: payload.name
          }
        };
      }
      return newState;

    case REMOVE_VIDEO:
      newState = cloneDeep(state);
      if (newState.editor.data.videos[payload.index])
        newState.editor.data.videos[payload.index]._video = null;

      return newState;

    case CHANGE_VIDEO_STATUS:
      newState = cloneDeep(state);

      newState.pageMap[payload.pageId].data.videos[
        payload.index
      ]._video.status = payload.video.status;

      newState.pageMap[payload.pageId].data.videos[
        payload.index
      ]._video._thumbnail = payload.video._thumbnail;

      return newState;

    // ==================================================================

    case ADD_AUDIO:
      newState = cloneDeep(state);
      newState.editor.data.audios.push(payload.audio);
      return newState;

    case CHANGE_AUDIO:
      newState = cloneDeep(state);
      if (newState.editor.data.audios[payload.index]) {
        newState.editor.data.audios[payload.index]._audio = {
          ...newState.editor.data.audios[payload.index]._audio,
          src: payload.src,
          name: payload.name
        };
      } else {
        newState.editor.data.audios[payload.index] = {
          _audio: {
            src: payload.src,
            name: payload.name
          }
        };
      }
      return newState;

    case MOVE_AUDIO:
      newState = cloneDeep(state);
      if (newState.editor.data.audios[payload.index]) {
        newState.editor.data.audios[payload.index].position = payload.position;
      } else {
        newState.editor.data.audios[payload.index] = {
          position: payload.position
        };
      }
      return newState;

    case REMOVE_AUDIO:
      newState = cloneDeep(state);
      if (newState.editor.data.audios[payload.index])
        newState.editor.data.audios[payload.index]._audio = null;
      return newState;

    case DELETE_AUDIO:
      newState = cloneDeep(state);
      newState.editor.data.audios[payload.index] = null;
      return newState;

    case ADD_CARD:
      newState = cloneDeep(state);
      newState.editor.data.cards.push(payload.card);
      return newState;

    case MOVE_CARD:
      newState = cloneDeep(state);
      if (newState.editor.data.cards[payload.index]) {
        newState.editor.data.cards[payload.index].position = payload.position;
      } else {
        newState.editor.data.cards[payload.index] = {
          position: payload.position,
          texts: [],
          images: [],
          audios: []
        };
      }
      return newState;

    case CHANGE_CARD_TEXT:
      newState = cloneDeep(state);
      if (payload.subIndex !== undefined) {
        if (newState.editor.data.cards[payload.index]) {
          if (newState.editor.data.cards[payload.index].texts) {
            if (
              newState.editor.data.cards[payload.index].texts[payload.subIndex]
            ) {
              newState.editor.data.cards[payload.index].texts[
                payload.subIndex
              ].value = payload.value;
            } else {
              newState.editor.data.cards[payload.index].texts[
                payload.subIndex
              ] = { value: payload.value };
            }
          } else {
            newState.editor.data.cards[payload.index].texts = [];
            newState.editor.data.cards[payload.index].texts[payload.subIndex] =
              { value: payload.value };
          }
        } else {
          newState.editor.data.cards[payload.index] = {
            texts: [],
            images: [],
            audios: []
          };
          newState.editor.data.cards[payload.index].texts[payload.subIndex] = {
            value: payload.value
          };
        }
      } else {
        if (newState.editor.data.cards[payload.index]) {
          if (newState.editor.data.cards[payload.index].text) {
            newState.editor.data.cards[payload.index].text.value =
              payload.value;
          } else {
            newState.editor.data.cards[payload.index].text = {
              value: payload.value
            };
          }
        } else {
          newState.editor.data.cards[payload.index] = {
            text: payload.value,
            texts: [],
            images: [],
            audios: []
          };
        }
      }
      return newState;

    case CHANGE_CARD_REMARK_TEXT:
      newState = cloneDeep(state);
      if (payload.subIndex !== undefined) {
        // card remarks
        if (newState.editor.data.cards[payload.index]) {
          if (newState.editor.data.cards[payload.index].remarks) {
            if (
              newState.editor.data.cards[payload.index].remarks[
                payload.subIndex
              ]
            ) {
              newState.editor.data.cards[payload.index].remarks[
                payload.subIndex
              ].value = payload.value;
            } else {
              newState.editor.data.cards[payload.index].remarks[
                payload.subIndex
              ] = { value: payload.value };
            }
          } else {
            newState.editor.data.cards[payload.index].remarks = [];
            newState.editor.data.cards[payload.index].remarks[
              payload.subIndex
            ] = { value: payload.value };
          }
        } else {
          newState.editor.data.cards[payload.index] = {
            remarks: [],
            texts: [],
            images: [],
            audios: []
          };
          newState.editor.data.cards[payload.index].remarks[payload.subIndex] =
            {
              value: payload.value
            };
        }
      } else {
        // card remark
        if (newState.editor.data.cards[payload.index]) {
          if (newState.editor.data.cards[payload.index].remark) {
            newState.editor.data.cards[payload.index].remark.value =
              payload.value;
          } else {
            newState.editor.data.cards[payload.index].remark = {
              value: payload.value
            };
          }
        } else {
          newState.editor.data.cards[payload.index] = {
            remark: payload.value,
            remarks: [],
            text: "",
            texts: [],
            images: [],
            audios: []
          };
        }
      }
      return newState;

    case CHANGE_CARD_IMAGE:
      newState = cloneDeep(state);
      if (payload.subIndex !== undefined) {
        if (newState.editor.data.cards[payload.index]) {
          if (newState.editor.data.cards[payload.index].images) {
            if (
              newState.editor.data.cards[payload.index].images[payload.subIndex]
            ) {
              newState.editor.data.cards[payload.index].images[
                payload.subIndex
              ]._image = {
                ...newState.editor.data.cards[payload.index].images[
                  payload.subIndex
                ]._image,
                src: payload.src,
                name: payload.name
              };
            } else {
              newState.editor.data.cards[payload.index].images[
                payload.subIndex
              ] = {
                _image: {
                  src: payload.src,
                  name: payload.name
                }
              };
            }
          } else {
            newState.editor.data.cards[payload.index].images = [];
            newState.editor.data.cards[payload.index].images[payload.subIndex] =
              {
                _image: {
                  src: payload.src,
                  name: payload.name
                }
              };
          }
        } else {
          newState.editor.data.cards[payload.index] = {
            texts: [],
            images: [],
            audios: []
          };
          newState.editor.data.cards[payload.index].images[payload.subIndex] = {
            _image: {
              src: payload.src,
              name: payload.name
            }
          };
        }
      } else {
        if (newState.editor.data.cards[payload.index]) {
          if (newState.editor.data.cards[payload.index].image) {
            newState.editor.data.cards[payload.index].image._image = {
              ...newState.editor.data.cards[payload.index].image._image,
              src: payload.src,
              name: payload.name
            };
          } else {
            newState.editor.data.cards[payload.index].image = {
              _image: {
                src: payload.src,
                name: payload.name
              }
            };
          }
        } else {
          newState.editor.data.cards[payload.index] = {
            image: {
              _image: {
                src: payload.src,
                name: payload.name
              }
            },
            texts: [],
            images: [],
            audios: []
          };
        }
      }
      return newState;

    case CHANGE_CARD_AUDIO:
      newState = cloneDeep(state);
      if (payload.subIndex !== undefined) {
        if (newState.editor.data.cards[payload.index]) {
          if (newState.editor.data.cards[payload.index].audios) {
            if (
              newState.editor.data.cards[payload.index].audios[payload.subIndex]
            ) {
              newState.editor.data.cards[payload.index].audios[
                payload.subIndex
              ]._audio = {
                ...newState.editor.data.cards[payload.index].audios[
                  payload.subIndex
                ]._audio,
                src: payload.src,
                name: payload.name
              };
            } else {
              newState.editor.data.cards[payload.index].audios[
                payload.subIndex
              ] = {
                _audio: {
                  src: payload.src,
                  name: payload.name
                }
              };
            }
          } else {
            newState.editor.data.cards[payload.index].audios = [];
            newState.editor.data.cards[payload.index].audios[payload.subIndex] =
              {
                _audio: {
                  src: payload.src,
                  name: payload.name
                }
              };
          }
        } else {
          newState.editor.data.cards[payload.index] = {
            texts: [],
            images: [],
            audios: []
          };
          newState.editor.data.cards[payload.index].audios[payload.subIndex] = {
            _audio: {
              src: payload.src,
              name: payload.name
            }
          };
        }
      } else {
        if (newState.editor.data.cards[payload.index]) {
          if (newState.editor.data.cards[payload.index].audio) {
            newState.editor.data.cards[payload.index].audio._audio = {
              ...newState.editor.data.cards[payload.index].audio._audio,
              src: payload.src,
              name: payload.name
            };
          } else {
            newState.editor.data.cards[payload.index].audio = {
              _audio: {
                src: payload.src,
                name: payload.name
              }
            };
          }
        } else {
          newState.editor.data.cards[payload.index] = {
            audio: {
              _audio: {
                src: payload.src,
                name: payload.name
              }
            },
            texts: [],
            images: [],
            audios: []
          };
        }
      }
      return newState;

    case REMOVE_CARD_IMAGE:
      newState = cloneDeep(state);
      if (payload.subIndex !== undefined) {
        if (
          newState.editor.data.cards[payload.index] &&
          newState.editor.data.cards[payload.index].images &&
          newState.editor.data.cards[payload.index].images[payload.subIndex]
        )
          newState.editor.data.cards[payload.index].images[
            payload.subIndex
          ]._image = null;
      } else {
        if (
          newState.editor.data.cards[payload.index] &&
          newState.editor.data.cards[payload.index].image
        )
          newState.editor.data.cards[payload.index].image._image = null;
      }
      return newState;

    case REMOVE_CARD_AUDIO:
      newState = cloneDeep(state);

      if (payload.subIndex !== undefined) {
        if (
          newState.editor.data.cards[payload.index] &&
          newState.editor.data.cards[payload.index].audios &&
          newState.editor.data.cards[payload.index].audios[payload.subIndex]
        )
          newState.editor.data.cards[payload.index].audios[
            payload.subIndex
          ]._audio = null;
      } else {
        if (
          newState.editor.data.cards[payload.index] &&
          newState.editor.data.cards[payload.index].audio
        )
          newState.editor.data.cards[payload.index].audio._audio = null;
      }
      return newState;

    case DELETE_CARD:
      newState = cloneDeep(state);
      newState.editor.data.cards[payload.index] = null;
      return newState;

    case ADD_FILE:
      return {
        ...state,
        editor: {
          ...state.editor,
          data: {
            ...state.editor.data,
            _files: [...state.editor.data._files, payload.file]
          }
        }
      };

    case FETCH_PAGES_NUMBER:
      return {
        ...state,
        pagesNumbers: payload
      };

    case CLEAN_AUTH:
      return { ...INIT_STATE };

    case ADD_PLAYGROUND:
      newState = cloneDeep(state);

      newState.editor.data.playgrounds = payload;

      return newState;

    case DELETE_PLAYGROUND:
      newState = cloneDeep(state);

      newState.editor.data.playgrounds.splice(payload.index, 1);

      return newState;

    case ADD_PLAYGROUNDLEVEL:
      newState = cloneDeep(state);

      newState.editor.data.playgroundLevel = payload;

      return newState;

    default:
      return state;
  }
};
