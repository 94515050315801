import Rollbar from "rollbar";

const defaultPayload = {
  person: {
    id: "Not_logged_in_yet",
    username: "Not_logged_in_yet",
    email: "Not_logged_in_yet"
  }
};

const config = {
  accessToken: process.env.REACT_APP_ROLLBARACCESSTOKEN,
  environment: "production", // TODO: only production success, other will not send. contact Rollbar
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: defaultPayload,
  ignoredMessages: [
    "Request failed with status code 401",
    "Request failed with status code 304",
    "ResizeObserver loop limit exceeded",
    "Network Error",
    "Error: Network Error",
    "Network request failed",
    "Failed to fetch"
  ]
};

const rollbar = new Rollbar(config);

export const setRollbarUserInfo = (user = null) => {
  const commaStr = (a, b) => a + ", " + b;
  rollbar.configure({
    payload: user
      ? {
          person: {
            id: user ? user.id : "Not_logged_in_yet",
            username:
              user.username +
              " (roles: " +
              (user.roles.length ? user.roles.reduce(commaStr) : "undefined") +
              ") (school: undefined)",
            email: user.email
          }
        }
      : defaultPayload
  });
};

export const getRollbarConfig = () => config;

export default rollbar;
