import axios from "axios";
import { SERVER } from "../config/environment";

axios.defaults.baseURL = SERVER;
axios.interceptors.request.use(
  (req) => {
    const token = localStorage.getItem("token");
    if (!req.url.includes("AWSAccessKeyId")) {
      req.headers.authorization = token ? `Bearer ${token}` : null;
    }
    return req;
  },
  (err) => Promise.reject(err)
);

const tokenInterceptor = (FreshnessTest = false) => {
  let previousUrl = sessionStorage.getItem("previousURL"); // avoid repeated trigger 401 before we update the new token
  if (FreshnessTest && !previousUrl) {
    axios.head(`view/frequent`);
  }
};

export default tokenInterceptor;
