import { FETCH_SCHOOLS, UPDATE_SCHOOL, CLEAN_AUTH } from "../actions/type";

const INIT_STATE = {
  schoolMap: {}
};

export default (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case FETCH_SCHOOLS:
      return {
        ...state,
        schoolMap: { ...payload.schoolMap }
      };

    case UPDATE_SCHOOL:
      return {
        ...state,
        schoolMap: {
          ...state.schoolMap,
          [payload.school._id]: payload.school
        }
      };

    case CLEAN_AUTH:
      return { ...INIT_STATE };

    default:
      return state;
  }
};
