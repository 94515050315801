import { omit } from "lodash-es";

import {
  FETCH_ANNOUNCEMENTS,
  UPDATE_ANNOUNCEMENT,
  LOADING_ANNOUNCEMENTS,
  DELETE_ANNOUNCEMENT,
  CLEAN_AUTH
} from "../actions/type";

const INIT_STATE = {
  announcementMap: {},
  isLoadingAnnouncements: false
};

export default (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case FETCH_ANNOUNCEMENTS:
      return {
        ...state,
        announcementMap: { ...payload.announcementMap },
        isLoadingAnnouncements: false
      };

    case LOADING_ANNOUNCEMENTS:
      return { ...state, isLoadingAnnouncements: true };

    case UPDATE_ANNOUNCEMENT:
      return {
        ...state,
        announcementMap: {
          ...state.announcementMap,
          [payload.announcement._id]: payload.announcement
        }
      };

    case DELETE_ANNOUNCEMENT:
      return {
        ...state,
        announcementMap: omit(state.announcementMap, [payload.id])
      };

    case CLEAN_AUTH:
      return { ...INIT_STATE };

    default:
      return state;
  }
};
