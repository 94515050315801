import React from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components/macro";
import { connect } from "react-redux";
import { closeNotify } from "../actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import qs from "qs";

import MainContainer from "./elements/MainContainer";
import PrimaryButton from "./elements/PrimaryButton";
import SecondaryButton from "./elements/SecondaryButton";
import T from "./elements/TranslateScript";
import { useMyBestUserSignin } from "../utils/authHelpers";
import { MYBESTUSER, CLIENTID } from "../config/environment";

const Background = styled.div`
  display: ${({ show }) => (show ? `flex` : `none`)}; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 98; /* Sit on top */

  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.9); /* Black w/ opacity */

  align-items: center;
  justify-content: center;
`;

const AlertIcon = styled(FontAwesomeIcon)`
  color: white;
`;

const Container = styled.div`
  height: 50%;
  width: 40%;

  overflow: hidden;

  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 20% 55% 1fr;
  grid-template-areas:
    "title"
    "content"
    "buttons";

  border-radius: 0.5em;
  background: white;
  margin: auto;
  position: relative;
`;

const Title = styled.div`
  grid-area: title;
  text-align: center;
  font-size: 2em;
  font-weight: 700;
  background: ${({ theme }) => theme.greenSecondary};
  color: white;
`;

const Content = styled.div`
  grid-area: content;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const Description = styled.div`
  font-size: 1.2em;
  width: 80%;
  height: 100%;
  overflow-y: auto;
  white-space: pre-wrap;
  word-break: break-word;
`;

const Buttons = styled.div`
  grid-area: buttons;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const Notify = ({
  notify: { show, alertMessage, confirm, cancel },
  closeNotify
}) => {
  const location = useLocation();
  const signin = useMyBestUserSignin();
  const relogin = () => {
    if (confirm) {
      confirm();
      signin(
        `${MYBESTUSER}/authorize` +
          qs.stringify(
            {
              client_id: CLIENTID,
              response_type: "code",
              redirect_uri: window.location.origin + "/mybestuser",
              state: "test message",
              scope: "api.mybestta.com api.mybestuser.com"
            },
            { addQueryPrefix: true }
          )
      );
      sessionStorage.setItem("previousURL", location.pathname);
    }
  };

  return (
    <Background show={show}>
      <MainContainer>
        <Container>
          <Title>
            <AlertIcon icon={faBell} /> <T>Notify</T>
          </Title>

          <Content>
            <Description>
              <T>{alertMessage}</T>
            </Description>
          </Content>

          <Buttons>
            <PrimaryButton
              type="submit"
              themeColor="green"
              onClick={relogin || closeNotify}
            >
              Confirm
            </PrimaryButton>
            {cancel && (
              <SecondaryButton
                type="submit"
                onClick={() => {
                  closeNotify();
                  typeof cancel === "function" && cancel();
                }}
              >
                Cancel
              </SecondaryButton>
            )}
          </Buttons>
        </Container>
      </MainContainer>
    </Background>
  );
};

const mapStateToProps = ({ notify }) => ({ notify });

export default connect(mapStateToProps, { closeNotify })(Notify);
