import axios from "axios";

import { FETCH_COMMENTS, UPDATE_COMMENT } from "./type";

//fetch section's comments
export const fetchComments =
  (fromProps, callback) => async (dispatch, getStore) => {
    let role = fromProps.role;
    let userId = fromProps.userId;
    let sectionId = fromProps.sectionId;

    const {
      data: { commentMap }
    } = await axios.get(`comments/${sectionId}`);

    dispatch({ type: FETCH_COMMENTS, payload: { commentMap } });

    if (callback) callback();
  };

export const fetchAllComments =
  (fromProps, callback) => async (dispatch, getStore) => {
    const {
      data: { commentMap }
    } = await axios.get(`comments`);

    dispatch({ type: FETCH_COMMENTS, payload: { commentMap } });

    if (callback) callback();
  };

// update(create) comment
export const updateTeacherComment =
  (fromProps, callback) => async (dispatch, getStore) => {
    let teacherId = fromProps.teacherId;

    const {
      data: { comment }
    } = await axios.patch(`comments/${teacherId}`, {
      patch: {
        data: fromProps
      }
    });

    dispatch({ type: UPDATE_COMMENT, payload: { comment } });

    if (callback) callback();
  };

//edit comment resolved
export const editIsNeedResolved =
  (fromProps, callback) => async (dispatch, getStore) => {
    let commentId = fromProps.commentId;

    const {
      data: { comment }
    } = await axios.patch(`resolved/${commentId}`, {
      patch: {
        data: fromProps
      }
    });

    dispatch({ type: UPDATE_COMMENT, payload: { comment } });

    if (callback) callback();
  };

//del commment
export const deleteComment =
  (fromProps, callback) => async (dispatch, getStore) => {
    let commentId = fromProps.commentId;

    await axios.delete(`comment/${commentId}`);

    // update comment
    const {
      data: { commentMap }
    } = await axios.get(`comments`);

    dispatch({ type: FETCH_COMMENTS, payload: { commentMap } });
    if (callback) callback();
  };
