import React, { useEffect, lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useTracking } from "react-tracking";
import { useSelector } from "react-redux";
import { ThemeProvider } from "styled-components/macro";
import axios from "axios";
import { ConfirmProvider } from "material-ui-confirm";
import { ErrorBoundary } from "react-error-boundary";
import { createBrowserHistory } from "history";
import { Provider as RollbarProvider, historyContext } from "@rollbar/react";
// routes
const AdminRoute = lazy(() =>
  import(/* webpackChunkName: "AdminRoute" */ "./routes/admin/AdminRoute")
);
const TeacherRoute = lazy(() =>
  import(/* webpackChunkName: "TeacherRoute" */ "./routes/teacher/TeacherRoute")
);
const StudentRoute = lazy(() =>
  import(/* webpackChunkName: "StudentRoute" */ "./routes/student/StudentRoute")
);
const FreeRoute = lazy(() =>
  import(/* webpackChunkName: "FreeRoute" */ "./routes/FreeRoute")
);
// components
import { GlobalStyle } from "./components/globalStyle.styled";
import Landing from "./app/LandingPage";
import Modal from "./app/Modal";
import Error from "./app/Error";
import Warning from "./app/Warning";
import Notify from "./app/Notify";
import Load from "./app/Load";
import Notice from "./app/Notice";
import ContextMenu from "./app/ContextMenu";
import AudioPlayer from "./app/elements/AudioPlayer";
import NoMatch from "./app/elements/NoMatch";
import MyBestUserLogin from "./components/MyBestUserLogin";
import DevMode from "./components/DevMode";
import Authorization from "./components/Authorization";
import ErrorFallback from "./ErrorFallBack";
import { DefaultSuspense as Suspense } from "./components/Suspense";
// utils
import { MyBestUserSigninProvider } from "./utils/authHelpers";
import { LanguageProvider } from "./utils/languageHelpers";
import { StrictConfirmProvider } from "./utils/confirmHelpers";
import { rollbar, getRollbarConfig } from "./loggers";
// configs
import { ALL, ADMINS, STUDENT } from "./config/accessLevels";
import { themeSelector } from "./config/themes";

const history = createBrowserHistory();

const App = () => {
  const roles = useSelector((state) => state.auth.user?.roles);
  const { Track } = useTracking(
    { app: "teach-booster" },
    {
      dispatch: (data) => {
        if (data && data.event === "browse") {
          axios.post("track", { track: data });
        }
      }
    }
  );

  useEffect(() => {
    const unlisten = history.listen(historyContext(rollbar));
    return unlisten;
  }, []);

  const reloadPage = () => {
    if (window.location.hash !== "#refresh") {
      window.location = window.location + "#refresh";
      window.location.reload();
    }
  };

  return (
    <RollbarProvider instance={rollbar} config={getRollbarConfig()}>
      <ErrorBoundary FallbackComponent={ErrorFallback} onReset={reloadPage}>
        <ThemeProvider theme={themeSelector(roles)}>
          <BrowserRouter>
            <LanguageProvider>
              <ConfirmProvider>
                <StrictConfirmProvider>
                  <MyBestUserSigninProvider>
                    <Suspense>
                      <DevMode />
                      <GlobalStyle />
                      {/* <Track> */}
                      <Routes>
                        <Route path="/" element={<Landing />} />
                        <Route
                          path="mybestuser"
                          element={<MyBestUserLogin />}
                        />
                        <Route
                          path="admin/*"
                          element={
                            <Authorization
                              component={AdminRoute}
                              level={ADMINS}
                            />
                          }
                        />
                        <Route
                          path="teacher/*"
                          element={
                            <Authorization
                              component={TeacherRoute}
                              level={ALL}
                            />
                          }
                        />

                        <Route
                          path="student/*"
                          element={
                            <Authorization
                              component={StudentRoute}
                              level={STUDENT}
                            />
                          }
                        />
                        <Route path="free/*" element={<FreeRoute />} />
                        <Route path="*" element={<NoMatch />} />
                      </Routes>
                      {/* </Track> */}
                      <AudioPlayer />
                      <Notice />
                      <Modal />
                      <Load />
                      <Notify />
                      <Warning />
                      <Error />
                      <ContextMenu />
                    </Suspense>
                  </MyBestUserSigninProvider>
                </StrictConfirmProvider>
              </ConfirmProvider>
            </LanguageProvider>
          </BrowserRouter>
        </ThemeProvider>
      </ErrorBoundary>
    </RollbarProvider>
  );
};

export default App;
