import { CLEAN_AUTH, OPEN_MODAL, CLOSE_MODAL } from "../actions/type";

const INIT_STATE = { component: null, close: null, show: false };

export default (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case OPEN_MODAL:
      return { ...state, show: true, ...payload };

    case CLOSE_MODAL:
      return { ...state, show: false, component: null, close: null };

    case CLEAN_AUTH:
      return { ...INIT_STATE };

    default:
      return state;
  }
};
