import axios from "axios";

import {
  FETCH_ANNOUNCEMENTS,
  UPDATE_ANNOUNCEMENT,
  LOADING_ANNOUNCEMENTS,
  DELETE_ANNOUNCEMENT
} from "./type";

// fetch all the announcements
export const fetchAnnouncements =
  (options, callback) => async (dispatch, getStore) => {
    dispatch({ type: LOADING_ANNOUNCEMENTS, payload: {} });

    let search = ``;

    if (options && options.constructor.name === "Object") {
      for (const option in options) {
        search = search
          ? `${search}&${option}=${options[option]}`
          : `?${option}=${options[option]}`;
      }
    }

    const {
      data: { announcementMap }
    } = await axios.get(`announcement/management${search}`);

    dispatch({ type: FETCH_ANNOUNCEMENTS, payload: { announcementMap } });

    if (callback) callback();
  };

// create one announcement
export const createAnnouncement =
  (formProps, callback) => async (dispatch, getStore) => {
    const {
      data: { announcement }
    } = await axios.post(`announcement`, formProps);

    dispatch({ type: UPDATE_ANNOUNCEMENT, payload: { announcement } });

    if (callback) callback();
  };

// update one announcement
export const updateAnnouncement =
  (id, formProps, callback) => async (dispatch, getStore) => {
    const {
      data: { announcement }
    } = await axios.patch(`announcement/${id}`, {
      patch: {
        action: `UPDATE`,
        data: { ...formProps }
      }
    });

    dispatch({ type: UPDATE_ANNOUNCEMENT, payload: { announcement } });

    if (callback) callback();
  };

// delete one announcement
export const deleteAnnouncement =
  (id, callback) => async (dispatch, getStore) => {
    await axios.delete(`announcement/${id}`);

    dispatch({ type: DELETE_ANNOUNCEMENT, payload: { id } });

    if (callback) callback();
  };
